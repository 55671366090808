<template>
    <svg
        class="locomotive"
        fill="none"
        height="180"
        viewBox="0 0 74 180"
        width="74"
        xmlns="http://www.w3.org/2000/svg">
        <g v-if="locomotiveType === 'middle'" id="middle">
            <rect
                fill="#E5E5E5"
                height="180"
                width="74" />
            <rect
                fill="white"
                height="1504"
                transform="translate(-1373 -310)"
                width="2086" />
            <rect
                fill="white"
                height="1198"
                width="1468"
                x="-976"
                y="-310" />
            <rect
                class="locomotive__border"
                :class="{ 'locomotive__border--error': locomotive?.plansToLeaveAt && locomotive.sectionStatus !== 4 ? moment().isAfter(locomotive.plansToLeaveAt) : false }"
                fill="white"
                height="176"
                stroke="#333333"
                stroke-width="4"
                width="70"
                x="2"
                y="2" />
            <text
                fill="#333333"
                font-family="Inter"
                font-size="10"
                font-weight="bold"
                letter-spacing="0em"
                style="white-space: pre"
                xml:space="preserve">
                <tspan
                    dominant-baseline="middle"
                    text-anchor="middle"
                    x="50%"
                    y="29.6364">
                    {{ locomotive.sectionNumber }}
                </tspan>
            </text>
            <text
                fill="#333333"
                font-family="Inter"
                font-size="10"
                font-weight="bold"
                letter-spacing="0em"
                style="white-space: pre"
                xml:space="preserve">
                <tspan
                    dominant-baseline="middle"
                    text-anchor="middle"
                    x="50%"
                    y="157.636">
                    {{ locomotive.locomotiveModel }}
                </tspan>
            </text>
            <circle
                cx="37"
                cy="90"
                :fill="fill"
                r="13.5"
                stroke="#333333"
                stroke-width="3" />
            <text
                fill="#333333"
                font-family="Inter"
                font-size="14"
                font-weight="bold"
                letter-spacing="0em"
                style="white-space: pre"
                xml:space="preserve">
                <tspan
                    dominant-baseline="middle"
                    text-anchor="middle"
                    x="50%"
                    y="91.5909">
                    {{ locomotive.jobCategoryShort }}
                </tspan>
            </text>
        </g>
        <g v-if="locomotiveType === 'once'" id="once">
            <rect
                class="locomotive__background"
                fill="#333333"
                height="16"
                rx="3"
                width="66"
                x="4"
                y="10" />
            <rect
                class="locomotive__border"
                :class="{ 'locomotive__border--error': locomotive?.plansToLeaveAt && locomotive.sectionStatus !== 4 ? moment().isAfter(locomotive.plansToLeaveAt) : false }"
                fill="white"
                height="156"
                rx="13"
                stroke="#333333"
                stroke-width="4"
                width="70"
                x="2"
                y="12" />
            <text
                fill="#333333"
                font-family="Inter"
                font-size="10"
                font-weight="bold"
                letter-spacing="0em"
                style="white-space: pre"
                xml:space="preserve">
                <tspan
                    dominant-baseline="middle"
                    text-anchor="middle"
                    x="50%"
                    y="39.6364">
                    {{ locomotive.sectionNumber }}
                </tspan>
            </text>
            <text
                fill="#333333"
                font-family="Inter"
                font-size="10"
                font-weight="bold"
                letter-spacing="0em"
                style="white-space: pre"
                xml:space="preserve">
                <tspan
                    dominant-baseline="middle"
                    text-anchor="middle"
                    x="50%"
                    y="147.636">
                    {{ locomotive.locomotiveModel }}
                </tspan>
            </text>
            <circle
                cx="37"
                cy="90"
                :fill="fill"
                r="13.5"
                stroke="#333333"
                stroke-width="3" />
            <text
                fill="#333333"
                font-family="Inter"
                font-size="14"
                font-weight="bold"
                letter-spacing="0em"
                style="white-space: pre"
                xml:space="preserve">
                <tspan
                    dominant-baseline="middle"
                    text-anchor="middle"
                    x="50%"
                    y="92.5909">
                    {{ locomotive.jobCategoryShort }}
                </tspan>
            </text>
        </g>
        <g v-if="locomotiveType === 'start'" id="start">
            <rect
                class="locomotive__background"
                fill="#333333"
                height="16"
                rx="3"
                width="66"
                x="4"
                y="10" />
            <path
                class="locomotive__border"
                :class="{ 'locomotive__border--error': locomotive?.plansToLeaveAt && locomotive.sectionStatus !== 4 ? moment().isAfter(locomotive.plansToLeaveAt) : false }"
                d="M15 12H59C66.1797 12 72 17.8203 72 25V178H2V25C2 17.8203 7.8203 12 15 12Z"
                fill="white"
                stroke="#333333"
                stroke-width="4" />
            <text
                fill="#333333"
                font-family="Inter"
                font-size="10"
                font-weight="bold"
                letter-spacing="0em"
                style="white-space: pre"
                xml:space="preserve">
                <tspan
                    dominant-baseline="middle"
                    text-anchor="middle"
                    x="50%"
                    y="39.6364">
                    {{ locomotive.sectionNumber }}
                </tspan>
            </text>
            <text
                fill="#333333"
                font-family="Inter"
                font-size="10"
                font-weight="bold"
                letter-spacing="0em"
                style="white-space: pre"
                xml:space="preserve">
                <tspan
                    dominant-baseline="middle"
                    text-anchor="middle"
                    x="50%"
                    y="157.636">
                    {{ locomotive.locomotiveModel }}
                </tspan>
            </text>
            <circle
                cx="37"
                cy="90"
                :fill="fill"
                r="13.5"
                stroke="#333333"
                stroke-width="3" />
            <text
                fill="#333333"
                font-family="Inter"
                font-size="14"
                font-weight="bold"
                letter-spacing="0em"
                style="white-space: pre"
                xml:space="preserve">
                <tspan
                    dominant-baseline="middle"
                    text-anchor="middle"
                    x="50%"
                    y="91.5909">
                    {{ locomotive.jobCategoryShort }}
                </tspan>
            </text>
        </g>
        <g v-if="locomotiveType === 'end'" id="end">
            <path
                class="locomotive__border"
                :class="{ 'locomotive__border--error': locomotive?.plansToLeaveAt && locomotive.sectionStatus !== 4 ? moment().isAfter(locomotive.plansToLeaveAt) : false }"
                d="M2 2H72V155C72 162.18 66.1797 168 59 168H15C7.8203 168 2 162.18 2 155V2Z"
                fill="white"
                stroke="#333333"
                stroke-width="4" />
            <text
                fill="#333333"
                font-family="Inter"
                font-size="10"
                font-weight="bold"
                letter-spacing="0em"
                style="white-space: pre"
                xml:space="preserve">
                <tspan
                    dominant-baseline="middle"
                    text-anchor="middle"
                    x="50%"
                    y="29.6364">
                    {{ locomotive.sectionNumber }}
                </tspan>
            </text>
            <text
                fill="#333333"
                font-family="Inter"
                font-size="10"
                font-weight="bold"
                letter-spacing="0em"
                style="white-space: pre"
                xml:space="preserve">
                <tspan
                    dominant-baseline="middle"
                    text-anchor="middle"
                    x="50%"
                    y="147.636">
                    {{ locomotive.locomotiveModel }}
                </tspan>
            </text>
            <circle
                cx="37"
                cy="90"
                :fill="fill"
                r="13.5"
                stroke="#333333"
                stroke-width="3" />
            <text
                fill="#333333"
                font-family="Inter"
                font-size="14"
                font-weight="bold"
                letter-spacing="0em"
                style="white-space: pre"
                xml:space="preserve">
                <tspan
                    dominant-baseline="middle"
                    text-anchor="middle"
                    x="50%"
                    y="91.5909">
                    {{ locomotive.jobCategoryShort }}
                </tspan>
            </text>
        </g>
        <g
            v-if="locomotiveType === 'add'"
            id="add"
            style="cursor:pointer;">
            <rect
                fill="white"
                height="156"
                rx="13"
                stroke="#333333"
                stroke-dasharray="8 8"
                stroke-width="4"
                width="70"
                x="2"
                y="12" />
            <circle
                cx="37"
                cy="90"
                fill="white"
                r="13.5"
                stroke="#333333"
                stroke-width="3" />
            <rect
                class="locomotive__border"
                :class="{ 'locomotive__border--error': locomotive?.plansToLeaveAt && locomotive.sectionStatus !== 4 ? moment().isAfter(locomotive.plansToLeaveAt) : false }"
                fill="#333333"
                height="16"
                width="4"
                x="35"
                y="82" />
            <rect
                fill="#333333"
                height="16"
                transform="rotate(90 45 88)"
                width="4"
                x="45"
                y="88" />
        </g>
        <g v-if="locomotiveType === 'zone'" id="zone">
            <rect
                class="locomotive__border"
                :class="{ 'locomotive__border--error': locomotive?.plansToLeaveAt && locomotive.sectionStatus !== 4 ? moment().isAfter(locomotive.plansToLeaveAt) : false }"
                fill="white"
                height="156"
                rx="13"
                stroke="#333333"
                stroke-dasharray="8 8"
                stroke-width="4"
                width="70"
                x="2"
                y="12" />
        </g>
    </svg>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import { IUnknownObject } from "@/@types/common";
import moment from 'moment-timezone';

export default defineComponent({
    name: "LocomotiveVerticalComponent",
    props: {
	    locomotive: {
            type: Object,
            default: null,
            // validator: (value: string) => [ 'submit', 'button' ].includes(value),
        },
	    type: {
            type: String,
	    },
    },
    setup(props) {
        const locomotiveType = computed(() => {
            if (props.type === 'zone') return 'zone';
	        else if (props.locomotive === null) return 'add';
            // if (props.locomotive.id === 0) return 'add'
            else if (props.locomotive.current === 1 && props.locomotive.count === 1) return 'once';
            else if (props.locomotive.current === 1 && [ 2, 3 ].includes(props.locomotive.count)) return 'start';
            else if (props.locomotive.current === 2 && props.locomotive.count === 2) return 'end';
            else if (props.locomotive.current === 2 && props.locomotive.count === 3) return 'middle';
            else if (props.locomotive.current === 3 && [ 2, 3 ].includes(props.locomotive.count)) return 'end';
        });

        const fill = computed(() => {
	        if (props.locomotive.sectionStatus === 1) return '#adb5bd';
	        if (props.locomotive.sectionStatus === 3) return '#1cbb8c';

            return '#fff';
        });

        return {
            locomotiveType,
	        fill,
            moment,
        };
    },
});
</script>

<style scoped>
.locomotive {
	cursor: pointer;
	display: block;
}
.locomotive:hover .locomotive__border {
	stroke: #3b7ddd;
}
.locomotive__border--error, .locomotive:hover .locomotive__border--error {
    stroke: #f7380d;
}
.locomotive:hover .locomotive__background {
	fill: #3b7ddd;
}

</style>