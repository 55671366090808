import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  ref: "target",
  class: "dropdown d-inline-block"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "cursor-pointer",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.state = !_ctx.state))
    }, [
      _renderSlot(_ctx.$slots, "button")
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["dropdown-menu dropdown-menu-end dropdown-menu-arrow", { 'show': _ctx.state }]),
      "data-bs-popper": "none"
    }, [
      _renderSlot(_ctx.$slots, "list")
    ], 2)
  ], 512))
}