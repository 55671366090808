<template>
    <modal-ui
        :id="id"
        size="modal-xl"
        title="Добавление материала">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
	            <div class="col-12 col-md-3">
		            <form-group-ui
			            invalid-feedback="Укажите наименование ТМЦ"
			            label="Наименование ТМЦ"
			            required>
			            <input-ui
				            placeholder="Наименование ТМЦ"
				            v-model="state.originalName"
				            :is-invalid="isNameError && state.visibleErrors"
			            />
		            </form-group-ui>
	            </div>
	            <div class="col-12 col-md-3">
		            <form-group-ui
			            invalid-feedback="Укажите единицу измерения"
			            label="Единица измерения"
			            required>
			            <input-ui
				            v-model="state.originalUnitDimension"
				            placeholder="Единица измерения"
				            :is-invalid="isUnitError && state.visibleErrors"
			            />
		            </form-group-ui>
	            </div>
	            <div class="col-12 col-md-3">
		            <form-group-ui
			            invalid-feedback="Укажите количество ТМЦ"
			            label="Количество ТМЦ"
			            required>
			            <input-ui
							type="number"
							step="any"
				            v-model="state.originalCount"
				            placeholder="Количество ТМЦ"
				            :is-invalid="isCountInvError && state.visibleErrors"
			            />
		            </form-group-ui>
	            </div>
	            <div class="col-12 col-md-3">
		            <form-group-ui
			            invalid-feedback="Укажите цену"
			            label="Цена за единицу"
			            required>
			            <input-ui
				            v-model="state.originalUnitPrice"
							step="any"
							type="number"
				            placeholder="Цена за единицу"
				            :is-invalid="isSumInvError && state.visibleErrors"
			            />
		            </form-group-ui>
	            </div>
	            <div class="col-12 col-md-3">
		            <form-group-ui
			            invalid-feedback="Выберите материал"
			            label="Материал"
			            required>
			            <select-ui
				            :is-invalid="state.visibleErrors && isApmIdError"
				            item-key="name"
				            :options="materials"
				            :searchable="true"
				            :values="[state.materialId]"
				            @on-updated="onMaterialSelected" />
<!--			            <div class="form-text" v-if="selectedMaterial.id">Документ: {{ selectedMaterial.documentNumber }}, ед. изм.: {{ selectedMaterial.unit }}</div>-->
		            </form-group-ui>
	            </div>
	            <div class="col-12 col-md-3">
		            <form-group-ui
			            invalid-feedback="Укажите количество"
			            label="Количество"
			            required>
			            <input-ui
				            v-model="state.actualCount"
							type="number"
							step="any"
				            placeholder="Количество"
				            :is-invalid="isCountError && state.visibleErrors"
			            />
		            </form-group-ui>
	            </div>
				<div class="col-12 col-md-3 d-flex align-items-center">
					<span>Ед измерения материала: {{ selectedMaterial?.unit || '' }}</span>
				</div>
                <div class="row">
                    <div class="col-12 text-center">
                        <button-ui
                            button-class="btn-primary">
                            Сохранить
                        </button-ui>
                    </div>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref, watch } from "vue";
import { initModal, useModal } from "@/composables/useModal";
import api from "@/api";
import { DatePicker } from 'v-calendar';

export default defineComponent({
    name: "InvoiceModal",
    components: {
        DatePicker,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
	    const modal = useModal(props.id);

		const materials = ref([]);
	    const selectedMaterial = reactive({
		    id: 0,
		    name: '',
		    unit: '',
		    documentNumber: '',
	    });

        const state = reactive({
	        id: 0,
	        originalName: '',
	        originalUnitDimension: '',
	        originalCount: '',
	        originalUnitPrice: '',
	        materialId: '',
	        actualCount: '',
            visibleErrors: false,
            loader: false,
        });

        const isNameError = computed(() => !state.originalName.length);
	    const isUnitError = computed(() => !state.originalUnitDimension.length);
	    const isCountInvError = computed(() => +state.originalCount <= 0 || isNaN(+state.originalCount));
	    const isSumInvError = computed(() => +state.originalUnitPrice <= 0 || isNaN(+state.originalUnitPrice));
		const isApmIdError = computed(() => !state.materialId);
	    const isCountError = computed(() => +state.actualCount <= 0 || isNaN(+state.actualCount));

		onMounted(() => {
            api.materials.getOld().then(({ data }) => {
                materials.value = data.filter((m: any) => !modal.data.materialIds?.includes(+m?.id));
            });

			if (Object.values(modal.data?.invoiceMaterial || {}).length) {
				state.originalName = modal.data?.invoiceMaterial.originalName;
				state.originalCount = modal.data?.invoiceMaterial.originalCount;
				state.originalUnitPrice = modal.data?.invoiceMaterial.originalUnitPrice;
				state.originalUnitDimension = modal.data?.invoiceMaterial.originalUnitDimension;
				state.materialId = modal.data?.invoiceMaterial.materialId;
				state.actualCount = modal.data?.invoiceMaterial.actualCount;
			}
        });

	    function onMaterialSelected(m: any) {
            state.materialId = m.id;
		    selectedMaterial.unit = m?.measurement;
	    }

        function send() {
            if (isNameError.value || isUnitError.value || isCountInvError.value || isSumInvError.value || isApmIdError.value || isCountError.value) {
                state.visibleErrors = true;
            } else {
                state.visibleErrors = false;
                state.loader = true;

				if (Object.values(modal.data?.invoiceMaterial || {}).length) {
					api.invoiceMaterials.update({
						originalName: state.originalName,
						originalUnitDimension: state.originalUnitDimension,
						originalCount: state.originalCount,
						actualCount: state.actualCount,
						materialId: state.materialId,
						originalUnitPrice: state.originalUnitPrice,
						invoiceId: modal.data.invoiceId,
						materialInvoiceId: modal.data.invoiceMaterial?.id,
					}).then(r => {
						modal.ok(r.data);
					})
				} else {
					api.invoiceMaterials.create({
						originalName: state.originalName,
						originalUnitDimension: state.originalUnitDimension,
						originalCount: state.originalCount,
						actualCount: state.actualCount,
						materialId: state.materialId,
						originalUnitPrice: state.originalUnitPrice,
						invoiceId: modal.data.invoiceId,
					}).then(r => {
						modal.ok(r.data);
					})
				}
            }
        }

	    function ok(loco: any) {
		    modal.ok({
			    loco: loco,
		    });
	    }

        function close() {
	        modal.cancel({
		        zxc: 123,
	        });
        }

	    function cancel() {
		    modal.cancel({
			    zxc: 123,
		    });
	    }

        return {
            ok,
	        modal,
            cancel,
            close,
            state,
	        send,
	        isNameError,
	        isUnitError,
	        isCountInvError,
	        isSumInvError,
	        isApmIdError,
	        isCountError,
	        selectedMaterial,
	        materials,
	        onMaterialSelected,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
