import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row mb-4" }
const _hoisted_2 = { class: "col-12" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "col-12 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader_ui = _resolveComponent("loader-ui")!
  const _component_input_ui = _resolveComponent("input-ui")!
  const _component_form_group_ui = _resolveComponent("form-group-ui")!
  const _component_button_ui = _resolveComponent("button-ui")!
  const _component_modal_ui = _resolveComponent("modal-ui")!

  return (_openBlock(), _createBlock(_component_modal_ui, {
    id: _ctx.id,
    size: "modal-sm",
    title: "Загрузка документа"
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        class: "loader",
        onSubmit: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.send && _ctx.send(...args)), ["prevent"]))
      }, [
        _createVNode(_component_loader_ui, {
          "is-show": _ctx.state.loader
        }, null, 8, ["is-show"]),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": "Выберите файл",
              label: "Выберите файл",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_input_ui, {
                  onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectFile($event))),
                  type: "file",
                  ref: "file",
                  accept: "application/pdf",
                  "is-invalid": _ctx.isFileError && _ctx.state.visibleErrors
                }, null, 8, ["is-invalid"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_button_ui, { "button-class": "btn-primary" }, {
                default: _withCtx(() => [
                  _createTextVNode(" Сохранить ")
                ]),
                _: 1
              })
            ])
          ])
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["id"]))
}