<template>
    <modal-ui
        :id="id"
        size="modal-lg"
        :title="state.title">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Выберите сотрудника"
                        label="Сотрудник"
                        required>
                        <select-ui
                            :is-invalid="state.visibleErrors && isEmployeeIdError"
                            item-key="name"
                            :options="employees"
                            placeholder="Сотрудник"
                            :searchable="true"
                            :values="[state.employeeId]"
                            @on-selected="onEmployeeSelected" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Выберите материал"
                        label="Материал"
                        required>
                        <select-ui
                            :is-invalid="state.visibleErrors && isMaterialIdError"
                            item-key="name"
                            :options="materials"
                            placeholder="Материал"
                            :searchable="true"
                            :values="[state.materialId]"
                            @on-selected="onMaterialSelected" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Выберите секцию"
                        label="Секция"
                        required>
                        <select-ui
                            :is-invalid="state.visibleErrors && isSectionIdError"
                            item-key="name"
                            :options="sections"
                            placeholder="Секция"
                            :searchable="true"
                            :values="[state.sectionId]"
                            @on-selected="onSectionSelected" />
                    </form-group-ui>
                </div>
            </div>
            <div v-if="state.findNorm && state.msg.length" class="row">
                <div class="col-12">
                    <div class="alert alert-info">
                        <div class="alert-message">
                            {{ state.msg }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="state.findNorm" class="row">
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Укажите единицу измерения"
                        label="Единица измерения"
                        required>
                        <select
                            v-model="state.measurement"
                            class="form-select"
                            :class="{'is-invalid': state.visibleErrors && isMeasurementError}">
                            <option disabled value="">
                                Единица измерения
                            </option>
                            <option
                                v-for="measurement in measurements"
                                :key="measurement"
                                :value="measurement">
                                {{ measurement }}
                            </option>
                        </select>
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Укажите количество"
                        label="Количество"
                        required>
                        <input-ui
                            v-model="state.count"
                            :is-invalid="state.visibleErrors && isCountError"
                            placeholder="Количество" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Укажите примечание"
                        label="Примечание">
                        <input-ui
                            v-model="state.comment"
                            placeholder="Примечание" />
                    </form-group-ui>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button-ui
                        button-class="btn-primary"
                        :disabled="!state.findNorm">
                        {{ state.btn }}
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref, watch } from "vue";
import { initModal, useModal } from "@/composables/useModal";
import api from "@/api";
import { DatePicker } from 'v-calendar';
import moment from 'moment-timezone';
import { insert } from "@/composables/useTransmitter";

export default defineComponent({
    name: "StockModal",
    components: {
        DatePicker,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
	    const modal = useModal(props.id);
        const materials = ref([]);
        const sections = ref([]);
        const areas = ref([]);
        const employees = ref([]);
        const measurements = ref([
	        'гр.',
		        'кв. м',
	        'кг.',
		        'комплект',
	        'куб. м.',
		        'литр',
	        'метр',
	        'мл.',
		        'мм.',
		       'п.м.',
		        'пара',
	        'рулон',
	        'см.',
		        'тонна',
	        'упаковка',
	        'штук',
        ]);

        const state = reactive({
            title: '',
            btn: '',
	        id: 0,
	        materialId: 0,
	        areaId: 0,
	        sectionId: 0,
	        measurement: '',
	        count: '',
	        comment: '',
	        employeeId: 0,
	        supercycle: null,
	        msg: '',
	        findNorm: false,
            visibleErrors: false,
            loader: false,
        });

	    const isMaterialIdError = computed(() => !state.materialId);
	    const isAreaIdError = computed(() => !state.areaId);
	    const isSectionIdError = computed(() => state.areaId === 1 ? !state.sectionId : false);
	    const isMeasurementError = computed(() => !state.measurement.length);
	    const isCountError = computed(() => !state.count.length);
	    const isCommentError = computed(() => !state.comment.length);
	    const isEmployeeIdError = computed(() => !state.employeeId);

        const errors = computed(() => {
            return isMaterialIdError.value ||
		            isSectionIdError.value ||
		            isMeasurementError.value ||
		            isCountError.value ||
		            isEmployeeIdError.value;
        });

        onMounted(() => {
            console.log(modal.data);
            if (modal.data.type === 'add') {
                state.title = 'Выдача ТМЦ';
                state.btn = 'Выдать';
            } else {
	            state.title = 'Редактирование ТМЦ';
	            state.btn = 'Сохранить';

                state.id = modal.data.material.id;
                state.comment = modal.data.material.comment ? modal.data.material.comment : '';
                state.count = modal.data.material.count;
                state.employeeId = modal.data.material.employeeId;
                state.materialId = modal.data.material.materialId;
                state.sectionId = modal.data.material.sectionId;
                state.measurement = modal.data.material.measurement;
            }

            sections.value = modal.data.sections;

            api.sectionList.get().then((r: any) => {
	            sections.value = r.data;
            });

	        api.materials.modal().then((r: any) => {
		        materials.value = r.data;
	        });

            // api.info.get().then((r: any) => {
            //     areas.value = r.data.areas;
            // });

	        // api.grid.get().then((r: any) => {
            //     console.log(r.data);
            //     sections.value = r.data.map((i: any) => {
            //         if (typeof i.sectionNumber === 'undefined') {
            //             console.log(i, i.sectionNumber, i.locomotiveModel, i.contractorName);
            //         }
	        //
            //         return {
            //             ...i,
            //             ...{
            //                 sectionNumber: '№' + i.sectionNumber + ' ' + i.locomotiveModel + ' (' + i.contractorName + ')',
            //             },
            //         };
            //     });
		    //     sections.value.unshift({
            //         // @ts-ignore
            //         id: 0,
            //         // @ts-ignore
            //         sectionNumber: 'Другое',
		    //     });
	        // });

            api.employees.modal().then((r: any) => {
                employees.value = r.data;
            });
        });

        function send() {
            if (errors.value) {
                state.visibleErrors = true;
            } else {
                state.visibleErrors = false;
                state.loader = true;
	            if (modal.data.type === 'add') {
		            api.stock.release.post({
			            materialId: state.materialId,
			            sectionId: state.sectionId !== 0 ? state.sectionId : null,
			            measurement: state.measurement,
			            count: state.count,
			            supercycle: state.supercycle,
			            comment: state.comment.length ? state.comment : null,
			            employeeId: state.employeeId,
		            }).then((r: any) => {
			            if (r.data) {
                            modal.ok({
                	            material: r.data,
                            });
			            }
		            }).finally(() => state.loader = false);
	            } else {
		            api.stock.release.patch({
			            id: state.id,
			            materialId: state.materialId,
			            sectionId: state.sectionId !== 0 ? state.sectionId : null,
			            measurement: state.measurement,
			            count: state.count,
			            supercycle: state.supercycle,
			            comment: state.comment.length ? state.comment : null,
			            employeeId: state.employeeId,
		            }).then((r: any) => {
			            if (r.data) {
                            modal.ok({
	                            material: r.data,
                            });
			            }
		            }).finally(() => state.loader = false);
	            }
            }
        }

	    function ok() {
		    modal.ok({
			    asd: 123,
		    });
	    }

        function close() {
            modal.close();
        }

	    function cancel() {
		    modal.cancel({
			    zxc: 123,
		    });
	    }

	    function onMaterialSelected(_:any) {
		    state.materialId = _.id;
	    }

	    function onAreaSelected(_:any) {
		    state.areaId = _.id;
	    }

	    function onSectionSelected(_:any) {
		    state.sectionId = _.id;
	    }

        function onEmployeeSelected(_: any) {
            state.employeeId = _.id;
        }

        watch(() => [ state.employeeId, state.materialId, state.sectionId ], (v) => {
            if (v[0] !== 0 && v[1] !== 0) {
                if (state.sectionId !== 0) {
	                api.findNorm.patch({
		                sectionId: state.sectionId,
		                materialId: state.materialId,
	                }).then((r: any) => {
		                state.supercycle = r.data.supercycle;
		                state.msg = r.data.message;
		                state.findNorm = true;
	                });
                } else {
	                state.findNorm = true;
	                state.msg = '';
	                state.supercycle = null;
                }
            }
        });

        return {
            ok,
            cancel,
            close,
            state,
	        send,
	        materials,
	        sections,
	        areas,
	        employees,
	        isMaterialIdError,
	        isAreaIdError,
	        isSectionIdError,
	        isMeasurementError,
	        isCountError,
	        isCommentError,
	        isEmployeeIdError,
	        onMaterialSelected,
	        onAreaSelected,
	        onSectionSelected,
	        onEmployeeSelected,
	        measurements,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
