<template>
    <modal-ui :id="id" size="modal-md" title="Редактирование документа">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row">
                <div class="col-12 col-md-6">
                    <form-group-ui invalid-feedback="Тип не указан" label="Тип документа" required>
                        <select-ui
                            item-key="name"
                            :options="documentTypes"
                            :is-invalid="state.visibleErrors && isDocumentTypeError"
                            placeholder="Тип документа"
                            :searchable="true"
                            :values="[state.documentTypeId]"
                            @on-selected="onDocumentTypeSelected" />
                    </form-group-ui>
                </div>
                <div class="col-12 col-md-6">
                    <form-group-ui invalid-feedback="Подтип не указан" label="Подтип документа" required>
                        <select-ui
                            item-key="name"
                            :options="filteredSubtypes"
                            :disabled="!state.documentTypeId"
                            :is-invalid="state.visibleErrors && isDocumentSubTypeError"
                            placeholder="Подтип документа"
                            :searchable="true"
                            :values="[state.subtypeId]"
                            @on-selected="onDocumentSubTypeSelected" />
                    </form-group-ui>
                </div>
                <div class="col-12 col-md-6">
                    <form-group-ui invalid-feedback="Укажите номер" label="Номер документа" required>
                        <input-ui v-model="state.documentNumber" :is-invalid="state.visibleErrors && isDocumentNumberError"
                            placeholder="Номер документа" />
                    </form-group-ui>
                </div>
                <div class="col-12 col-md-6">
                    <form-group-ui invalid-feedback="Укажите наименование" label="Наименование" required>
                        <input-ui v-model="state.name" :is-invalid="state.visibleErrors && isNameError"
                            placeholder="Наименование" />
                    </form-group-ui>
                </div>
                <div class="col-12 col-md-6">
                    <form-group-ui
                        label="Контрагент">
                        <select-ui
                            item-key="name"
                            :options="contractors"
                            placeholder="Контрагент"
                            :searchable="true"
                            :values="[state.contractorId]"
                            @on-selected="onSupplierSelected" />
                    </form-group-ui>
                </div>
                <div class="col-12 col-md-6">
                    <form-group-ui
                        label="Локомотив"
                        required>
                        <select-ui
                            item-key="name"
                            :disabled="!state.contractorId"
                            :options="filteredLocomotives"
                            placeholder="Локомотив"
                            :searchable="true"
                            :values="[state.locomotiveId]"
                            @on-selected="handleLocomotiveSelect">
                        </select-ui>
                    </form-group-ui>
                </div>
                <div class="col-12 col-md-6">
                    <form-group-ui invalid-feedback="Выберите дату документа" label="Дата документа" required>
                        <date-picker v-model="state.documentedAt" as readonly :timezone="'Asia/Almaty'" class="inline-block h-full" :is24hr="true" mode="date">
                            <template #default="{ inputValue, togglePopover }">
                                <div class="flex items-center">
                                    <input class="form-control"
                                        :class="{ 'is-invalid': isDocumentedAtError && state.visibleErrors, 'bg-white': true }"
                                        :value="inputValue"
                                        @blur="togglePopover()" @focus="togglePopover()">
                                    <div class="invalid-feedback">
                                        Укажите дату документа
                                    </div>
                                </div>
                            </template>
                        </date-picker>
                    </form-group-ui>
                </div>
                <div class="col-12 col-md-6">
                    <form-group-ui label="Краткое содержание">
                        <input-ui v-model="state.comment" placeholder="Краткое содержание" />
                    </form-group-ui>
                </div>
                <div class="col-12 mt-3">
                    <tags-input-ui :tags="state.tags" @on-tags-changed="handleChangeTag" placeholder="Тэги" />
                </div>
            </div>
            <div class="col-12 text-center mt-3">
                <button-ui button-class="btn-primary">
                    Сохранить
                </button-ui>
            </div>
        </form>
    </modal-ui>
</template>

<script setup lang="ts">
import { onMounted, reactive, computed, ref } from "vue";
import { CATEGORY_TYPE } from '@depo/constants/contractor';
import { useModal } from "@/composables/useModal";
import api from "@/api";
import { ISupplier } from "@/@types/ISupplier";
import { ILocomotive } from "@/@types/ILocomotive";
import { IDocumentSubtype, IDocumentType } from "@/@types/IDocument";
import { DatePicker } from 'v-calendar';

type stateType = {
    id: number,
    visibleErrors: boolean,
    loader: boolean,
    documentId: number,
    documentTypeId: null | number,
    documentNumber: string,
    documentNames: string[],
    subtypeId: null | number,
    name: string,
    locomotiveId: number | string,
    documentType: string,
    contractorId: null | number, 
    tags: Array<string>,
    comment: string,
    documentedAt: string,
};

const props = defineProps({
    id: {
        type: String,
        default: '',
    },
});

const modal = useModal(props.id);
const contractors = ref<ISupplier[]>([]);
const locomotives = ref<ILocomotive[]>([]);
const documentTypes = ref<IDocumentType[]>([]);
const state: stateType = reactive({
    id: 0,
    visibleErrors: false,
    loader: false,
    name: '',
    documentType: '',
    documentNames: [],
    documentNumber: '',
    documentTypeId: null,
    subtypeId: null,
    documentId: 0,
    locomotiveId: '',
    contractorId: null,
    tags: [],
    comment: '',
    documentedAt: '',
});

const filteredLocomotives = computed(() => {
    if (!state.contractorId) {
        return [];
    }

    const contractor = contractors.value.find(c => c.id === state.contractorId);

    if (!contractor) {
        return [];
    }

    return locomotives.value.filter(locomotive => locomotive.contractorId === Number(contractor.rollingStockOwner?.id));
});

const filteredSubtypes = computed(() => {
    if (!state.documentTypeId) {
        return [];
    }

    const selectedDocumentType = documentTypes.value.find(dt => dt.id === state.documentTypeId);
    if (!selectedDocumentType) {
        return [];
    }
    return selectedDocumentType.subTypes;
});

onMounted(() => {
    if (Object.keys(modal.data.document).length) {
        state.documentId = modal.data.document.id;
        state.tags = modal.data.document.tags;
        state.documentNames = modal.data.documentNames;
        state.contractorId = modal.data.document.contractorId;
        state.subtypeId = modal.data.document.subTypeId;
        state.name = modal.data.document.fileName;
        state.comment = modal.data.document.comment;
        state.documentedAt = modal.data.document.documentedAt;
        state.documentNumber = modal.data.document.documentNumber;
    }

    api.supplier.get().then(r => {
        contractors.value = r.data;
    });

    api.document.getTypes().then(r => {
        documentTypes.value = r.data;
        if (!state.subtypeId) {
            return
        }
        const type = r.data.find((type: IDocumentType) => {
            return type.subTypes.some((s: IDocumentSubtype) => s.id === state.subtypeId);
        });
        if (!type) {
            return;
        }
        state.documentTypeId = type.id;
    });

    api.locomotives.getAll().then(data => {
        locomotives.value = data;
    }).catch(console.error);
});

const isNameError = computed(() => !state.name.length || !/[\wа-яА-Я]+/ig.test(state.name) || state.documentNames.includes(state.name));
const isDocumentTypeError = computed(() => !state.documentTypeId);
const isDocumentSubTypeError = computed(() => !state.subtypeId);
const isDocumentNumberError = computed(() => !state.documentNumber);
const isDocumentedAtError = computed(() => !state.documentedAt);

const isErrorsExist = computed(() => isDocumentTypeError.value ||
    isNameError.value ||
    isDocumentNumberError.value ||
    isDocumentedAtError.value ||
    isDocumentSubTypeError.value);

function onSupplierSelected(supplier: ISupplier) {
    state.locomotiveId = '';
    if (state.contractorId === supplier.id) {
        state.contractorId = null;
    } else {
        state.contractorId = supplier.id;
    }
}

function onDocumentTypeSelected(documentType: IDocumentType) {
    state.subtypeId = null;
    if (state.documentTypeId === documentType.id) {
        state.documentTypeId = null;
    } else {
        state.documentTypeId = documentType.id;
    }
}

function onDocumentSubTypeSelected(documentType: IDocumentType) {
    if (state.subtypeId === documentType.id) {
        state.subtypeId = null;
    } else {
        state.subtypeId = documentType.id;
    }
}

function handleLocomotiveSelect(locomotive: ILocomotive) {
    if (state.locomotiveId === locomotive.id) {
        state.locomotiveId = '';
    } else {
        state.locomotiveId = locomotive.id;
    }
}

function handleChangeTag(tags: Array<string>) {
    state.tags = tags;
}

function send() {
    if (isErrorsExist.value) {
        state.visibleErrors = true;
        console.log('returned');
        return;
    }
    state.visibleErrors = false;
    state.loader = true;

    api.document.update(state.documentId, {
        documentType: state.documentType,
        tags: state.tags,
        name: state.name,
        contractorId: state.contractorId,
        locomotiveId: state.locomotiveId || null,
        subtypeId: state.subtypeId,
        comment: state.comment,
        documentedAt: state.documentedAt,
        documentNumber: state.documentNumber,
    }).then(r => {
        modal.ok(r.data);
    })
}

</script>

<style scoped lang="scss"></style>
