<template>
    <div v-if="isShow" class="loader__wrapper">
        <div class="loader__spinner" :class="spinnerClasses"></div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";

export default defineComponent({
    name: "LoaderUi",
    props: {
	    isShow: Boolean,
	    size: {
            type: String,
		    default: '',
	    },
    },
    setup(props) {
        const spinnerClasses = computed(() => {
            if (props.size === 'sm') return '--sm';
        });

        return {
            spinnerClasses,
        };
    },
});
</script>

<style scoped lang="scss">

</style>