<template>
    <div v-if="table.rows.length" class="col-12 mt-3">
        <div class="card border">
            <table-lite
                class="table table-bordered"
                :columns="table.columns"
                :is-hide-paging="true"
                :is-loading="table.isLoading"
                :is-slot-mode="true"
                :rows="table.rows"
                :sortable="table.sortable"
                :total="table.totalRecordCount"
                @do-search="doSearch"
                @is-finished="table.isLoading = false">
                <template #locomotive="data">
                    <router-link :to=" { name: 'LocationShow', params: { locomotiveId: data.value.locoId, sectionId: data.value.sectionId, date: moment(state.date).format('YYYY-MM-DD') } }">
                        №{{ data.value.sectionNumber }} {{ data.value.locoModel }} ({{ data.value.contractorName }})
                    </router-link>
                </template>
            </table-lite>
        </div>
    </div>
    <div v-else class="col-12">
        <div class="card border">
            <div class="card-body">
                <div class="alert alert-warning m-0">
                    <div class="alert-message">
                        Отчетов нет
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive } from "vue";
import { useRouter } from "vue-router";
import { DatePicker } from 'v-calendar';
import api from "@/api";
import moment from 'moment-timezone';
import TableLite from "vue3-table-lite/ts";

export default defineComponent({
    name: "IndexView",
    components: {
        DatePicker,
	    TableLite,
    },
    setup() {
        const router = useRouter();
        const state = reactive({
            loader: false,
            date: new Date(),
	        visibleErrors: false,
        });
	    const table = reactive({
		    isLoading: false,
		    columns: [
			    {
				    label: "Локомотив",
				    field: "locomotive",
				    sortable: false,
				    isKey: false,
				    // display: function (row: any) {
                    //     return '<router-link>№' + row.sectionNumber + ' ' + row.locoModel + ' ' + '(' + row.contractorName + ')</router-link>';
                    //     // return '№' + row.sectionNumber + ' ' + row.locoModel + ' ' + '(' + row.contractorName + ')';
				    // },
			    },
			    // {
                //     label: "Поступил в депо",
                //     display: function (row: any) {
                // 	    if (row.receiptDate === null) return '-';
                //         else return moment(row.receiptDate).locale('ru').format('DD.MM.YYYY');
                //     },
			    // },
			    // {
                //     label: 'Заехал на канаву',
                //     display: function (row: any) {
                //         if (row.repairStart === null) return '-';
                // 	    else return moment(row.repairStart).locale('ru').format('DD.MM.YYYY');
                //     },
			    // },
			    {
				    label: "Дней в ремонте",
				    field: "days",
				    sortable: false,
				    isKey: true,
			    },
			    {
				    label: "Вид работ",
				    field: "jobTypeName",
				    sortable: false,
				    isKey: true,
			    },
			    {
				    label: "Место",
				    field: "placeName",
				    sortable: true,
				    isKey: true,
			    },
			    // {
                //     label: "План",
                //     field: 'workPlan',
                //     sortable: false,
                //     isKey: true,
			    // },
			    // {
                //     label: "Закрепление людей",
                //     field: 'fixedPeople',
                //     sortable: false,
                //     isKey: true,
			    // },
			    {
                    label: 'Работы',
				    isKey: false,
				    display: function (row: any) {
                        return 'Работы по циклу: ' + (row.workPlan ? row.workPlan : '-') + '<br>Работы по сверхциклу: ' + (row.fixedPeople ? row.fixedPeople : '-');
				    },
			    },
			    // {
                //     label: 'Запчасти',
                //     field: 'spares',
                //     sortable: false,
                //     isKey: false,
                //     display: function (row: any) {
                //         if (row.spares) return '<input checked class="form-check-input" disabled type="checkbox">';
                //         else return '<input class="form-check-input" disabled type="checkbox">';
                //     },
			    // },
			    {
				    label: "Примечание",
				    field: "info",
				    sortable: false,
				    isKey: false,
			    },
		    ],
		    rows: [],
		    totalRecordCount: 0,
		    sortable: {
			    order: "dt",
			    sort: "asc",
		    },
	    });

        const isDateError = computed(() => state.date === null);

        onMounted(() => send());

        function send() {
            if (isDateError.value) {
                state.visibleErrors = true;
            } else {
                state.visibleErrors = false;
                api.dailyReport.post(moment(state.date as unknown as string).format('YYYY-MM-DD') as string).then((r: any) => {
                    table.rows = r.data;
                });
            }
        }

	    function doSearch(offset: any, limit: any, order: any, sort: any) {
		    table.isLoading = true;

		    table.rows = table.rows.sort(function (a: any, b: any) {
			    if (sort === 'asc') {
				    return b[order].localeCompare(a[order]);
			    } else if (order === 'placeName') {
				    return a[order].localeCompare(b[order]);
			    }
		    });

		    table.isLoading = false;
	    }

        function print() {
            router.push({
                name: 'ReportPrintIndex',
                query: {
                    date: moment(state.date as unknown as string).format('YYYY-MM-DD HH:mm:ss'),
                },
            });
        }

        return {
	        state,
	        table,
            send,
	        isDateError,
	        doSearch,
	        moment,
	        print,
        };
    },
});
</script>

<style scoped lang="scss">
.vtl-table {
	width: 100%;
	display: table !important;
}
::v-deep(.vtl-table-responsive-sm) {
	display: table !important;
}
::v-deep(.vtl-table .vtl-thead .vtl-thead-th) {
	color: #333;
	background-color: #fff;
	//2px solid #dee2e6
	border-color: #dee2e6;
	white-space: nowrap !important
}
::v-deep(.vtl-table td),
::v-deep(.vtl-table tr) {
	//border: none;
	border-color: #dee2e6;
}
::v-deep(.vtl-paging-info) {
	color: #333;
}
::v-deep(.vtl-paging-count-label),
::v-deep(.vtl-paging-page-label) {
	color: #333;
}
::v-deep(.vtl-paging-pagination-page-link) {
	//border: none;
}
</style>