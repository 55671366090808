<template>
    <div class="mt-3">
        <div class="overflow-hidden">
            <div class="bg-white position-relative overflow-auto mw-100 pb-5" style="max-height: 650px;">
                <table class="table table-bordered">
                    <thead class="sticky-top bg-white">
                        <tr class="position-relative border-0">
                            <th class="p-2">
                                Наименование ТМЦ
                            </th>
                            <th class="p-2">
                                № потребности ТМЦ
                            </th>
                            <th class="p-2">
                                Дата поступления на закуп
                            </th>
                            <th class="p-2">
                                № протокола выбора поставщика
                            </th>
                            <th class="p-2">
                                Дата отправки Протокола на согласование
                            </th>
                            <th class="p-2">
                                Дата согласования Протокола выбора поставщика
                            </th>
                            <th class="p-2">Поставщик</th>
                            <th class="p-2">
                                Дата оплаты
                            </th>
                            <th class="p-2">
                                Накладная ТМЦ
                            </th>
                            <th class="p-2">
                                Приход ТМЦ
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="state.materials.length">
                            <tr v-for="(requiredMaterial) in state.materials" :key="requiredMaterial.id">
                                <td class="p-2">
                                    {{ requiredMaterial.materialName }}
                                </td>
                                <td class="p-2">
                                    {{ requiredMaterial.documentNumber }}
                                </td>
                                <td class="p-2">
                                    {{
                                        requiredMaterial.requestedAt
                                            ? formatDate(requiredMaterial.requestedAt)
                                            : ''
                                    }}
                                </td>
                                <td class="p-2">
                                    {{ requiredMaterial.protocolNumber }}
                                </td>
                                <td class="p-2">
                                    {{
                                        requiredMaterial.protocolSentForApprovalAt
                                            ? formatDate(requiredMaterial.protocolSentForApprovalAt)
                                            : ''
                                    }}
                                </td>
                                <td class="p-2">
                                    {{
                                        requiredMaterial.protocolAgreedAt
                                            ? formatDate(requiredMaterial.protocolAgreedAt)
                                            : ''
                                    }}
                                </td>
                                <td class="p-2">{{ requiredMaterial.supplierName }}</td>
                                <td class="p-2">
                                    {{
                                        requiredMaterial.paidAt
                                            ? formatDate(requiredMaterial.paidAt)
                                            : ''
                                    }}
                                </td>
                                <td class="p-2">

                                </td>
                                <td class="p-2">

                                </td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <td colspan="10" class="p-2 text-center">Записи отсутствуют</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script lang="ts" async setup>

import api from "@/api";
import { reactive } from "vue";
import formatDate from "@/utils/formatDate";

type consolidatedReportOnMaterialMovementType = {
    id: number;
    documentNumber: string;
    materialName: string;
    requestedAt: string;
    protocolNumber?: string;
    protocolSentForApprovalAt?: string;
    protocolAgreedAt?: string;
    supplierName?: string;
    paidAt?: string;
};

type reportStateType = {
    materials: consolidatedReportOnMaterialMovementType[];
};
const state: reportStateType = reactive({
    materials: [],
});

api.advancePayment.consolidatedReportOnMaterialMovement().then(({ data }) => {
    state.materials = data;
})

</script>

<style scoped lang="scss"></style>
