<template>
    <modal-ui
        :id="id"
        size="modal-md"
        :title="state.title">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Укажите название"
                        label="Название"
                        required>
                        <input-ui
                            v-model="state.name"
                            :is-invalid="state.visibleErrors && isNameError"
                            placeholder="Название" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Укажите адрес"
                        label="Адрес"
                        required>
                        <input-ui
                            v-model="state.address"
                            :is-invalid="state.visibleErrors && isAddressError"
                            placeholder="Адрес" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Укажите БИН"
                        label="БИН"
                        required>
                        <input-ui
                            v-model="state.bin"
                            :is-invalid="state.visibleErrors && isBinError"
                            placeholder="БИН" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Укажите контакты"
                        label="Контакты"
                        required>
                        <input-ui
                            v-model="state.contacts"
                            :is-invalid="state.visibleErrors && isContactsError"
                            placeholder="Контакты" />
                    </form-group-ui>
                </div>
                <div class="col-md-6">
                    <form-group-ui
                        invalid-feedback="Укажите инфо"
                        label="Инфо"
                        required>
                        <input-ui
                            v-model="state.info"
                            :is-invalid="state.visibleErrors && isInfoError"
                            placeholder="Инфо" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                    <label class="form-check form-check-inline">
                            <input
                                v-model="state.isAnchorSupplier"
                                class="form-check-input"
                                type="checkbox">
                            <span class="form-check-label">Якорный поставщик</span>
                        </label>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Укажите признак" label="Признак контрагента">
                        <select-ui
                            :is-invalid="state.visibleErrors && isCategoryTypeError"
                            :multiple="true"
                            :options="CATEGORY_TYPE_COLLECTION.map(c => ({ name: c, id: c }))"
                            placeholder="Признак"
                            :values="state.categoryTypes"
                            @on-updated="handleCategoryTypesSelect" />
                    </form-group-ui>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button-ui
                        button-class="btn-primary">
                        {{ state.btn }}
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script async setup lang="ts">
import { onMounted, reactive, computed } from "vue";
import { useModal } from "@/composables/useModal";
import api from "@/api";
import { CATEGORY_TYPE_COLLECTION } from '@depo/constants/contractor';

const props = defineProps({
    id: {
        type: String,
        default: '',
    },
});

const modal = useModal(props.id);

const state = reactive({
    title: '',
    btn: '',
    id: 0,
    name: '',
    address: '',
    contacts: '',
    info: '',
    bin: '',
    isAnchorSupplier: false,
    visibleErrors: false,
    loader: false,
    categoryTypes: [],
});

const isNameError = computed(() => !state.name.length);
const isAddressError = computed(() => !state.address || !state.address.length);
const isBinError = computed(() => !state.bin || !state.bin.length);
const isInfoError = computed(() => !state.info || !state.info.length);
const isContactsError = computed(() => !state.contacts || !state.contacts.length);
const isCategoryTypeError = computed(() => !state.categoryTypes.length);

function handleCategoryTypesSelect(_c: any) {
    state.categoryTypes = _c.map((c: any) => c.id);
}

const errors = computed(() => {
    return isNameError.value ||
        isAddressError.value ||
        isBinError.value ||
        isInfoError.value ||
        isCategoryTypeError.value ||
        isContactsError.value;
});

onMounted(() => {
    if (modal.data.type === 'add') {
        state.title = 'Добавление поставщика';
        state.btn = 'Добавить';
    } else {
        state.title = 'Редактирование поставщика';
        state.btn = 'Сохранить';

        state.id = modal.data.supplier.id;
        state.name = modal.data.supplier.name;
        state.address = modal.data.supplier.address;
        state.contacts = modal.data.supplier.contacts;
        state.info = modal.data.supplier.info;
        state.bin = modal.data.supplier.bin;
        state.isAnchorSupplier = modal.data.supplier.isAnchorSupplier;
        state.categoryTypes = modal.data.supplier.categoryTypes;
    }
});

function send() {
    if (errors.value) {
        state.visibleErrors = true;
    } else {
        state.visibleErrors = false;
        state.loader = true;
        if (modal.data.type === 'add') {
            api.supplier.create({
                name: state.name,
                address: state.address,
                contacts: state.contacts,
                info: state.info,
                bin: state.bin,
                isAnchorSupplier: state.isAnchorSupplier,
                categoryTypes: state.categoryTypes,
            }).then((r: any) => {
                if (r.data) {
                    modal.ok({
                        supplier: r.data,
                    });
                }
            }).finally(() => state.loader = false);
        } else {
            api.supplier.update({
                id: state.id,
                name: state.name,
                address: state.address,
                contacts: state.contacts,
                info: state.info,
                bin: state.bin,
                isAnchorSupplier: state.isAnchorSupplier,
                categoryTypes: state.categoryTypes,
            }).then((r: any) => {
                if (r.data) {
                    modal.ok({
                        supplier: r.data,
                    });
                }
            }).finally(() => state.loader = false);
        }
    }
}

function ok() {
    modal.ok({
        asd: 123,
    });
}

function close() {
    modal.close();
}

function cancel() {
    modal.cancel({
        zxc: 123,
    });
}
</script>

<style scoped lang="scss">

</style>
