<template>
    <modal-ui
        :id="id"
        size="modal-md"
        :title="state.title">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Укажите наименование"
                        label="Наименование"
                        required>
                        <input-ui
                            v-model="state.name"
                            :is-invalid="state.visibleErrors && isNameError"
                            placeholder="Наименование" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Укажите альтернативное наименование"
                        label="Альтернативное наименование">
                        <input-ui
                            v-model="state.alternative"
                            placeholder="Альтернативное наименование" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Укажите ГОСТ / ТУ"
                        label="ГОСТ / ТУ">
                        <input-ui
                            v-model="state.gost"
                            maxlength="50"
                            placeholder="ГОСТ / ТУ" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Укажите чертежный код"
                        label="Чертежный код">
                        <input-ui
                            v-model="state.code"
                            maxlength="30"
                            placeholder="Чертежный код" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Укажите артикульный номер"
                        label="Артикульный номер">
                        <input-ui
                            v-model="state.itemCode"
                            placeholder="Артикульный номер" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Укажите единицу измерения"
                        label="Единица измерения"
                        required>
                        <select-ui
                            :is-invalid="state.visibleErrors && isMeasurementError"
                            :options="measurements"
                            placeholder="Единица измерения"
                            :values="[state.measurement]"
                            @on-selected="onMeasurementSelected" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Укажите категорию"
                        label="Категория"
                        required>
                        <select-ui
                            :is-invalid="state.visibleErrors && isCategoryError"
                            :options="categories"
                            placeholder="Категория"
                            :values="[state.categoryId]"
                            @on-selected="onCategoryNameSelected" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Укажите конструктив"
                        label="Конструктив">
                        <select-ui
                            :multiple="true"
                            :options="constructs"
                            placeholder="Конструктив"
                            :searchable="true"
                            :values="state.constructId"
                            @on-updated="onConstructSelected" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        label="Код ЕНС ТРУ">
                        <input-ui
                            v-model="state.enstruCode"
                            placeholder="Код ЕНС ТРУ" />
                    </form-group-ui>
                </div>
                <!--                <div class="col-12">-->
                <!--                    <label class="form-check form-check-inline">-->
                <!--                        <input-->
                <!--                            v-model="state.te10"-->
                <!--                            class="form-check-input"-->
                <!--                            type="checkbox">-->
                <!--                        <span class="form-check-label">ТЭ10</span>-->
                <!--                    </label>-->
                <!--                </div>-->
                <!--                <div class="col-12">-->
                <!--                    <label class="form-check form-check-inline">-->
                <!--                        <input-->
                <!--                            v-model="state.tem2"-->
                <!--                            class="form-check-input"-->
                <!--                            type="checkbox">-->
                <!--                        <span class="form-check-label">ТЭМ2</span>-->
                <!--                    </label>-->
                <!--                </div>-->
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button-ui
                        button-class="btn-primary">
                        {{ state.btn }}
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref, watch } from "vue";
import { useModal } from "@/composables/useModal";
import api from "@/api";
import { DatePicker } from 'v-calendar';

export default defineComponent({
    name: "MaterialModal",
    components: {
        DatePicker,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
	    const modal = useModal(props.id);

        const state = reactive({
            title: '',
            btn: '',
	        id: 0,
	        name: '',
	        gost: '',
	        nameBy185: '',
	        code: '',
            enstruCode: '',
	        itemCode: '',
	        measurement: 0,
	        categoryId: 0,
	        constructId: [],
	        alternative: '',
	        te10: false,
	        tem2: false,
            visibleErrors: false,
            loader: false,
        });

        const measurements = ref([
	        { id: 1, name: 'гр.' },
	        { id: 2, name: 'кв. м.' },
	        { id: 3, name: 'кг.' },
	        { id: 4, name: 'комплект' },
	        { id: 5, name: 'куб. м.' },
	        { id: 6, name: 'литр' },
	        { id: 7, name: 'метр' },
	        { id: 8, name: 'мл.' },
	        { id: 9, name: 'мм.' },
	        { id: 10, name: 'п.м.' },
	        { id: 11, name: 'пара' },
	        { id: 12, name: 'рулон' },
	        { id: 13, name: 'см.' },
	        { id: 14, name: 'тонна' },
	        { id: 15, name: 'упаковка' },
	        { id: 16, name: 'штук' },
        ]);

        const categories = ref([
            { id: 1, name: 'Запасная часть' },
            { id: 2, name: 'Расходный материал' },
            { id: 3, name: 'Сырье' },
            { id: 4, name: 'Инструмент и инвентарь' },
            { id: 5, name: 'ГСМ' },
            { id: 6, name: 'Прочие' },
        ]);

        const constructs = ref([]);

	    const isNameError = computed(() => !state.name.length);
	    const isMeasurementError = computed(() => !state.measurement);
	    const isCategoryError = computed(() => !state.categoryId);

        const errors = computed(() => {
            return isNameError.value || isMeasurementError.value || isCategoryError.value;
        });

        onMounted(() => {
            if (modal.data.type === 'add') {
                state.title = 'Добавление материала';
                state.btn = 'Добавить';
            } else {
	            state.title = 'Редактирование материала';
	            state.btn = 'Сохранить';

                state.id = modal.data.material.id;
                state.name = modal.data.material.name;
                state.nameBy185 = modal.data.material.nameBy185Norm;
                state.enstruCode = modal.data.material.enstruCode;
                state.code = modal.data.material.code;
                state.itemCode = modal.data.material.itemCode;
                state.gost = modal.data.material.gost;
                // state.measurement = modal.data.material.measurement;
	            // @ts-ignore
                state.constructId = modal.data.material.materialSubgroups.map((i: any) => i.subgoupId);
                console.log(modal.data.material);
                state.alternative = modal.data.material.alternative;
                // @ts-ignore
                state.categoryId = categories.value.find((i: any) => i.name === modal.data.material.categoryName)?.id;
	            // @ts-ignore
                state.measurement = measurements.value.find((i: any) => i.name === modal.data.material.unit)?.id;
            }

            api.constructs.get().then(r => constructs.value = r.data);
        });

        function send() {
            if (errors.value) {
                state.visibleErrors = true;
            } else {
                state.visibleErrors = false;
                state.loader = true;
	            if (modal.data.type === 'add') {
		            api.materials.post({
			            name: state.name,
			            code: state.code,
			            itemCode: state.itemCode,
			            gost: state.gost,
			            alternative: state.alternative,
                        enstruCode: state.enstruCode,
			            categoryName: categories.value.find((i: any) => i.id === state.categoryId)?.name || null,
			            constructs: state.constructId,
			            measurement: measurements.value.find((i: any) => i.id === state.measurement)?.name || null,
		            }).then((r: any) => {
			            if (r.data) {
                            modal.ok({
                	            material: r.data,
                            });
			            }
		            }).finally(() => state.loader = false);
	            } else {
		            api.materials.patch(state.id, {
			            name: state.name,
			            code: state.code,
			            itemCode: state.itemCode,
			            gost: state.gost,
			            alternative: state.alternative,
                        enstruCode: state.enstruCode,
			            categoryName: categories.value.find((i: any) => i.id === state.categoryId)?.name || null,
			            constructs: state.constructId,
			            measurement: measurements.value.find((i: any) => i.id === state.measurement)?.name || null,
		            }).then((r: any) => {
			            if (r.data) {
                            modal.ok({
	                            material: r.data,
                            });
			            }
		            }).finally(() => state.loader = false);
	            }
            }
        }

	    function onMeasurementSelected(_m: any) {
		    state.measurement = _m.id;
	    }

	    function onCategoryNameSelected(_c: any) {
		    state.categoryId = _c.id;
	    }

        function onConstructSelected(_c: any) {
            state.constructId = _c.map((i: any) => i.id);
        }

	    function ok() {
		    modal.ok({
			    asd: 123,
		    });
	    }

        function close() {
            modal.close();
        }

	    function cancel() {
		    modal.cancel({
			    zxc: 123,
		    });
	    }

        return {
            ok,
            cancel,
            close,
            state,
	        send,
	        isNameError,
	        isMeasurementError,
	        onMeasurementSelected,
	        measurements,
	        categories,
	        onCategoryNameSelected,
	        constructs,
	        onConstructSelected,
	        isCategoryError,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
