<template>
    <modal-ui :id="id" size="modal-md" title="Забрать потребность со склада">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row">
                <div class="col-12 row">
                    <div class="col-4 border text-center fw-bold">
                        Материал
                    </div>
                    <div class="col-2 border text-center fw-bold">
                        Ед изм
                    </div>
                    <div class="col-3 border text-center fw-bold">
                        Количество
                    </div>
                    <div class="col-3 border text-center fw-bold">
                        Макс. количество
                    </div>
                </div>
                <div v-for="material in state.requiredMaterials" :key="material.materialId">
                    <div class="row col-12" v-for="materialByLocomotive in material.ids" :key="materialByLocomotive.id">
                        <div class="col-4 border">
                            <b>{{ materialByLocomotive.model }}</b><br />
                            {{ material?.materialName }}
                        </div>
                        <div class="col-2 border d-flex align-items-center">
                            {{ material?.unit }}
                        </div>
                        <div class="col-3 border d-flex align-items-center">
                            <form-group-ui invalid-feedback="Укажите точное количество" required>
                                <input-ui class="mt-2 text-end" v-model="materialByLocomotive.count"
                                    :is-invalid="state.visibleErrors && isQuantityError(materialByLocomotive)"
                                    type="number" placeholder="Цена" />
                            </form-group-ui>
                        </div>
                        <div class="col-3 border d-flex align-items-center justify-content-end">
                            <b>{{ materialByLocomotive.quantity }}</b>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-12 text-center mt-3">
                <button-ui button-class="btn-primary">
                    Сохранить
                </button-ui>
            </div>
        </form>
    </modal-ui>
</template>

<script setup lang="ts">
import { onMounted, reactive, computed } from "vue";
import { useModal } from "@/composables/useModal";
import api from "@/api";

type materialByLocomotiveType = {
    id: number;
    quantityInStock: number;
    count: number;
    model: string;
    quantity: number;
};

type purchasingMaterialType = {
    quantity: number;
    materialId: number;
    materialName: string;
    unit: string;
    ids: materialByLocomotiveType[];
};

type stateType = {
    id: number;
    visibleErrors: boolean;
    loader: boolean;
    requiredMaterials: purchasingMaterialType[];
};

type payloadType = {
    quantity: number;
    materialId: number;
};

const props = defineProps({
    id: {
        type: String,
        default: '',
    },
});

const modal = useModal(props.id);
const state: stateType = reactive({
    id: 0,
    visibleErrors: false,
    loader: false,
    requiredMaterials: [],
});
onMounted(() => {
    if (Object.keys(modal.data.payload).length) {
        state.requiredMaterials = modal.data.payload.map((p: purchasingMaterialType) => ({
            ...p,
            ids: p.ids.map((material) => ({
                ...material,
                count: material.quantityInStock,
            }))
        }));
    }
});

const isQuantityError = computed(() => (material: materialByLocomotiveType) => {
    return +material.count < 0 || !isFinite(+material.count) || +material.count > +material.quantity;
});

const isErrorsExist = computed(() => {
    return state.requiredMaterials.some((rm) => rm.ids.some((material) => isQuantityError.value(material)));
});

function send() {
    if (isErrorsExist.value) {
        state.visibleErrors = true;
        return;
    }
    state.visibleErrors = false;
    state.loader = true;
    const payload = state.requiredMaterials.reduce((acc, curVal) => {
        return [...acc, ...curVal.ids.map(material => ({
            materialId: material.id,
            quantity: material.count,
        }))];
    }, [] as payloadType[]);
    api.purchaseRequests.takeFromStock(({ materials: payload })).then(r => {
        modal.ok(r.data);
    })
}

</script>

<style scoped lang="scss"></style>
