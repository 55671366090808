import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { class: "col-12 col-md-3" }
const _hoisted_2 = { class: "col-12 col-md-3" }
const _hoisted_3 = { class: "col-12 col-md-3" }
const _hoisted_4 = { class: "col-12 col-md-3" }
const _hoisted_5 = { class: "col-12 col-md-3" }
const _hoisted_6 = { class: "col-12 col-md-3" }
const _hoisted_7 = { class: "col-12 col-md-3 d-flex align-items-center" }
const _hoisted_8 = { class: "col-12 col-md-3 d-flex align-items-center" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-12 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader_ui = _resolveComponent("loader-ui")!
  const _component_input_ui = _resolveComponent("input-ui")!
  const _component_form_group_ui = _resolveComponent("form-group-ui")!
  const _component_select_ui = _resolveComponent("select-ui")!
  const _component_button_ui = _resolveComponent("button-ui")!
  const _component_modal_ui = _resolveComponent("modal-ui")!

  return (_openBlock(), _createBlock(_component_modal_ui, {
    id: _ctx.id,
    size: "modal-xl",
    title: "Добавление материала"
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        class: "loader",
        onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.send && _ctx.send(...args)), ["prevent"]))
      }, [
        _createVNode(_component_loader_ui, {
          "is-show": _ctx.state.loader
        }, null, 8, ["is-show"]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.state.materials, (item, itemInd) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "row mb-4",
            key: itemInd
          }, [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_form_group_ui, {
                "invalid-feedback": "Укажите наименование ТМЦ",
                label: "Наименование ТМЦ",
                required: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_input_ui, {
                    placeholder: "Наименование ТМЦ",
                    modelValue: _ctx.state.materials[itemInd].originalName,
                    "onUpdate:modelValue": ($event: any) => ((_ctx.state.materials[itemInd].originalName) = $event),
                    "is-invalid": _ctx.isNameError(item) && _ctx.state.visibleErrors
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "is-invalid"])
                ]),
                _: 2
              }, 1024)
            ]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_form_group_ui, {
                "invalid-feedback": "Укажите единицу измерения",
                label: "Единица измерения",
                required: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_input_ui, {
                    modelValue: _ctx.state.materials[itemInd].originalUnitDimension,
                    "onUpdate:modelValue": ($event: any) => ((_ctx.state.materials[itemInd].originalUnitDimension) = $event),
                    placeholder: "Единица измерения",
                    "is-invalid": _ctx.isUnitError(item) && _ctx.state.visibleErrors
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "is-invalid"])
                ]),
                _: 2
              }, 1024)
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_form_group_ui, {
                "invalid-feedback": "Укажите количество ТМЦ",
                label: "Количество ТМЦ",
                required: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_input_ui, {
                    type: "number",
                    step: "any",
                    modelValue: _ctx.state.materials[itemInd].originalCount,
                    "onUpdate:modelValue": ($event: any) => ((_ctx.state.materials[itemInd].originalCount) = $event),
                    placeholder: "Количество ТМЦ",
                    "is-invalid": _ctx.isCountInvError(item) && _ctx.state.visibleErrors
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "is-invalid"])
                ]),
                _: 2
              }, 1024)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_form_group_ui, {
                "invalid-feedback": "Укажите цену",
                label: "Цена за единицу",
                required: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_input_ui, {
                    modelValue: _ctx.state.materials[itemInd].originalUnitPrice,
                    "onUpdate:modelValue": ($event: any) => ((_ctx.state.materials[itemInd].originalUnitPrice) = $event),
                    step: "any",
                    type: "number",
                    placeholder: "Цена за единицу",
                    "is-invalid": _ctx.isSumInvError(item) && _ctx.state.visibleErrors
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "is-invalid"])
                ]),
                _: 2
              }, 1024)
            ]),
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_form_group_ui, {
                "invalid-feedback": "Выберите материал",
                label: "Материал",
                required: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_select_ui, {
                    "is-invalid": _ctx.state.visibleErrors && _ctx.isApmIdError(item),
                    "item-key": "name",
                    options: _ctx.materials,
                    searchable: true,
                    values: [_ctx.state.materials[itemInd].materialId],
                    onOnUpdated: (m) => _ctx.onMaterialSelected(m, itemInd)
                  }, null, 8, ["is-invalid", "options", "values", "onOnUpdated"])
                ]),
                _: 2
              }, 1024)
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_form_group_ui, {
                "invalid-feedback": "Укажите количество",
                label: "Количество",
                required: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_input_ui, {
                    modelValue: _ctx.state.materials[itemInd].actualCount,
                    "onUpdate:modelValue": ($event: any) => ((_ctx.state.materials[itemInd].actualCount) = $event),
                    type: "number",
                    step: "any",
                    placeholder: "Количество",
                    "is-invalid": _ctx.isCountError(item) && _ctx.state.visibleErrors
                  }, null, 8, ["modelValue", "onUpdate:modelValue", "is-invalid"])
                ]),
                _: 2
              }, 1024)
            ]),
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("span", null, "Ед измерения материала: " + _toDisplayString(_ctx.state.materials[itemInd]?.unit || ''), 1)
            ]),
            _createElementVNode("div", _hoisted_8, [
              (!!itemInd)
                ? (_openBlock(), _createBlock(_component_button_ui, {
                    key: 0,
                    "button-class": "btn-secondary",
                    onClick: ($event: any) => (_ctx.removeMaterialById(itemInd))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("Удалить материал")
                    ]),
                    _: 2
                  }, 1032, ["onClick"]))
                : _createCommentVNode("", true)
            ])
          ]))
        }), 128)),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_button_ui, { "button-class": "btn-primary me-2" }, {
              default: _withCtx(() => [
                _createTextVNode(" Сохранить ")
              ]),
              _: 1
            }),
            _createVNode(_component_button_ui, {
              "button-class": "btn-outline-primary",
              type: "button",
              onClick: _ctx.addOneMoreMaterial
            }, {
              default: _withCtx(() => [
                _createTextVNode("Добавить материал")
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["id"]))
}