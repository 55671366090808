<template>
    <modal-ui
        :id="id"
        size="modal-md"
        :title="state.title">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Укажите наименование"
                        label="Наименование"
                        required>
                        <input-ui
                            v-model="state.name"
                            :is-invalid="state.visibleErrors && isNameError"
                            placeholder="Наименование" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Выберите группу"
                        label="Группа"
                        required>
                        <select-ui
                            :is-invalid="state.visibleErrors && isGroupIdError"
                            item-key="name"
                            :options="groups"
                            placeholder="Группа"
                            :searchable="true"
                            :values="[state.groupId]"
                            @on-selected="onGroupSelected" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Укажите категорию"
                        label="Категория">
                        <input-ui
                            v-model="state.category"
                            placeholder="Категория" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Укажите характеристику"
                        label="Характеристика">
                        <input-ui
                            v-model="state.characteristic"
                            placeholder="Характеристика" />
                    </form-group-ui>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button-ui
                        button-class="btn-primary">
                        {{ state.btn }}
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref, watch } from "vue";
import { initModal, useModal } from "@/composables/useModal";
import api from "@/api";
import { DatePicker } from 'v-calendar';
import moment from 'moment-timezone';

export default defineComponent({
    name: "ToolModal",
    components: {
        DatePicker,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
	    const modal = useModal(props.id);
        const groups = ref([]);

        const state = reactive({
            title: '',
            btn: '',
	        id: 0,
	        name: '',
	        groupId: 0,
	        category: '',
	        characteristic: '',
            visibleErrors: false,
            loader: false,
        });

	    const isNameError = computed(() => !state.name.length);
	    const isGroupIdError = computed(() => !state.groupId);
	    const isCategoryError = computed(() => !state.category.length);
	    const isCharacteristicError = computed(() => !state.characteristic.length);

        const errors = computed(() => {
            return isNameError.value ||
		            isGroupIdError.value;
        });

        onMounted(() => {
            if (modal.data.type === 'add') {
                state.title = 'Добавление инструмента';
                state.btn = 'Добавить';
            } else {
	            state.title = 'Редактирование инструмента';
	            state.btn = 'Сохранить';

                state.id = modal.data.tool.id;
                state.name = modal.data.tool.name;
                state.groupId = modal.data.tool.groupId;
                state.category = modal.data.tool.category;
                state.characteristic = modal.data.tool.characteristic;
            }

            api.toolsgroups.get().then((r: any) => {
	            groups.value = r.data;
            });
        });

	    function onGroupSelected(group: any) {
		    state.groupId = group.id;
	    }

        function send() {
            if (errors.value) {
                state.visibleErrors = true;
            } else {
                state.visibleErrors = false;
                state.loader = true;
	            if (modal.data.type === 'add') {
		            api.tools.post({
			            name: state.name,
			            groupId: state.groupId,
			            category: state.category,
			            characteristic: state.characteristic,
		            }).then((r: any) => {
			            if (r.data) {
                            modal.ok({
                	            tool: r.data,
                            });
			            }
		            }).finally(() => state.loader = false);
	            } else {
		            api.tools.patch({
			            id: state.id,
			            name: state.name,
			            groupId: state.groupId,
			            category: state.category,
			            characteristic: state.characteristic,
		            }).then((r: any) => {
			            if (r.data) {
                            modal.ok({
	                            tool: r.data,
                            });
			            }
		            }).finally(() => state.loader = false);
	            }
            }
        }

	    function ok() {
		    modal.ok({
			    asd: 123,
		    });
	    }

        function close() {
            modal.close();
        }

	    function cancel() {
		    modal.cancel({
			    zxc: 123,
		    });
	    }

        return {
            ok,
            cancel,
            close,
            state,
	        groups,
	        isNameError,
	        isGroupIdError,
	        isCategoryError,
	        isCharacteristicError,
	        onGroupSelected,
	        send,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
