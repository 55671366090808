<template>
    <modal-ui :id="id" size="modal-md" title="Редактирование данных пользователя">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row">
                <div class="col-12">
                    <form-group-ui invalid-feedback="Пароль состоит из не менее 5 букв или цифр" label="Пароль"
                        required>
                        <input-ui class="mt-2" v-model="state.password" type="password"
                            :is-invalid="state.visibleErrors && isPasswordError" placeholder="Пароль" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui invalid-feedback="Значения не совпадают" label="Повторно введите пароль" required>
                        <input-ui class="mt-2" v-model="state.repeatPassword" type="password"
                            :is-invalid="state.visibleErrors && isRepeatPasswordError"
                            placeholder="Повторно введите пароль" />
                    </form-group-ui>
                </div>
            </div>
            <div class="col-12 text-center mt-3">
                <button-ui button-class="btn-primary">
                    Сохранить
                </button-ui>
            </div>
        </form>
    </modal-ui>
</template>

<script setup lang="ts">
import { onMounted, reactive, computed } from "vue";
import { useModal } from "@/composables/useModal";
import api from "@/api";

type stateType = {
    id: number,
    visibleErrors: boolean,
    loader: boolean,
    password: string;
    repeatPassword: string;
};

const props = defineProps({
    id: {
        type: String,
        default: '',
    },
});

const modal = useModal(props.id);
const state: stateType = reactive({
    id: 0,
    visibleErrors: false,
    loader: false,
    password: '',
    repeatPassword: '',
});
onMounted(() => {
    if (Object.keys(modal.data.user).length) {
        state.id = modal.data.user.id;
    }
});

const isPasswordError = computed(() => state.password.length < 5 || !/^[a-zA-Z0-9]+$/.test(state.password));
const isRepeatPasswordError = computed(() => state.password !== state.repeatPassword);

const isErrorsExist = computed(() => {
    return isPasswordError.value || isRepeatPasswordError.value;
});

function send() {
    if (isErrorsExist.value) {
        state.visibleErrors = true;
        return;
    }
    state.visibleErrors = false;
    state.loader = true;

    api.user.resetPassword(state.id, {
        newPassword: state.password
    }).then(r => {
        modal.ok(r.data);
    })
}

</script>

<style scoped lang="scss"></style>
