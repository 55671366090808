<template>
    <modal-ui
        :id="id"
        size="modal-md"
        :title="state.title">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th class="px-3 py-2">
                        Контрагент
                    </th>
                    <th class="px-3 py-2" colspan="2">
                        Дата
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(l, lInd) in locos">
                    <td class="px-3 py-2">
                        <select-ui
                            item-key="name"
                            :options="contractors"
                            placeholder="Контрагент"
                            :searchable="true"
                            :values="[l.contractorCategoryId]"
                            @on-selected="(v) => onContractorSelected(l, v)" />
                    </td>
                    <td class="px-3 py-2">
                        {{ moment(l.dt).locale('ru').format('D MMMM YYYY HH:mm') }}
                        <!--                        <date-picker-->
                        <!--                            v-model="l.dt"-->
                        <!--                            class="inline-block h-full"-->
                        <!--                            :is24hr="true"-->
                        <!--                            mode="dateTime">-->
                        <!--                            <template #default="{ inputValue, togglePopover }">-->
                        <!--                                <div class="flex items-center">-->
                        <!--                                    <input-->
                        <!--                                        class="form-control"-->
                        <!--                                        :class="{'bg-white': true }"-->
                        <!--                                        placeholder="Дата"-->
                        <!--                                        readonly-->
                        <!--                                        :timezone="'Asia/Dhaka'"-->
                        <!--                                        :value="inputValue"-->
                        <!--                                        @blur="togglePopover()"-->
                        <!--                                        @focus="togglePopover()">-->
                        <!--                                </div>-->
                        <!--                            </template>-->
                        <!--                        </date-picker>-->
                    </td>
                    <td class="px-3 py-2">
                        <button-ui button-class="btn-danger" @click="deleteHandler(l, lInd)">
                            х
                        </button-ui>
                    </td>
                </tr>
            </tbody>
        </table>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref, watch } from "vue";
import { initModal, useModal } from "@/composables/useModal";
import api from "@/api";
import { DatePicker } from 'v-calendar';
import moment from 'moment-timezone';

export default defineComponent({
    name: "PowerCalculationEditModal",
    components: {
        DatePicker,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
	    const modal = useModal(props.id);
        const locos = ref([]);
        const contractors = ref([]);

        const state = reactive({
            title: '',
            btn: '',
	        date: moment().format('YYYY-MM-DD HH:mm:ss'),
            visibleErrors: false,
            loader: false,
        });

	    const isDateError = computed(() => !state.date);

        onMounted(() => {
	        state.title = 'Редактирование количества ' + modal.data.category + ' ' + modal.data.job;
	        state.btn = 'Выбрать';

            locos.value = modal.data.locos;
	        contractors.value = JSON.parse(JSON.stringify(modal.data.contractors));
            // @ts-ignore
            contractors.value.unshift({ id: null, name: 'Без категории' });
        });

        function onContractorSelected(l: any, contractor: any) {
            api.plan.patch({
	            contractorId: contractor.id,
	            id: l.id,
            });
        }

        function send() {
            if (isDateError.value) {
                state.visibleErrors = true;
            } else {
                state.visibleErrors = false;
                state.loader = true;
	            ok();
            }
        }

        function deleteHandler(l: any, lInd: any) {
            api.plan.delete(l.id).then((r: any) => {
                locos.value.splice(lInd, 1);
            });
        }

	    function ok() {
		    modal.ok({
			    date: moment(state.date).format('YYYY-MM-DD HH:mm:ss'),
		    });
	    }

        function close() {
	        modal.cancel({
		        zxc: 123,
	        });
        }

	    function cancel() {
		    modal.cancel({
			    zxc: 123,
		    });
	    }

        return {
            ok,
            cancel,
            close,
            state,
	        isDateError,
	        send,
	        locos,
	        contractors,
	        onContractorSelected,
	        moment,
	        deleteHandler,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
