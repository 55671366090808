<template>
  <modal-ui :id="id" size="modal-xl" title="Себестоимость проекта">
    <form class="loader" @submit.prevent="send">
      <loader-ui :is-show="state.loader" />
      <div class="row my-2">
        <div class="col-2 border fw-bold">Конструктив</div>
        <div class="col-4 fw-bold row">
          <div class="col-12 border text-center">
            {{ PRICE_NAME.GOODS }}
          </div>
          <div class="col-6 border">Ремонт</div>
          <div class="col-6 border">Замена</div>
        </div>
        <div class="col-4 fw-bold row">
          <div class="col-12 border text-center">
            {{ PRICE_NAME.SERVICES }}
          </div>
          <div class="col-6 border">Ремонт</div>
          <div class="col-6 border">Замена</div>
        </div>
        <div class="col-2 border fw-bold">Сумма без НДС</div>
      </div>
      <div
        v-for="constructivePrice in state.constructivePrices"
        class="row my-2"
        :key="constructivePrice.constructiveId"
      >
        <div class="col-2 border d-flex align-items-center">
          {{ constructivePrice.constructiveName }}
        </div>
        <div class="col-2 border">
          <form-group-ui invalid-feedback="Укажите сумму ремонта" required>
            <input-ui
              class="mt-2 text-end"
              v-model="constructivePrice['Товары'].repairPrice"
              :mask="{ mask: 'Z*', tokens: { Z: { pattern: /[0-9]/ } } }"
              :is-invalid="state.visibleErrors && isTotalWithoutVATError(constructivePrice)"
              maxlength="12"
            />
          </form-group-ui>
        </div>
        <div class="col-2 border">
          <form-group-ui invalid-feedback="Укажите сумму замены" required>
            <input-ui
              class="mt-2 text-end"
              v-model="constructivePrice['Товары'].replacementPrice"
              :mask="{ mask: 'Z*', tokens: { Z: { pattern: /[0-9]/ } } }"
              :is-invalid="state.visibleErrors && isTotalWithoutVATError(constructivePrice)"
              maxlength="12"
            />
          </form-group-ui>
        </div>
        <div class="col-2 border">
          <form-group-ui invalid-feedback="Укажите сумму ремонта" required>
            <input-ui
              class="mt-2 text-end"
              v-model="constructivePrice['Услуги'].repairPrice"
              :mask="{ mask: 'Z*', tokens: { Z: { pattern: /[0-9]/ } } }"
              :is-invalid="state.visibleErrors && isTotalWithoutVATError(constructivePrice)"
              maxlength="12"
            />
          </form-group-ui>
        </div>
        <div class="col-2 border">
          <form-group-ui invalid-feedback="Укажите сумму замены" required>
            <input-ui
              class="mt-2 text-end"
              v-model="constructivePrice['Услуги'].replacementPrice"
              :mask="{ mask: 'Z*', tokens: { Z: { pattern: /[0-9]/ } } }"
              :is-invalid="state.visibleErrors && isTotalWithoutVATError(constructivePrice)"
              maxlength="12"
            />
          </form-group-ui>
        </div>
        <div class="col-2 border d-flex align-items-center">
          {{ getItemTotal(constructivePrice) ? formatMoney(getItemTotal(constructivePrice)) : '' }}
        </div>
      </div>
      <div class="row my-2">
        <div class="col-2 border fw-bold">
          Итого
        </div>
        <div class="col-2 border fw-bold">
          {{ goodsRepairPrice ? formatMoney(goodsRepairPrice) : '' }}
        </div>
        <div class="col-2 border fw-bold">
          {{ goodsReplacementPrice ? formatMoney(goodsReplacementPrice) : '' }}
        </div>
        <div class="col-2 border fw-bold">
          {{ servicesRepairPrice ? formatMoney(servicesRepairPrice) : '' }}
        </div>
        <div class="col-2 border fw-bold">
          {{ servicesReplacementPrice ? formatMoney(servicesReplacementPrice) : '' }}
        </div>
        <div class="col-2 border fw-bold">
          {{ total ? formatMoney(total) : '' }}
        </div>
      </div>
      <div class="row">
        <div class="col-4 border d-flex align-items-center">
          в т.ч. неофиц. расходы (КЭШ)
        </div>
        <div class="col-2 border">
          <form-group-ui invalid-feedback="Укажите проценты" label="(%)" required>
            <input-ui
              class="mt-2 text-end"
              v-model="state.constructivePriceCachePercent"
              :is-invalid="state.visibleErrors && isConstructiveCachePercentError"
              :mask="{ mask: 'Z*', tokens: { Z: { pattern: /^[0-9.,]+$/ } } }"
              maxlength="3"
            />
          </form-group-ui>
        </div>
        <div class="col-6 border">
          {{ formatMoney(cacheTotal) }}
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <button-ui button-class="btn-primary"> Сохранить </button-ui>
        </div>
      </div>
    </form>
  </modal-ui>
</template>

<script async setup lang="ts">
import { onMounted, reactive, computed, ref } from 'vue';
import { PRICE_NAME } from '@depo/constants/project-register';
import { useModal } from '@/composables/useModal';
import api from '@/api';
import { IConstructive } from '@/@types/ISummary';
import formatMoney from '@/utils/formatMoney';

type constructivePriceType = {
  constructiveId: number;
  constructiveName: string;
  constructiveNumber: string;
  projectRegisterId: number;
  ['Товары']: {
    replacementPrice: number;
    repairPrice: number;
  };
  ['Услуги']: {
    replacementPrice: number;
    repairPrice: number;
  };
};

type stateType = {
  constructivePrices: Array<constructivePriceType>;
  constructivePriceCachePercent: string;
  visibleErrors: boolean;
  loader: boolean;
};

const props = defineProps({
  id: {
    type: String,
    default: '',
  },
});

const modal = useModal(props.id);

const state: stateType = reactive({
  constructivePrices: [],
  constructivePriceCachePercent: '',
  visibleErrors: false,
  loader: false,
});

const constructives = ref<IConstructive[]>([]);

const getItemTotal = computed(() => (constructivePrice: constructivePriceType) => {
  const goods = +constructivePrice['Товары'].replacementPrice + +constructivePrice['Товары'].repairPrice;
  const services = +constructivePrice['Услуги'].replacementPrice + +constructivePrice['Услуги'].repairPrice;
  return goods + services;
});

const goodsRepairPrice = computed(() => {
  return state.constructivePrices.reduce((total, curVal) => {
    return total + +curVal['Товары'].repairPrice;
  }, 0);
})

const goodsReplacementPrice = computed(() => {
  return state.constructivePrices.reduce((total, curVal) => {
    return total + +curVal['Товары'].replacementPrice;
  }, 0);
})

const servicesRepairPrice = computed(() => {
  return state.constructivePrices.reduce((total, curVal) => {
    return total + +curVal['Услуги'].repairPrice;
  }, 0);
})

const servicesReplacementPrice = computed(() => {
  return state.constructivePrices.reduce((total, curVal) => {
    return total + +curVal['Услуги'].replacementPrice;
  }, 0);
})

const total = computed(() => {
  return goodsRepairPrice.value + goodsReplacementPrice.value + servicesRepairPrice.value + servicesReplacementPrice.value;
});

const cacheTotal = computed(() => {
  if (!state.constructivePriceCachePercent) {
    return 0;
  }
  return (total.value / 100) * Number(state.constructivePriceCachePercent);
});
const isConstructiveCachePercentError = computed(() => {
  return !state.constructivePriceCachePercent.length
    ? false 
    : (+state.constructivePriceCachePercent > 100 || +state.constructivePriceCachePercent < 0)
});

const isTotalWithoutVATError = computed(() => (constructivePrice: constructivePriceType) => {
  const total = getItemTotal.value(constructivePrice);
  return +total < 0 || !isFinite(+total);
});

const errors = computed(() => state.constructivePrices.some((cp) => isTotalWithoutVATError.value(cp)));

onMounted(() => {
  api.constructs.get().then((r: any) => {
    constructives.value = r.data;

    if (modal.data.projectRegister.constructivePrices?.length) {
      state.constructivePriceCachePercent = modal.data.projectRegister.constructivePriceCachePercent;
      state.constructivePrices = modal.data.projectRegister.constructivePrices.map((cp: any) => ({
        constructiveId: cp.constructiveId,
        constructiveName: `${cp.constructive.nm} ${cp.constructive.group_name}`,
        constructiveNumber: cp.constructive.nm,
        projectRegisterId: cp.id,
        ['Товары']: {
          replacementPrice: +cp['Товары'].replacementPrice || '',
          repairPrice: +cp['Товары'].repairPrice || '',
        },
        ['Услуги']: {
          replacementPrice: +cp['Услуги'].replacementPrice || '',
          repairPrice: +cp['Услуги'].repairPrice || '',
        },
      }));
    } else {
      state.constructivePrices = r.data.map((constructive: IConstructive) => ({
        constructiveId: constructive.id,
        constructiveName: constructive.name,
        constructiveNumber: constructive.nm,
        projectRegisterId: modal.data.projectRegister.id as number,
        ['Товары']: {
          replacementPrice: '',
          repairPrice: '',
        },
        ['Услуги']: {
          replacementPrice: '',
          repairPrice: '',
        },
      }));
    }
  });

});

function send() {
  if (errors.value) {
    state.visibleErrors = true;
    return;
  }
  state.visibleErrors = false;
  state.loader = true;

  api.projectRegister
    .calculate(modal.data.projectRegister.id, {
      constructivePriceCachePercent: state.constructivePriceCachePercent,
      constructivePrices: state.constructivePrices.map(cp => {

      return {
        ...cp,
        details: [
          {
            name: PRICE_NAME.GOODS,
            repairPrice: cp['Товары'].repairPrice,
            replacementPrice: cp['Товары'].replacementPrice,
          },
          {
            name: PRICE_NAME.SERVICES,
            repairPrice: cp['Услуги'].repairPrice,
            replacementPrice: cp['Услуги'].replacementPrice,
          }
        ],
      };
    }) })
    .then(() => modal.ok({ isSuccess: true }))
    .finally(() => (state.loader = false));
}
</script>

<style scoped lang="scss"></style>
