<template>
    <modal-ui :id="id" size="modal-sm" :title="state.title">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-12">
                    <form-group-ui :label="state.label" required>
                        <input-ui v-model="state.value" :placeholder="state.label" />
                    </form-group-ui>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button-ui button-class="btn-primary">
                        {{ state.btn }}
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed } from "vue";
import { useModal } from "@/composables/useModal";

export default defineComponent({
    name: "ChooseDateModal",
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
        const modal = useModal(props.id);

        const state = reactive({
            type: 'text',
            title: '',
            btn: '',
            label: '',
            value: '',
            visibleErrors: false,
            loader: false,
        });

        if (modal.data.hasOwnProperty('type')) {
            state.type = modal.data.type
        }

        const isValueError = computed(() => !state.value.length);

        onMounted((() => {
            state.title = modal.data.title;
            state.btn = modal.data.btn;
            state.type = modal.data.type;
            state.label = modal.data.label;
            state.value = modal.data.value;
        }));

        function send() {
            if (!state.value.length || (state.type === 'number' && (!isFinite(Number(state.value)) || !Number(state.value)))) {
                return;
            }
            state.loader = true;
            ok();
        }

        function ok() {
            modal.ok({
                value: state.value,
            });
        }

        function close() {
            modal.cancel({
                zxc: 123,
            });
        }

        function cancel() {
            modal.cancel({
                zxc: 123,
            });
        }

        return {
            ok,
            cancel,
            close,
            state,
            isValueError,
            send,
        };
    },
});
</script>

<style scoped lang="scss"></style>
