<template>
    <modal-ui
        :id="id"
        size="modal-sm"
        :title="state.title">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-12">
                    <form-group-ui
                        invalid-feedback="Выберите дату"
                        label="Дата захода на канаву"
                        required>
                        <date-picker
                            v-model="state.date"
                            class="inline-block h-full"
                            :is24hr="true"
                            mode="dateTime">
                            <template #default="{ inputValue, togglePopover }">
                                <div class="flex items-center">
                                    <input
                                        class="form-control"
                                        :class="{'is-invalid': isDateError && state.visibleErrors, 'bg-white': true }"
                                        placeholder="Дата захода на канаву"
                                        readonly
                                        :timezone="'Asia/Almaty'"
                                        :value="inputValue"
                                        @blur="togglePopover()"
                                        @focus="togglePopover()">
                                    <div class="invalid-feedback">
                                        Укажите дату
                                    </div>
                                </div>
                            </template>
                        </date-picker>
                    </form-group-ui>
                    <form-group-ui
                        invalid-feedback="Выберите плановую дату выхода"
                        label="Плановая дата выхода"
                        required>
                        <date-picker
                            v-model="state.plansToLeaveAt"
                            class="inline-block h-full"
                            :is24hr="true"
                            mode="dateTime">
                            <template #default="{ inputValue, togglePopover }">
                                <div class="flex items-center">
                                    <input
                                        class="form-control"
                                        :class="{'is-invalid': isPlansToLeaveAtError && state.visibleErrors, 'bg-white': true }"
                                        placeholder="Плановая дата выхода"
                                        readonly
                                        :timezone="'Asia/Almaty'"
                                        :value="inputValue"
                                        @blur="togglePopover()"
                                        @focus="togglePopover()">
                                    <div class="invalid-feedback">
                                        Выберите плановую дату выхода
                                    </div>
                                </div>
                            </template>
                        </date-picker>
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui
                        label="Примечание">
                        <text-area-ui
                            v-model="state.comment"
                            placeholder="Примечание" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <router-link to="/list_equipment" target='_blank'>
                        <span class="btn btn-link">
                            Посмотреть плановые количества дней ремонта
                        </span>
                    </router-link>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button-ui
                        button-class="btn-primary">
                        {{ state.btn }}
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref, watch } from "vue";
import { initModal, useModal } from "@/composables/useModal";
import api from "@/api";
import { DatePicker } from 'v-calendar';
import moment from 'moment-timezone';

export default defineComponent({
    name: "MoveLocomotiveModal",
    components: {
        DatePicker,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
	    const modal = useModal(props.id);

        const state = reactive({
            title: '',
            btn: '',
	        date: moment().format('YYYY-MM-DD HH:mm:ss'),
            plansToLeaveAt: moment().format('YYYY-MM-DD HH:mm:ss'),
	        comment: '',
            visibleErrors: false,
            loader: false,
        });

	    const isDateError = computed(() => !state.date);
        const isPlansToLeaveAtError = computed(() => !state.plansToLeaveAt);

        onMounted((() => {
	        state.title = 'Передвижение секции ' + modal.data.locomotive.sectionNumber;
	        state.btn = 'Перетащить';
        }));

        function send() {
            if (isDateError.value) {
                state.visibleErrors = true;
            } else {
                state.visibleErrors = false;
                state.loader = true;
	            ok();
            }
        }

	    function ok() {
		    modal.ok({
			    date: moment(state.date).format('YYYY-MM-DD HH:mm:ss'),
                plansToLeaveAt: moment(state.plansToLeaveAt).format('YYYY-MM-DD HH:mm:ss'),
			    comment: state.comment.length ? state.comment : null,
			    // locomotives: r.data,
		    });
	    }

        function close() {
	        modal.cancel({
		        zxc: 123,
	        });
        }

	    function cancel() {
		    modal.cancel({
			    zxc: 123,
		    });
	    }

        return {
            ok,
            cancel,
            close,
            state,
	        isDateError,
            isPlansToLeaveAtError,
	        send,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
