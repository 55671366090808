import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2056721a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "modal fade show" }
const _hoisted_2 = { class: "modal-content" }
const _hoisted_3 = { class: "modal-header px-4 mx-0" }
const _hoisted_4 = { class: "modal-title" }
const _hoisted_5 = { class: "modal-body px-4 py-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["modal-dialog modal-dialog-centered", _ctx.setSize])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("h5", _hoisted_4, _toDisplayString(_ctx.getTitle), 1),
          _createElementVNode("button", {
            class: "btn-close me-0",
            type: "button",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.cancel && _ctx.cancel(...args)))
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ])
      ])
    ], 2)
  ]))
}