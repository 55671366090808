<template>
  <modal-ui :id="id" size="modal-xl" title="Добавление материала">
    <form class="loader" @submit.prevent="send">
      <loader-ui :is-show="state.loader" />
      <div class="row mb-4" v-for="(item, itemInd) in state.materials" :key="itemInd">
        <div class="col-12 col-md-3">
          <form-group-ui invalid-feedback="Укажите наименование ТМЦ" label="Наименование ТМЦ" required>
            <input-ui
              placeholder="Наименование ТМЦ"
              v-model="state.materials[itemInd].originalName"
              :is-invalid="isNameError(item) && state.visibleErrors"
            />
          </form-group-ui>
        </div>
        <div class="col-12 col-md-3">
          <form-group-ui invalid-feedback="Укажите единицу измерения" label="Единица измерения" required>
            <input-ui
              v-model="state.materials[itemInd].originalUnitDimension"
              placeholder="Единица измерения"
              :is-invalid="isUnitError(item) && state.visibleErrors"
            />
          </form-group-ui>
        </div>
        <div class="col-12 col-md-3">
          <form-group-ui invalid-feedback="Укажите количество ТМЦ" label="Количество ТМЦ" required>
            <input-ui
              type="number"
              step="any"
              v-model="state.materials[itemInd].originalCount"
              placeholder="Количество ТМЦ"
              :is-invalid="isCountInvError(item) && state.visibleErrors"
            />
          </form-group-ui>
        </div>
        <div class="col-12 col-md-3">
          <form-group-ui invalid-feedback="Укажите цену" label="Цена за единицу" required>
            <input-ui
              v-model="state.materials[itemInd].originalUnitPrice"
              step="any"
              type="number"
              placeholder="Цена за единицу"
              :is-invalid="isSumInvError(item) && state.visibleErrors"
            />
          </form-group-ui>
        </div>
        <div class="col-12 col-md-3">
          <form-group-ui invalid-feedback="Выберите материал" label="Материал" required>
            <select-ui
              :is-invalid="state.visibleErrors && isApmIdError(item)"
              item-key="name"
              :options="materials"
              :searchable="true"
              :values="[state.materials[itemInd].materialId]"
              @on-updated="(m: any) => onMaterialSelected(m, itemInd)"
            />
          </form-group-ui>
        </div>
        <div class="col-12 col-md-3">
          <form-group-ui invalid-feedback="Укажите количество" label="Количество" required>
            <input-ui
              v-model="state.materials[itemInd].actualCount"
              type="number"
              step="any"
              placeholder="Количество"
              :is-invalid="isCountError(item) && state.visibleErrors"
            />
          </form-group-ui>
        </div>
        <div class="col-12 col-md-3 d-flex align-items-center">
          <span>Ед измерения материала: {{ state.materials[itemInd]?.unit || '' }}</span>
        </div>
        <div class="col-12 col-md-3 d-flex align-items-center">
          <button-ui v-if="!!itemInd" button-class="btn-secondary" @click="removeMaterialById(itemInd)"
            >Удалить материал</button-ui
          >
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <button-ui button-class="btn-primary me-2"> Сохранить </button-ui>
          <button-ui button-class="btn-outline-primary" type="button" @click="addOneMoreMaterial">Добавить материал</button-ui>
        </div>
      </div>
    </form>
  </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref, watch } from 'vue';
import { useModal } from '@/composables/useModal';
import api from '@/api';
import { DatePicker } from 'v-calendar';

export default defineComponent({
  name: 'AddInvoiceMaterialsModal',
  components: {
    DatePicker,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const modal = useModal(props.id);

    const materials = ref([]);

    const state = reactive({
      visibleErrors: false,
      loader: false,
      materials: [
        {
          id: 0,
          originalName: '',
          originalUnitDimension: '',
          originalCount: '',
          originalUnitPrice: '',
          materialId: '',
          actualCount: '',
          unit: '',
        },
      ],
    });

    const isNameError = computed(() => (material: any) => !material.originalName.length);
    const isUnitError = computed(() => (material: any) => !material.originalUnitDimension.length);
    const isCountInvError = computed(
      () => (material: any) => +material.originalCount <= 0 || isNaN(+material.originalCount)
    );
    const isSumInvError = computed(
      () => (material: any) => +material.originalUnitPrice <= 0 || isNaN(+material.originalUnitPrice)
    );
    const isApmIdError = computed(() => (material: any) => !material.materialId);
    const isCountError = computed(() => (material: any) => +material.actualCount <= 0 || isNaN(+material.actualCount));

    onMounted(() => {
      api.materials.getOld().then(({ data }) => {
        materials.value = data.filter((m: any) => !modal.data.materialIds?.includes(+m?.id));
      });
    });

    function onMaterialSelected(m: any, ind: number) {
      state.materials[ind].materialId = m.id;
      state.materials[ind].unit = m?.measurement;
    }

    function addOneMoreMaterial() {
      state.materials.push({
        id: 0,
        originalName: '',
        originalUnitDimension: '',
        originalCount: '',
        originalUnitPrice: '',
        materialId: '',
        actualCount: '',
        unit: '',
      });
    }

    function removeMaterialById(ind: number) {
      state.materials.splice(ind, 1);
    }

    function send() {
      const isErrorsExist = state.materials.some((m) => {
        return (
          isNameError.value(m) ||
          isUnitError.value(m) ||
          isCountInvError.value(m) ||
          isSumInvError.value(m) ||
          isApmIdError.value(m) ||
          isCountError.value(m)
        );
      });
      if (isErrorsExist) {
        state.visibleErrors = true;
      } else {
        state.visibleErrors = false;
        state.loader = true;

        api.invoiceMaterials
          .create({
            materials: state.materials,
            invoiceId: modal.data.invoiceId,
          })
          .then((r) => {
            modal.ok(r.data);
          });
      }
    }

    function ok(loco: any) {
      modal.ok({
        loco: loco,
      });
    }

    function close() {
      modal.cancel({
        zxc: 123,
      });
    }

    function cancel() {
      modal.cancel({
        zxc: 123,
      });
    }

    return {
      ok,
      modal,
      cancel,
      close,
      state,
      send,
      isNameError,
      isUnitError,
      isCountInvError,
      isSumInvError,
      isApmIdError,
      isCountError,
      materials,
      onMaterialSelected,
      addOneMoreMaterial,
      removeMaterialById,
    };
  },
});
</script>

<style scoped lang="scss"></style>
