import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-8be927d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "workshop__locomotive2" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "outside__locomotive my-3" }
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_locomotive_vertical_component = _resolveComponent("locomotive-vertical-component")!
  const _component_Tooltip = _resolveComponent("Tooltip")!
  const _component_locomotive_horizontal_component = _resolveComponent("locomotive-horizontal-component")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.place)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Tooltip, { placement: "top" }, {
        title: _withCtx(() => [
          _createElementVNode("div", { innerHTML: _ctx.getTooltip }, null, 8, _hoisted_2)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_locomotive_vertical_component, {
            locomotive: _ctx.locomotive,
            type: _ctx.type
          }, null, 8, ["locomotive", "type"])
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Tooltip, { placement: "top" }, {
        title: _withCtx(() => [
          _createElementVNode("div", { innerHTML: _ctx.getTooltip }, null, 8, _hoisted_4)
        ]),
        default: _withCtx(() => [
          _createVNode(_component_locomotive_horizontal_component, { locomotive: _ctx.locomotive }, null, 8, ["locomotive"])
        ]),
        _: 1
      })
    ])
  ], 2))
}