import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "mb-3" }
const _hoisted_2 = {
  key: 0,
  class: "form-label"
}
const _hoisted_3 = {
  key: 0,
  class: "text-danger"
}
const _hoisted_4 = {
  key: 1,
  class: "invalid-feedback"
}
const _hoisted_5 = {
  key: 2,
  class: "valid-feedback"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", _hoisted_2, [
          _createTextVNode(_toDisplayString(_ctx.getLabel) + " ", 1),
          (_ctx.required)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, "*"))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default"),
    (_ctx.invalidFeedback)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, _toDisplayString(_ctx.getInvalidFeedback), 1))
      : _createCommentVNode("", true),
    (_ctx.validFeedback)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.getValidFeedback), 1))
      : _createCommentVNode("", true)
  ]))
}