<template>
    <modal-ui :id="id" size="modal-xl" :title="state.title">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-12">
                    <form-group-ui invalid-feedback="Выберите сотрудника" label="Сотрудник" required>
                        <select-ui :is-invalid="state.visibleErrors && isEmployeeIdError"
                            :options="employees" placeholder="Сотрудник"
                            :searchable="true" :values="[state.employeeId]"
                            @on-selected="onEmployeeSelect" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui invalid-feedback="Выберите начало" label="Начало" required>
                        <date-picker v-model="state.startsAt" class="inline-block h-full" :is24hr="true" mode="date">
                            <template #default="{ inputValue, togglePopover }">
                                <div class="flex items-center">
                                    <input class="form-control"
                                        :class="{ 'is-invalid': isStartsAtError && state.visibleErrors, 'bg-white': true }"
                                        as readonly :timezone="'Asia/Almaty'" :value="inputValue"
                                        @blur="togglePopover()" @focus="togglePopover()">
                                    <div class="invalid-feedback">
                                        Укажите начало
                                    </div>
                                </div>
                            </template>
                        </date-picker>
                    </form-group-ui>
                    <form-group-ui invalid-feedback="Выберите конец" label="Конец" required>
                        <date-picker v-model="state.endsAt" class="inline-block h-full" :is24hr="true" mode="date">
                            <template #default="{ inputValue, togglePopover }">
                                <div class="flex items-center">
                                    <input class="form-control"
                                        :class="{ 'is-invalid': isEndsAtError && state.visibleErrors, 'bg-white': true }"
                                        as readonly :timezone="'Asia/Almaty'" :value="inputValue"
                                        @blur="togglePopover()" @focus="togglePopover()">
                                    <div class="invalid-feedback">
                                        Укажите конец
                                    </div>
                                </div>
                            </template>
                        </date-picker>
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui invalid-feedback="Укажите расценку" label="Расценка" required>
                        <input-ui v-model="state.unitPrice"
                            :is-invalid="state.visibleErrors && isUnitPriceError"
                            :mask="{ mask: 'Z*', tokens: { 'Z': { pattern: /[0-9]/ } } }"
                            placeholder="Расценка" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui invalid-feedback="Примечание" label="Примечание">
                        <input-ui v-model="state.comment" placeholder="Примечание" />
                    </form-group-ui>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button-ui button-class="btn-primary">
                        {{ state.btn }}
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref } from "vue";
import { useModal } from "@/composables/useModal";
import api from "@/api";
import moment from 'moment-timezone';
import { DatePicker } from 'v-calendar';

export default defineComponent({
    name: "EmployeeTariffModal",
    components: {
        DatePicker,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
        const modal = useModal(props.id);
        const employees = ref([]);

        const state = reactive({
            startsAt: moment().startOf('year').toDate(),
            endsAt: moment().endOf('year').toDate(),
            unitPrice: '',
            comment: '',
            employeeId: '',
            title: '',
            btn: '',
            visibleErrors: false,
            loader: false,
        });
        const isEmployeeIdError = computed(() => !state.employeeId);
        const isStartsAtError = computed(() => !state.startsAt);
        const isEndsAtError = computed(() => !state.endsAt);
        const isUnitPriceError = computed(() => !state.unitPrice);

        const errors = computed(() => {
            return isStartsAtError.value || isEndsAtError.value || isEmployeeIdError.value || isUnitPriceError.value;
        });

        onMounted(() => {
            if (modal.data.type === 'add') {
                state.title = 'Создать расценку';
                state.btn = 'Сохранить';
            } else {
                state.title = 'Редактировать расценку';
                state.btn = 'Сохранить';

                state.startsAt = modal.data.employeeTariff.startsAt;
                state.endsAt = modal.data.employeeTariff.endsAt;
                state.unitPrice = modal.data.employeeTariff.unitPrice;
                state.employeeId = modal.data.employeeTariff.employeeId;
                state.comment = modal.data.employeeTariff.comment;
            }
            api.employees.get().then((r: any) => {
                employees.value = r.data?.map((em: any) => ({
                    ...em,
                    name: `${em.employeeName} (${em.employeePost})`,
                }));
            });
        });

        function onEmployeeSelect(_c: any) {
            state.employeeId = _c.id;
        }

        function send() {
            if (errors.value) {
                state.visibleErrors = true;
            } else {
                state.visibleErrors = false;
                state.loader = true;
                if (modal.data.type === 'add') {
                    api.employeeTariff.create({
                        startsAt: state.startsAt,
                        endsAt: state.endsAt,
                        employeeId: state.employeeId,
                        comment: state.comment,
                        unitPrice: state.unitPrice,
                    }).then((r: any) => {
                        if (r.data) {
                            modal.ok(r.data);
                        }
                    }).finally(() => state.loader = false);
                } else {
                    api.employeeTariff.update(modal.data.employeeTariff.id, {
                        startsAt: state.startsAt,
                        endsAt: state.endsAt,
                        employeeId: state.employeeId,
                        comment: state.comment,
                        unitPrice: state.unitPrice,
                    }).then((r: any) => {
                        if (r.data) {
                            modal.ok(r.data);
                        }
                    }).finally(() => state.loader = false);
                    // api.employees.patch({
                    //     id: state.id,
                    //     name: state.name,
                    //     organizationId: state.organizationId,
                    //     subdivision: state.subdivision,
                    //     plot: state.plot,
                    //     post: state.post,
                    //     workingMode: state.workingModeId,
                    // }).then((r: any) => {
                    //     if (r.data) {
                    //         modal.ok({
                    //             employee: r.data,
                    //         });
                    //     }
                    // }).finally(() => state.loader = false);
                }
            }
        }

        function ok() {
            modal.ok({
                asd: 123,
            });
        }

        function close() {
            modal.close();
        }

        function cancel() {
            modal.cancel({
                zxc: 123,
            });
        }

        return {
            ok,
            cancel,
            close,
            state,
            employees,
            isEmployeeIdError,
            isStartsAtError,
            isEndsAtError,
            isUnitPriceError,
            send,
            onEmployeeSelect,
        };
    },
});
</script>

<style scoped lang="scss"></style>
