<template>
    <modal-ui
        :id="id"
        size="modal-xl"
        :title="state.title">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-6 col-lg-3">
                    <form-group-ui
                        invalid-feedback="Укажите ФИО"
                        label="ФИО"
                        required>
                        <input-ui
                            v-model="state.name"
                            :is-invalid="state.visibleErrors && isNameError"
                            placeholder="ФИО" />
                    </form-group-ui>
                </div>
                <div class="col-6 col-lg-3">
                    <form-group-ui
                        invalid-feedback="Укажите должность"
                        label="Должность"
                        required>
                        <input-ui
                            v-model="state.post"
                            :is-invalid="state.visibleErrors && isPostError"
                            placeholder="Должность" />
                    </form-group-ui>
                </div>
                <div class="col-6 col-lg-3">
                    <form-group-ui invalid-feedback="Укажите ИИН" label="ИИН">
                        <input-ui
                            v-model="state.iin"
                            :mask="{ mask: 'Z*', tokens: { 'Z': { pattern: /[0-9]/ }}}"
                            maxlength="12"
                            :is-invalid="state.visibleErrors && isIINError"
                            placeholder="ИИН" />
                    </form-group-ui>
                </div>
                <div class="col-6 col-lg-3">
                    <form-group-ui invalid-feedback="Укажите контакты" label="Контакты">
                        <input-ui
                            v-model="state.contact"
                            placeholder="Контакты" />
                    </form-group-ui>
                </div>
                <div class="col-6 col-lg-2">
                    <form-group-ui
                        invalid-feedback="Укажите СКУД"
                        label="СКУД">
                        <input-ui
                            v-model="state.pacsCards"
                            placeholder="СКУД" />
                    </form-group-ui>
                </div>
                <div class="col-2 d-flex align-items-center">
		            <label class="form-check form-check-inline">
			            <input
				            v-model="state.isNotOurEmployee"
				            class="form-check-input"
				            type="checkbox">
			            <span class="form-check-label">Привлеченный сотрудник</span>
		            </label>
	            </div>
                <div class="col-6 col-lg-2" v-if="state.isNotOurEmployee">
                    <form-group-ui
                        label="Юр.лицо" invalid-feedback="Выберите юр.лицо" required>
                        <select-ui
                            item-key="name"
                            :is-invalid="state.visibleErrors && isSupplierError"
                            :options="suppliers"
                            placeholder="Юр.лицо"
                            :searchable="true"
                            :values="[state.supplierId]"
                            @on-selected="handleSupplierSelect" />
                    </form-group-ui>
                </div>
                <div class="col-6 col-lg-2">
                    <form-group-ui
                        invalid-feedback="Выберите основание"
                        label="Основание"
                        required>
                        <select-ui
                            :is-invalid="state.visibleErrors && isOrganizationIdError"
                            item-key="name"
                            :options="organizations"
                            placeholder="Основание"
                            :searchable="true"
                            :values="[state.organizationId]"
                            @on-selected="onOrganizationSelected" />
                    </form-group-ui>
                </div>
                <div class="col-6 col-lg-2">
                    <form-group-ui
                        invalid-feedback="Укажите подразделение"
                        label="Подразделение"
                        required>
                        <select-ui
                            :is-invalid="state.visibleErrors && isPlotError"
                            item-key="name"
                            :options="plots"
                            placeholder="Подразделение"
                            :values="[state.plot]"
                            @on-selected="onPlotSelected" />
                        <!--                        <input-ui-->
                        <!--                            v-model="state.plot"-->
                        <!--                            :is-invalid="state.visibleErrors && isPlotError"-->
                        <!--                            placeholder="Участок" />-->
                    </form-group-ui>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button-ui
                        button-class="btn-primary">
                        {{ state.btn }}
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref } from "vue";
import { DatePicker } from 'v-calendar';
import { useModal } from "@/composables/useModal";
import api from "@/api";
import { ISupplier } from "@/@types/ISupplier";

export default defineComponent({
    name: "EmployeeModal",
    components: {
        DatePicker,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
	    const modal = useModal(props.id);
        const organizations = ref([]);
        const suppliers = ref<ISupplier[]>([]);
        const plots = ref([
	        {
		        id: 'Автоматный',
		        name: 'Автоматный',
	        },
	        {
		        id: 'Аккумуляторный',
		        name: 'Аккумуляторный',
	        },
	        {
		        id: 'Заготовительный',
		        name: 'Заготовительный',
	        },
	        {
		        id: 'Ремонтный',
		        name: 'Ремонтый',
	        },
	        {
		        id: 'Токарный',
		        name: 'Токарный',
	        },
	        {
		        id: 'Топливный',
		        name: 'Топливный',
	        },
	        {
		        id: 'Электроаппаратный',
		        name: 'Электроаппаратный',
	        },
	        {
		        id: 'Хозяйственный',
		        name: 'Хозяйственный',
	        },
	        {
                id: 'Дизельный',
                name: 'Дизельный',
	        },
	        {
                id: 'Ходовой',
                name: 'Ходовой',
	        },
	        {
		        id: 'Прочее',
		        name: 'Прочее',
	        },
        ]);
        const workingModes = [
            { id: 'Пятидневка' },
            { id: 'Смена' },
            { id: 'Вахта' },
        ];

        const state = reactive({
            title: '',
            btn: '',
	        id: 0,
	        name: '',
	        organizationId: 0,
	        plot: '',
	        post: '',
	        pacsCards: '',
	        iin: '',
	        contact: '',
	        workingModeId: 0,
            isNotOurEmployee: false,
            supplierId: 0,
            visibleErrors: false,
            loader: false,
        });

	    const isNameError = computed(() => !state.name.length);
	    const isOrganizationIdError = computed(() => !state.organizationId);
	    const isPlotError = computed(() => !state.plot.length);
	    const isPostError = computed(() => !state.post.length);
        const isIINError = computed(() => !state.iin.length ? false : !/^[0-9]{12}$/.test(state.iin));
        const isSupplierError = computed(() => state.isNotOurEmployee ? !state.supplierId : false);

        const errors = computed(() => {
            return isNameError.value ||
	            isOrganizationIdError.value ||
	            isPlotError.value ||
                isIINError.value ||
	            isPostError.value ||
                isSupplierError.value;
        });

        onMounted(() => {
            if (modal.data.type === 'add') {
                state.title = 'Добавление сотрудника';
                state.btn = 'Добавить';
            } else {
	            state.title = 'Редактирование сотрудника';
	            state.btn = 'Сохранить';

                state.id = modal.data.employee.id;
                state.name = modal.data.employee.employeeName;
                state.organizationId = modal.data.employee.organizationId;
                state.plot = modal.data.employee.employeePlot;
                state.post = modal.data.employee.employeePost;
                state.pacsCards = modal.data.employee.pacsCards;
                state.iin = modal.data.employee.iin || '';
                state.isNotOurEmployee = !!modal.data.employee.isNotOurEmployee || false;
                state.supplierId = modal.data.employee.supplierId || null;
                // state.workingModeId = modal.data.employee.employeeWorkingMode;
            }

            api.organizations.get().then((r: any) => {
	            organizations.value = r.data;
            });
            api.supplier.get().then(r => {
                suppliers.value = r.data;
            });
        });

	    function onOrganizationSelected(organization: any) {
		    state.organizationId = organization.id;
	    }

	    function onPlotSelected(t: any) {
		    state.plot = t.id;
	    }

        function onWorkingModeSelected(mode: any) {
            state.workingModeId = mode.id;
        }

        function handleSupplierSelect(supplier: ISupplier) {
            console.log(supplier);
            if (state.supplierId === supplier.id) {
                state.supplierId = 0;
            } else {
                state.supplierId = supplier.id;
            }
        }

        function send() {
            if (errors.value) {
                state.visibleErrors = true;
            } else {
                state.visibleErrors = false;
                state.loader = true;
	            if (modal.data.type === 'add') {
		            api.employees.post({
			            name: state.name,
			            organizationId: state.organizationId,
			            pacsCards: state.pacsCards,
			            plot: state.plot,
			            iin: state.iin ? String(state.iin) : null,
			            contact: state.contact,
			            post: state.post,
			            workingMode: null, // state.workingModeId,
                        isNotOurEmployee: state.isNotOurEmployee,
                        supplierId: state.supplierId,
		            }).then((r: any) => {
			            if (r.data) {
				            modal.ok({
					            employee: r.data,
				            });
			            }
		            }).finally(() => state.loader = false);
	            } else {
		            api.employees.patch({
			            id: state.id,
			            name: state.name,
			            pacsCards: state.pacsCards,
			            organizationId: state.organizationId,
			            iin: state.iin,
			            contact: state.contact,
			            plot: state.plot,
			            post: state.post,
			            workingMode: null, // state.workingModeId,
                        isNotOurEmployee: state.isNotOurEmployee,
                        supplierId: state.supplierId,
		            }).then((r: any) => {
			            if (r.data) {
				            modal.ok({
					            employee: r.data,
				            });
			            }
		            }).finally(() => state.loader = false);
	            }
            }
        }

	    function ok() {
		    modal.ok({
			    asd: 123,
		    });
	    }

        function close() {
            modal.close();
        }

	    function cancel() {
		    modal.cancel({
			    zxc: 123,
		    });
	    }

        return {
            ok,
            cancel,
            close,
            state,
	        organizations,
	        workingModes,
	        isNameError,
	        isOrganizationIdError,
	        isPlotError,
	        isPostError,
            isIINError,
	        onOrganizationSelected,
	        plots,
	        onPlotSelected,
	        onWorkingModeSelected,
	        send,
            suppliers,
            handleSupplierSelect,
            isSupplierError,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
