<template>
    <div
        class="modal fade show">
        <div class="modal-dialog modal-dialog-centered" :class="setSize">
            <div class="modal-content">
                <div class="modal-header px-4 mx-0">
                    <h5 class="modal-title">
                        {{ getTitle }}
                    </h5>
                    <button
                        class="btn-close me-0"
                        type="button"
                        @click="cancel"></button>
                </div>
                <div class="modal-body px-4 py-4">
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent, reactive } from "vue";
import { useModal } from "@/composables/useModal";
import t from "@/utils/t";

export default defineComponent({
    name: "ModalUi",
    props: {
        id: {
            type: String,
            default: null,
        },
        title: {
            type: String,
            default: '',
        },
        size: {
            type: String,
            default: 'modal-lg',
        },
    },
    setup(props) {
        const state = reactive({
            opened: false,
        });

        const getTitle = computed(() => props.title ? t(props.title) : '');

        const setSize = computed(() => props.size !== 'modal-md' ? props.size : '');

        function open() {
            state.opened = true;
        }

        function close() {
	        useModal(props.id).close();
        }

        function cancel() {
            useModal(props.id).cancel();
        }

        return {
            state,
            close,
            open,
            cancel,
            getTitle,
            setSize,
        };
    },
});
</script>

<style scoped lang="scss">
    .show {
        display: block;
    }
</style>
