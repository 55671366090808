<template>
    <modal-ui
        :id="id"
        size="modal-md"
        :title="state.title">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-12">
                    <form-group-ui
                        :invalid-feedback="isLocomotiveExistsInDepoError ? 'Данные секции уже имеется в депо' : 'Выберите локомотив'"
                        label="Локомотив"
                        required>
                        <select-ui
                            :is-invalid="state.visibleErrors && (isLocomotiveIdError || isLocomotiveExistsInDepoError)"
                            item-key="name"
                            :options="locomotives"
                            placeholder="Локомотив"
                            :searchable="true"
                            :values="[state.locomotiveId]"
                            @on-selected="onLocoModelSelected">
                        </select-ui>
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <label v-if="sectionsOfSelectedLocomotive.length"
                        class="d-block mb-2" :class="{ 'text-danger': state.visibleErrors && isSectionIdsError }">
                        {{ state.visibleErrors && isSectionIdsError ? 'Выберите секцию' : 'Секция' }}
                    </label>
                    <label v-for="section in sectionsOfSelectedLocomotive" :key="section.id" class="form-check form-check-inline">
                        <input
                            :checked="state.sectionIds.includes(section.id)"
                            @input="handleSelectSection($event, section)"
                            class="form-check-input"
                            type="checkbox">
                        <span class="form-check-label">{{ section.number }}</span>
                    </label>
                </div>
                <div class="col-12">
                    <form-group-ui
                        invalid-feedback="Выберите вид работ"
                        label="Вид работ"
                        required>
                        <select-ui
                            :is-invalid="state.visibleErrors && isJobCategoryIdError"
                            item-key="name"
                            :options="jobCategories"
                            placeholder="Вид работ"
                            :searchable="true"
                            :values="[state.jobCategoryId]"
                            @on-selected="onJobCategorySelected" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui
                        invalid-feedback="Выберите дату поступления"
                        label="Дата поступления"
                        required>
                        <date-picker
                            v-model="state.date"
                            class="inline-block h-full"
                            :is24hr="true"
                            mode="dateTime">
                            <template #default="{ inputValue, togglePopover }">
                                <div class="flex items-center">
                                    <input
                                        class="form-control"
                                        :class="{'is-invalid': isDateError && state.visibleErrors, 'bg-white': true }"
                                        placeholder="Дата поступления"
                                        readonly
                                        :timezone="'Asia/Dhaka'"
                                        :value="inputValue"
                                        @blur="togglePopover()"
                                        @focus="togglePopover()">
                                    <div class="invalid-feedback">
                                        Укажите дату
                                    </div>
                                </div>
                            </template>
                        </date-picker>
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <label class="form-check form-check-inline">
                        <input
                            v-model="state.warranty"
                            class="form-check-input"
                            type="checkbox">
                        <span class="form-check-label">Ремонт по гарантии</span>
                    </label>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button-ui
                        button-class="btn-primary">
                        {{ state.btn }}
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref } from "vue";
import { useModal } from "@/composables/useModal";
import api from "@/api";
import { DatePicker } from 'v-calendar';
import moment from 'moment-timezone';

type stateType = {
    title: string,
    btn: string,
    locomotiveId: number,
    jobCategoryId: number,
    warranty: boolean,
    sectionIds: number[],
    date: string,
    visibleErrors: boolean,
    loader: boolean,
};

export default defineComponent({
    name: "GridModal",
    components: {
        DatePicker,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
	    const modal = useModal(props.id);

	    const jobCategories = ref([]);
        const locomotives = ref([]);
        const _locomotives = ref([]);

        const state: stateType = reactive({
            title: '',
            btn: '',
	        locomotiveId: 0,
	        jobCategoryId: 0,
	        warranty: false,
            sectionIds: [],
	        date: moment().format('YYYY-MM-DD HH:mm:ss'),
            visibleErrors: false,
            loader: false,
        });

	    const isLocomotiveIdError = computed(() => !state.locomotiveId);
	    const isJobCategoryIdError = computed(() => !state.jobCategoryId);
        const isLocomotiveExistsInDepoError = computed(() => {
            return _locomotives.value.filter((i: any) => state.sectionIds.includes(i.sectionId)).length;
        });
	    const isDateError = computed(() => !state.date);
        const isSectionIdsError = computed(() => !state.sectionIds.length);

        const sectionsOfSelectedLocomotive = computed(() => {
            if (!state.locomotiveId) {
                return [];
            }
            const locomotive: any = locomotives.value.find((locomotive: any) => locomotive.id === +state.locomotiveId);
            if (!locomotive) {
                return [];
            }
            return locomotive.sections;
        });

        function handleSelectSection(e: Event, model: any) {
            const element = e.target as HTMLInputElement;
            const isChecked = element.checked

            if(isChecked) {
                state.sectionIds.push(model.id);
                return;
            }
            const idx = state.sectionIds.findIndex(i => i === model.id);
            if (idx < 0) {
                return;
            }
            state.sectionIds.splice(idx, 1)
        }

        const errors = computed(() => {
            return isLocomotiveIdError.value ||
	            isLocomotiveExistsInDepoError.value ||
				isJobCategoryIdError.value ||
                isSectionIdsError.value ||
				isDateError.value;
        });

        onMounted((() => {
            if (modal.data.type === 'add') {
                state.title = 'Добавление локомотива';
                state.btn = 'Добавить';
            }

            api.info.get().then((r: any) => {
	            jobCategories.value = r.data.jobCategories;
            });

            api.locomotives.getAll().then(data => {
                locomotives.value = data;
            }).catch(console.error);

	        _locomotives.value = modal.data.locomotives;
        }));

	    function onLocoModelSelected(locomotive: any) {
		    state.locomotiveId = locomotive.id;
	    }

	    function onJobCategorySelected(category: any) {
            state.jobCategoryId = category.id;
	    }

        function send() {
            if (errors.value) {
                state.visibleErrors = true;
            } else {
                state.visibleErrors = false;
                state.loader = true;
                api.locomotives.createSectionJob({
	                locomotiveId: state.locomotiveId,
                    sectionIds: state.sectionIds,
	                jobId: state.jobCategoryId,
	                warranty: state.warranty,
                    date: moment(state.date).format('YYYY-MM-DD HH:mm:ss'),
                }).then((r: any) => {
                    modal.ok(r.data);
                }).finally(() => state.loader = false);
            }
        }

	    function ok() {
		    modal.ok({
			    asd: 123,
		    });
	    }

        function close() {
            modal.close();
        }

	    function cancel() {
		    modal.cancel({
			    zxc: 123,
		    });
	    }

        return {
            ok,
            cancel,
            close,
            state,
	        jobCategories,
	        locomotives,
	        _locomotives,
	        isLocomotiveIdError,
	        isJobCategoryIdError,
            isSectionIdsError,
	        isLocomotiveExistsInDepoError,
            handleSelectSection,
            sectionsOfSelectedLocomotive,
	        isDateError,
	        onLocoModelSelected,
	        onJobCategorySelected,
	        send,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
