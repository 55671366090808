<template>
    <div class="col-auto d-none d-sm-block flex-grow-1"></div>
    <div class="col-auto flex-grow-0 d-flex align-items-center">
        <select v-model="state.month" class="form-select me-2">
            <option disabled value="">
                Месяц
            </option>
            <option v-for="(month, monthIdx) in months" :key="month" :value="monthIdx">
                {{ month }}
            </option>
        </select>
        <input-ui v-model="state.year" :mask="{ mask: 'Z*', tokens: { 'Z': { pattern: /[0-9]/ } } }"
            maxlength="4" placeholder="Год" />
    </div>
    <div class="mt-3">
        <div class="container-fluid">
            <div class="row mb-2">
            </div>
        </div>
        <div class="overflow-hidden">
            <div class="bg-white position-relative overflow-auto mw-100 pb-5" style="max-height: 650px;">
                <table class="table table-bordered">
                    <thead class="sticky-top bg-white">
                        <tr class="position-relative border-0">
                            <th class="p-2">
                                Техника
                            </th>
                            <th class="p-2">
                                Вид&nbsp;работ
                            </th>
                            <th class="p-2">
                                Поступил&nbsp;в&nbsp;депо
                            </th>
                            <th class="p-2">
                                Принят&nbsp;на&nbsp;ремонт
                            </th>
                            <th class="p-2">
                                Плановый&nbsp;выход
                            </th>
                            <th class="p-2">
                                Завершен&nbsp;ремонт
                            </th>
                            <th class="p-2">
                                Покинул&nbsp;депо
                            </th>
                            <th class="p-2">
                                Кол&#8209;во&nbsp;дней&nbsp;в&nbsp;ремонте
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="state.sectionJobs.length">
                            <tr v-for="(sectionJob) in state.sectionJobs" :key="sectionJob.id">
                                <td class="p-2">
                                    {{ sectionJob.name }}
                                </td>
                                <td class="p-2">
                                    {{ sectionJob.jobCategory?.name }}
                                </td>
                                <td class="p-2">
                                    {{ sectionJob?.receivedAt ? formatDate(sectionJob?.receivedAt) : '' }}
                                </td>
                                <td class="p-2">
                                    {{ sectionJob?.repairStartsAt ? formatDate(sectionJob?.repairStartsAt) : '' }}
                                </td>
                                <td class="p-2">
                                    {{ sectionJob?.plansToLeaveAt ? formatDate(sectionJob?.plansToLeaveAt) : '' }}
                                </td>
                                <td class="p-2">
                                    {{ sectionJob?.repairEndsAt ? formatDate(sectionJob?.repairEndsAt) : '' }}
                                </td>
                                <td class="p-2">
                                    {{ sectionJob?.leavesAt ? formatDate(sectionJob?.leavesAt) : '' }}
                                </td>
                                <td class="p-2 text-end">
                                    {{
                                        sectionJob?.repairStartsAt
                                            ? moment(sectionJob?.repairEndsAt || new Date()).diff(sectionJob.repairStartsAt,
                                                'day')
                                            : ''
                                    }}
                                </td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <td colspan="8" class="p-2 text-center">Записи отсутствуют</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script lang="ts" async setup>

import api from "@/api";
import { reactive, computed, watch } from "vue";
import moment from 'moment-timezone';
import formatDate from "@/utils/formatDate";
import { ISectionJob } from "@/@types/ISectionJob";

moment.locale('ru');

type consolidatedReportOnMaterialMovementType = {
    id: number;
    documentNumber: string;
    materialName: string;
    requestedAt: string;
    protocolNumber?: string;
    protocolSentForApprovalAt?: string;
    supplierName?: string;
    paidAt?: string;
};

type reportStateType = {
    materials: consolidatedReportOnMaterialMovementType[];
    sectionJobs: ISectionJob[];
    month: number;
    year: number;
};
const state: reportStateType = reactive({
    materials: [],
    sectionJobs: [],
    month: moment().month(),
    year: moment().year(),
});

const months = computed(() => moment.months());

api.sectionJob.getRepairExitReportData({
    month: state.month,
    year: state.year,
}).then((r) => {
    state.sectionJobs = r.data;
})

watch(() => [state.month, state.year], (v: any) => {
    if (!state.month || !state.year) {
        return;
    }
    api.sectionJob.getRepairExitReportData({
        month: state.month,
        year: state.year,
    }).then((r) => {
        state.sectionJobs = r.data;
    });
});

</script>

<style scoped lang="scss"></style>
