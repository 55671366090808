import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row mb-4" }
const _hoisted_2 = { class: "col-md-6 col-lg-6 col-xl-6 col-xxl-6" }
const _hoisted_3 = {
  key: 0,
  class: "col-md-6 col-lg-6 col-xl-6 col-xxl-6"
}
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-12 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader_ui = _resolveComponent("loader-ui")!
  const _component_select_ui = _resolveComponent("select-ui")!
  const _component_form_group_ui = _resolveComponent("form-group-ui")!
  const _component_button_ui = _resolveComponent("button-ui")!
  const _component_modal_ui = _resolveComponent("modal-ui")!

  return (_openBlock(), _createBlock(_component_modal_ui, {
    id: _ctx.id,
    size: "modal-lg",
    title: _ctx.state.title
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        class: "loader",
        onSubmit: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.send && _ctx.send(...args)), ["prevent"]))
      }, [
        _createVNode(_component_loader_ui, {
          "is-show": _ctx.state.loader
        }, null, 8, ["is-show"]),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": "Выберите локомотив",
              label: "Локомотив",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_select_ui, {
                  "is-invalid": _ctx.state.visibleErrors && _ctx.isLocomotiveIdError,
                  "item-key": "name",
                  options: _ctx.locomotives,
                  placeholder: "Локомотив",
                  searchable: true,
                  values: [_ctx.state.locomotiveId],
                  onOnSelected: _ctx.onLocomotiveSelected
                }, null, 8, ["is-invalid", "options", "values", "onOnSelected"])
              ]),
              _: 1
            })
          ]),
          (_ctx.state.locomotiveId !== 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_form_group_ui, {
                  "invalid-feedback": "Выберите вид работы",
                  label: "Вид работы",
                  required: ""
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_select_ui, {
                      "is-invalid": _ctx.state.visibleErrors && _ctx.isJobIdError,
                      "item-key": "work",
                      options: _ctx.jobs,
                      placeholder: "Вид работы",
                      searchable: true,
                      values: [_ctx.state.jobId],
                      onOnSelected: _ctx.onJobSelected
                    }, null, 8, ["is-invalid", "options", "values", "onOnSelected"])
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_button_ui, { "button-class": "btn-primary" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.state.btn), 1)
              ]),
              _: 1
            })
          ])
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["id", "title"]))
}