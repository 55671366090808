<template>
    <modal-ui :id="id" size="modal-md" title="Редактирование данных пользователя">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row">
                <div class="col-12">
                    <form-group-ui label="ФИО">
                        <input-ui class="mt-2" v-model="state.fullName" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui label="Фамилия и инициалы">
                        <input-ui class="mt-2" v-model="state.name" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui invalid-feedback="Укажите должность" label="Должность">
                        <input-ui class="mt-2" v-model="state.position" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui invalid-feedback="Роль не указан" label="Роль">
                        <select class="form-select mt-2" v-model="state.roleId">
                            <option value="0" disabled>Не выбрано</option>
                            <option v-for="role in ROLE_COLLECTION" :key="role.VALUE" :value="role.VALUE">
                                {{ role.LABEL }}
                            </option>
                        </select>
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui invalid-feedback="Депо не указаны" label="Депо">
                        <select-ui :multiple="true" :is-invalid="state.visibleErrors && isDepotsError"
                            :options="depotsList" placeholder="Депо" :searchable="true" :values="state.depots"
                            @on-updated="handleDepoSelect" />
                    </form-group-ui>
                </div>
            </div>
            <div class="col-12 text-center mt-3">
                <button-ui button-class="btn-primary">
                    Сохранить
                </button-ui>
            </div>
        </form>
    </modal-ui>
</template>

<script setup lang="ts">
import { onMounted, reactive, computed, ref } from "vue";
import { ROLE_COLLECTION } from '@depo/constants/user';
import { useModal } from "@/composables/useModal";
import api from "@/api";

type stateType = {
    id: number,
    visibleErrors: boolean,
    loader: boolean,
    roleId: number;
    position: string;
    fullName: string;
    name: string;
    email: string;
    depots: number[];
};

const props = defineProps({
    id: {
        type: String,
        default: '',
    },
});

const modal = useModal(props.id);
const depotsList = ref<{ id: number, name: string }[]>([]);
const state: stateType = reactive({
    id: 0,
    visibleErrors: false,
    loader: false,
    roleId: 0,
    position: '',
    fullName: '',
    name: '',
    email: '',
    depots: [],
});
onMounted(() => {
    if (Object.keys(modal.data.user).length) {
        state.id = modal.data.user.id;
        state.name = modal.data.user.name;
        state.roleId = modal.data.user.roleId;
        state.fullName = modal.data.user.fullName;
        state.position = modal.data.user.position;
        state.email = modal.data.user.email;
        state.depots = modal.data.user?.userDepots.map((ud: any) => ud.depotId);
    }

    api.depot.get().then(r => {
        depotsList.value = r.data;
    });
});

const isRoleError = computed(() => !Number(state.roleId));
const isDepotsError = computed(() => !state.depots.length);

const isErrorsExist = computed(() => {
    return isRoleError.value || isDepotsError.value;
});

function handleDepoSelect(_c: any) {
    state.depots = _c.map((i: any) => i.id);
}

function send() {
    if (isErrorsExist.value) {
        state.visibleErrors = true;
        return;
    }
    state.visibleErrors = false;
    state.loader = true;

    api.user.update(state.id, {
        roleId: state.roleId,
        position: state.position,
        fullName: state.fullName,
        name: state.name,
        email: state.email,
        depots: state.depots,
    }).then(r => {
        modal.ok(r.data);
    })
}

function ok(loco: any) {
    modal.ok({
        loco: loco,
    });
}

function close() {
    modal.cancel({
        zxc: 123,
    });
}

function cancel() {
    modal.cancel({
        zxc: 123,
    });
}

</script>

<style scoped lang="scss"></style>
