<template>
  <div
      ref="target"
      class="position-relative select"
      :class="getSelectClass">
    <button
        v-if="state.values.length && multiple && !disabled"
        class="select__reset btn btn-sm btn-light"
        @click="reset">
      <vue-feather
          class="d-flex"
          size="20px"
          type="x"/>
    </button>

    <div
        v-if="!state.values.length || !multiple"
        class="select__arrow btn btn-sm btn-light">
      <vue-feather
          class="d-flex"
          size="20px"
          type="chevron-down"/>
    </div>
    <div class="input-group select__input">
      <div v-if="multiple && searchable && state.values.length" class="input-group-text">
        Выбрано: {{ getValue }}
      </div>
      <input
          v-model="getModel"
          v-bind="$attrs"
          autocomplete="off"
          class="form-control position-relative"
          :class="getInputClass"
          :disabled="getDisabled"
          :placeholder="getPlaceholder"
          :readonly="getReadonly"
          @focus="focus"
          @input="input($event)">
    </div>
    <simple-bar
        v-if="state.isOpen"
        :auto-hide="false"
        class="card border select__options options simple-bar-dark">
      <div
          v-for="(option, optionInd) in getOptions"
          :key="option.id"
          class="options__item"
          :class="getOptionClass(option)"
          @click="select(option)">
        <slot name="option" :option="option">
          {{ option[itemKey] }}
        </slot>
      </div>
      <div v-if="!getOptions.length && !state.loader" class="options__empty">
        Ничего не найдено
      </div>
      <div v-if="state.loader" class="options__loader">
        <loader-ui :is-show="state.loader" size="sm"/>
      </div>
    </simple-bar>
  </div>
</template>

<script lang="ts">
import {computed, ComputedRef, defineComponent, onMounted, onUnmounted, PropType, reactive, ref, watch} from "vue";
import {IUnknownObject} from "@/@types/common";
import {onClickOutside} from "@vueuse/core";
// import { api } from '@/api';
import axios from "axios";
import t from "@/utils/t";
import {getCurrentInstance} from "vue";

// TODO: если попросят поменять name на что то другое - сразу посылать, вдогонку крича про переименовывание колонки при селекте из базы

export interface IOption extends IUnknownObject {
  id: string | number,
  name?: string
}

interface IState {
  values: IOption[],
  query: string,
  options: IOption[],
  isOpen: boolean,
  loader: boolean,
  position: string
}

export default defineComponent({
  name: "SelectUi",
  props: {
    options: {
      type: Array,
      default: [] as PropType<IOption[]>,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Выберите значение',
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    itemKey: {
      type: String,
      default: 'name',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Array,
      default: [],
    },
    filtering: {
      type: Function,
      default: () => {},
    },
    asyncOptionsUrl: {
      type: Function,
      default: (query: string) => '',
    },
    mode: {
      type: String as () => 'static' | 'async',
      default: 'static',
    },
    isInvalid: {
      type: [Boolean, Number],
      default: false,
    },
    isValid: {
      type: [Boolean, Number],
      default: false,
    },
    disabled: {
      type: Boolean,
      default: null,
    },
    inputClass: {
      type: String,
      default: '',
    },
    chooseSingleOption: {
      type: Boolean,
      default: true,
    },
  },
  emits: {
    onSelected: null,
    onUnSelected: null,
    onUpdated: null,
    onInput: null,
  },
  setup(props, {emit}) {
    let asyncInterval: any = null;
    const CancelToken = axios.CancelToken;
    let cancel = () => {
    };

    const target = ref(<HTMLElement><unknown>null);

    const state = reactive(<IState>{
      values: [],
      query: '',
      options: <IOption[]>props.options,
      isOpen: false,
      loader: false,
      position: 'bottom',
    });

    const instance = getCurrentInstance()

    const getModel = computed({
      get() {
        if (!props.searchable || (props.searchable && !props.multiple)) {
          if (state.values.length) {
            return state.values.map((option: IOption) => option[props.itemKey]).join(', ');
          }
        }

        return state.query;
      },
      set(value: string) {
        state.query = value;
        // return emit('update:modelValue', value);
      },
    });

    const getInputClass = computed(() => {
      let defaultClass = props.inputClass;
      let notSearchable = !props.searchable;
      let isInvalid = props.isInvalid ? 'is-invalid' : null;
      let isValid = props.isValid ? 'is-valid' : null;
      return [defaultClass, notSearchable, isInvalid, isValid].filter(i => i).join(' ');
    });

    const getPlaceholder = computed(() => {
      return t(props.placeholder);
    });

    const getOptions = computed(() => {
      if (!state.query.length || props.mode === 'async') return state.options;
      else return state.options.filter((option: IOption) => {
        if(props.filtering.toString() === '() => {}' || props.filtering.toString() === '()=>{}') {
          if (option[props.itemKey].toLowerCase().includes(state.query.toLowerCase())) return true;
          return false;
        }else{
          return props.filtering(option, state.query)
        }
      });
    });

    const getSelectClass = computed(() => ({
      '--active': state.isOpen,
      'is-invalid': props.isInvalid,
      '--top': state.position === 'top',
      '--bottom': state.position === 'bottom',
    }));

    const getOptionClass = computed(() => (option: any) => ({
      'bg-success bg-opacity-25': !!option?.isHighlighted,
      '--selected bg-primary bg-opacity-75': state.values.find((o: IOption) => o.id === option.id),
    }));

    const getReadonly = computed(() => {
      if (!props.searchable && !props.disabled) return 'readonly';

      return null;
    });

    const getDisabled = computed(() => props.disabled ? 'disabled' : null);

    const getValue = computed(() => {
      if (state.values.length < 2) {
        return state.values.map((option: IOption) => option[props.itemKey]).join(', ');
      } else {
        return state.values.length + ' варианта';
      }
    });

    onClickOutside(target, (event: any) => {
      // console.log(event);
      state.isOpen = false;
    });

    onMounted(() => {
      if (props.chooseSingleOption) {
        if (state.options.length === 1) {
          emit('onSelected', state.options[0]);
        }
      }
      ;
      window.addEventListener('scroll', scroll);
      scroll();
    });

    onUnmounted(() => {
      window.removeEventListener('scroll', scroll);
    });

    function scroll() {
      let bodyRect = document.body.getBoundingClientRect(),
          elemRect = target.value.getBoundingClientRect(),
          offset = elemRect.top - bodyRect.top,
          bottom = bodyRect.height + Math.abs(bodyRect.top),
          elem = offset + 33 + 300;

      if (elem > bottom) state.position = 'top';
      else state.position = 'bottom';
    }

    function focus() {
      console.log('focus');
      state.isOpen = true;
    }

    function blur() {
      console.log('blur');
      state.isOpen = false;
    }

    function input(e: InputEvent) {
      // console.log(e);
      if (props.mode === 'async') {
        state.options = [];
        state.values = [];
        state.loader = true;
        clearTimeout(asyncInterval);
        cancel();

        // asyncInterval = setTimeout(async () => {
        //     await api.get(props.asyncOptionsUrl(state.query), {
        //         cancelToken: new CancelToken(function executor(c) {
        //             cancel = c;
        //         }),
        //     }).then((r: any) => {
        //         state.options = r.data.data;
        //     }).catch((e: any) => {
        //
        //     }).finally(() => state.loader = false);
        // }, 500);
      }
    }

    function select(option: IOption) {
      if (props.multiple) {
        let index = state.values.findIndex((o: IOption) => o.id === option.id);
        // console.log(option, index);
        if (index !== -1) {
          state.values.splice(index, 1);
          emit('onUnSelected', option);
        } else {
          state.values.push(option);
          emit('onSelected', option);
        }
      } else {
        state.values = Array.from([option]);
        emit('onSelected', option);
        state.isOpen = false;
      }

      emit('onUpdated', props.multiple ? state.values : state.values[0]);
    }

    function reset() {
      state.values = [];
      emit('onUpdated', props.multiple ? state.values : state.values[0]);
    }

    function getAsyncOptions() {

    }

    function syncValuesWithOptions() {
      // console.log(1, props.values);
      // @ts-ignore
      // console.log(2, state.options.filter((option: IOption) => props.values.includes(option.id)));
      // @ts-ignore
      let diff = state.values.filter((option: IOption) => !props.values.includes(option.id));
      // console.log(1, diff, 2, state.values, 3, props.values);
      if (diff.length) {
        // state.values = state.options.filter((option: IOption) => props.values.includes(option.id));
      }

      state.values = state.options.filter((option: IOption) => props.values.includes(option.id));
    }

    // watch(() => state.values, () => emit('onUpdated', props.multiple ? state.values : state.values[0]), { deep: true });
    watch(() => [...props.values], (v, o) => {
      syncValuesWithOptions();
      // state.query = '';
    }, {immediate: true});

    watch(() => <IOption[]>props.options, (v: IOption[]) => {
      state.options = v;
      syncValuesWithOptions();
    }, {
      deep: true,
    });

    return {
      state,
      target,
      getModel,
      getOptions,
      getOptionClass,
      getSelectClass,
      getDisabled,
      getInputClass,
      getValue,
      getPlaceholder,
      getReadonly,
      focus,
      blur,
      select,
      input,
      reset,
      instance
    };
  },
});
</script>

<style lang="scss" scoped>

.select.--bottom .select__options {
  margin-top: -1px;
  border-top: none !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.select.--bottom .options {
  top: auto !important;
  bottom: auto !important;
}

.select.--top .select__options {
  margin-bottom: -1px;
  border-bottom: none !important;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.select.--top .options {
  bottom: 32px !important;
  top: auto !important;
}

.select {
  &.--active {
    z-index: 10;
  }

  &__input {
    z-index: 2;

    & input[readonly] {
      background: #fff;
      padding-right: 30px;
    }
  }

  &__options {
    //margin-top: -1px;
    //z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
    min-width: 100%;
    max-height: 300px;
  }

  &__reset {
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    z-index: 3;
    background: #fff;
    border: none;
    width: 31px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;

    &:hover {
      background: var(--bs-gray-200);
    }
  }

  &__arrow {
    position: absolute;
    top: 1px;
    right: 1px;
    bottom: 1px;
    z-index: 3;
    background: transparent;
    border: none;
    width: 31px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    display: flex;
    align-content: center;
    align-items: center;
    pointer-events: none;
  }
}

.options {
  padding: 5px 0;
  width: auto;

  &__item {
    appearance: none;
    background-clip: padding-box;
    background-color: #fff;
    //border: 1px solid transparent;
    color: #495057;
    display: block;
    font-size: .875rem;
    user-select: none;
    font-weight: 400;
    line-height: 1.5;
    //padding: .3rem .85rem;
    padding: calc(.3rem + 1px) calc(.85rem + 1px);
    width: 100%;
    cursor: pointer;

    &:hover {
      background: var(--bs-gray-200);
      //color: #fff;
    }

    &.--selected {
      color: #fff;
    }
  }

  &__empty {
    background-clip: padding-box;
    background: var(--bs-gray-200);
    color: #495057;
    display: block;
    font-size: .875rem;
    user-select: none;
    font-weight: 400;
    line-height: 1.5;
    padding: calc(.3rem + 1px) calc(.85rem + 1px);
    width: 100%;
  }

  & .simplebar-content {
    height: auto;
    padding: 10px 0 5px !important;
  }

  &__loader {
    height: 30px;
  }
}

.input-not-searchable {
  background: #fff;
  cursor: pointer;
}

</style>
