<template>
    <modal-ui :id="id" size="modal-xl" :title="state.title">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-12 col-md-3">
                    <form-group-ui invalid-feedback="Укажите наименование ТМЦ" label="Наименование ТМЦ" required>
                        <select-ui :is-invalid="state.visibleErrors && isMaterialIdError"
                            :options="materials" placeholder="Наименование ТМЦ" :searchable="true"
                            :values="[state.materialId]"
                            @on-selected="(value: any) => onMaterialSelected(value)" />
                    </form-group-ui>
                </div>
                <div class="col-12 col-md-1">
                    <div>
                        <label for="formFile" class="form-label">Ед изм</label>
                        <input class="form-control" :value="getUnitDimensionOfMaterial" disabled>
                    </div>
                </div>
                <div class="col-12 col-md-3">
                    <form-group-ui invalid-feedback="Укажите конструктив" label="Конструктив" required>
                        <select-ui :is-invalid="state.visibleErrors && isConstructIdError"
                            :options="constructs" placeholder="Конструктив" :searchable="true"
                            :values="[state.constructId]"
                            @on-selected="(value: any) => onConstructSelected(value)" />
                    </form-group-ui>
                </div>
                <div class="col-12 col-md-2">
                    <form-group-ui invalid-feedback="Укажите количество" label="Количество" required>
                        <input-ui v-model="state.count"
                            :mask="{ mask: 'Z*', tokens: { 'Z': { pattern: /^[0-9.,]+$/ } } }"
                            :is-invalid="state.visibleErrors && isCountError"
                            @input="onCountEnter"
                            placeholder="Количество" />
                    </form-group-ui>
                </div>
                <div class="col-12 col-md-2">
                    <form-group-ui invalid-feedback="Укажите примечание" label="Примечание">
                        <input-ui v-model="state.comment" placeholder="Примечание" />
                    </form-group-ui>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button-ui button-class="btn-primary">
                        {{ state.btn }}
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts" async setup>
import { onMounted, reactive, computed, ref } from "vue";
import { useModal } from "@/composables/useModal";
import api from "@/api";
import { useRoute } from "vue-router";
import { IMaterial } from "@/@types/IMaterial";

const props = defineProps({
    id: Number,
});

const modal = useModal(String(props.id));
const route = useRoute();

const materials = ref<IMaterial[]>([]);
const constructs = ref([]);

const state = reactive({
    title: '',
    btn: '',
    id: 0,
    count: '',
    comment: '',
    materialId: 0,
    constructId: 0,
    visibleErrors: false,
    loader: false,
});

const isMaterialIdError = computed(() => !state.materialId);
const isConstructIdError = computed(() => !state.constructId);
const isCountError = computed(() => +state.count <= 0);
const getUnitDimensionOfMaterial = computed(() => {
    if (!state.materialId) {
        return '-';
    }
    const material = materials.value.find((i: any) => i.id === state.materialId);
    if (!material) {
        return '-';
    }
    return material.measurement;
})

const errors = computed(() => {
    return isMaterialIdError.value || isConstructIdError.value || isCountError.value;
});

onMounted(() => {
    state.title = 'Редактирование';
    state.btn = 'Сохранить';

    api.constructs.get().then(r => constructs.value = r.data);
    api.materials.getOld().then(r => materials.value = r.data);

    if (Object.keys(modal.data.requiredMaterial).length) {
        state.count = modal.data.requiredMaterial.count;
        state.comment = modal.data.requiredMaterial.comment;
        state.materialId = modal.data.requiredMaterial.materialId;
        state.constructId = modal.data.requiredMaterial.subgroupId;
    }
});

function send() {
    if (errors.value) {
        state.visibleErrors = true;
        return;
    }
    state.visibleErrors = false;
    state.loader = true;
    api.apMaterial.update(+modal.data.requiredMaterial.id, {
        count: state.count,
        comment: state.comment ? state.comment : null,
        materialId: state.materialId,
        subgroupId: state.constructId,
    }).then((r: any) => {
        if (r.data) {
            modal.ok(r.data);
        }
    }).finally(() => state.loader = false);
}

function onMaterialSelected(_m: any) {
    state.materialId = _m.id;
}

function onConstructSelected(_c: any) {
    state.constructId = _c.id;
}

function onCountEnter() {
    state.count = state.count.replaceAll(',', '.');
}

</script>

<style scoped lang="scss"></style>
