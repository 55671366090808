<template>
    <modal-ui
        :id="id"
        size="modal-lg"
        :title="state.title">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Выберите локомотив"
                        label="Локомотив"
                        required>
                        <select-ui
                            :is-invalid="state.visibleErrors && isLocomotiveIdError"
                            item-key="name"
                            :options="locomotives"
                            placeholder="Локомотив"
                            :searchable="true"
                            :values="[state.locomotiveId]"
                            @on-selected="onLocomotiveSelected" />
                    </form-group-ui>
                </div>
                <div v-if="state.locomotiveId !== 0" class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Выберите вид работы"
                        label="Вид работы"
                        required>
                        <select-ui
                            :is-invalid="state.visibleErrors && isJobIdError"
                            item-key="work"
                            :options="jobs"
                            placeholder="Вид работы"
                            :searchable="true"
                            :values="[state.jobId]"
                            @on-selected="onJobSelected" />
                    </form-group-ui>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button-ui
                        button-class="btn-primary">
                        {{ state.btn }}
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref, watch } from "vue";
import { initModal, useModal } from "@/composables/useModal";
import api from "@/api";
import { DatePicker } from 'v-calendar';
import moment from 'moment-timezone';
import { insert } from "@/composables/useTransmitter";

export default defineComponent({
    name: "ProcessEngineerFormTwoModal",
    components: {
        DatePicker,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
	    const modal = useModal(props.id);
        const locomotives = ref([]);
        const jobs = ref([]);

        const state = reactive({
            title: '',
            btn: '',
	        id: 0,
	        locomotiveId: 0,
	        ids: '',
	        locomotive: '',
	        job: '',
	        date: '',
	        jobId: 0,
            visibleErrors: false,
            loader: false,
        });

	    const isLocomotiveIdError = computed(() => !state.locomotiveId);
	    const isJobIdError = computed(() => !state.jobId);

        const errors = computed(() => {
            return isLocomotiveIdError.value ||
	            isJobIdError.value;
        });

        onMounted(() => {
            if (modal.data.type === 'add') {
                state.title = 'Форма 2';
                state.btn = 'Создать';
            } else {
	            // state.title = 'Редактирование ТМЦ';
	            // state.btn = 'Сохранить';
                //
                // state.id = modal.data.material.id;
                // state.comment = modal.data.material.comment ? modal.data.material.comment : '';
                // state.count = modal.data.material.count;
                // state.employeeId = modal.data.material.employeeId;
                // state.materialId = modal.data.material.materialId;
                // state.sectionId = modal.data.material.sectionId;
                // state.measurement = modal.data.material.measurement;
            }

	        api.info.get().then((r: any) => {
		        locomotives.value = r.data.locos;
	        });
        });

        function send() {
            if (errors.value) {
                state.visibleErrors = true;
            } else {
                state.visibleErrors = false;
                state.loader = true;
	            if (modal.data.type === 'add') {
		            api.form2.post({
			            ids: state.ids.split(','),
			            locomotive: state.locomotive,
			            locomotiveId: state.locomotiveId,
			            job: state.job,
			            date: state.date,
		            }).then((r: any) => {
			            if (r.data) {
                            modal.ok({
                	            item: r.data,
                            });
			            }
		            }).finally(() => state.loader = false);
	            } else {
		            // api.stock.release.patch({
			        //     id: state.id,
			        //     materialId: state.materialId,
			        //     sectionId: state.sectionId !== 0 ? state.sectionId : null,
			        //     measurement: state.measurement,
			        //     count: state.count,
			        //     supercycle: state.supercycle,
			        //     comment: state.comment.length ? state.comment : null,
			        //     employeeId: state.employeeId,
		            // }).then((r: any) => {
			        //     if (r.data) {
                    //         modal.ok({
	                //             material: r.data,
                    //         });
			        //     }
		            // }).finally(() => state.loader = false);
	            }
            }
        }

	    function ok() {
		    modal.ok({
			    asd: 123,
		    });
	    }

        function close() {
            modal.close();
        }

	    function cancel() {
		    modal.cancel({
			    zxc: 123,
		    });
	    }

	    function onLocomotiveSelected(_:any) {
		    state.locomotiveId = _.id;
            state.locomotive = _.name;
	    }

	    function onJobSelected(_:any) {
		    state.jobId = _.id;
            state.ids = _.ids;
            state.job = _.workName;
            state.date = _.date;
	    }

        watch(() => state.locomotiveId, (v) => {
            state.jobId = 0;
            state.date = '';
            state.job = '';
            api.locomotiveRepairs.get(v).then((r: any) => {
                jobs.value = r.data;
            });
        });

        return {
            ok,
            cancel,
            close,
            state,
	        send,
	        isLocomotiveIdError,
	        isJobIdError,
	        onLocomotiveSelected,
	        onJobSelected,
	        locomotives,
	        jobs,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
