<template>
    <table class="table table-bordered mb-4">
        <thead class="sticky-top bg-white">
            <tr class="position-relative border-0">
                <th class="py-2 px-3">
                    Организация
                </th>
                <th class="py-2 px-3">
                    Участок
                </th>
                <th class="py-2 px-3">
                    Должность
                </th>
                <th class="py-2 px-3">
                    ФИО
                </th>
                <th class="py-2 px-3">
                    Режим работы
                </th>
                <th class="py-2 px-3">
                    СКУД
                </th>
                <th class="py-2 px-3" colspan="2">
                    Статус
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(employee, employeeInd) in props.employees" :key="employee.id">
                <td class="py-2 px-3">
                    {{ employee.organizationName }}
                </td>
                <td class="py-2 px-3">
                    {{ employee.employeePlot }}
                </td>
                <td class="py-2 px-3">
                    {{ employee.employeePost }}
                </td>
                <td class="py-2 px-3">
                    {{ employee.employeeName }}
                </td>
                <td class="py-2 px-3">
                    {{ employee.employeeWorkingMode }}
                </td>
                <td class="py-2 px-3">
                    {{ employee.pacsCards }}
                </td>
                <td class="py-2 px-3" style="min-width: 120px;">
                    <select-ui
                        item-key="name"
                        :options="props.statuses"
                        :values="[employee.employeeStatus]"
                        @on-selected="(v: Record<string, string | number>) => props.onChangeStatus(employee, v, employeeInd)" />
                </td>
                <td class="py-2 px-3" style="width:50px">
                    <dropdown-ui>
                        <template #button>
                            <button class="btn btn-sm d-flex justify-content-center align-items-center">
                                <vue-feather size="14px" type="more-vertical" />
                            </button>
                        </template>
                        <template #list>
                            <button-ui
                                v-if="user.isAdmin || user.isKadry || user.isSuperAdmin"
                                class="dropdown-item cursor-pointer"
                                @click="props.onEdit(employee, employeeInd)">
                                Редактировать
                            </button-ui>
                        </template>
                    </dropdown-ui>
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import { userStore } from "@/store/userStore";
import { IEmployee } from "@/@types/IEmployee";

const props = defineProps({
    employees: {
        type: Array<IEmployee>,
        required: true,
    },
    statuses: {
        type: Array<Record<string, number | string>>,
        required: true,
    },
    onEdit: {
        type: Function as PropType<(employee: IEmployee, index: number) => void>,
        required: true,
    },
    onChangeStatus: {
        type: Function as PropType<(employee: IEmployee, v: Record<string, number | string>, index: number) => void>,
        required: true,
    },
});

const { user } = userStore();
</script>

<style scoped lang="scss">

</style>
