<template>
    <modal-ui
        :id="id"
        size="modal-sm"
        title="Изменение данных договора">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
	            <div class="col-12">
		            <form-group-ui
			            invalid-feedback="Укажите номер договора"
			            label="Номер договора"
			            required>
			            <input-ui
				            v-model="state.contractNumber"
				            :is-invalid="isNumberError && state.visibleErrors"
			            />
		            </form-group-ui>
	            </div>
	            <div class="col-12">
		            <form-group-ui
			            invalid-feedback="Выберите дату договора"
			            label="Дата договора"
			            required>
			            <date-picker
				            v-model="state.contractStartsAt"
				            class="inline-block h-full"
				            :is24hr="true"
				            mode="date">
				            <template #default="{ inputValue, togglePopover }">
					            <div class="flex items-center">
						            <input
							            class="form-control"
							            :class="{'is-invalid': isDateError && state.visibleErrors, 'bg-white': true }"as
							            readonly
							            :timezone="'Asia/Dhaka'"
							            :value="inputValue"
							            @blur="togglePopover()"
							            @focus="togglePopover()">
						            <div class="invalid-feedback">
							            Укажите дату
						            </div>
					            </div>
				            </template>
			            </date-picker>
		            </form-group-ui>
	            </div>
                <div class="row">
                    <div class="col-12 text-center">
                        <button-ui
                            button-class="btn-primary">
                            Сохранить
                        </button-ui>
                    </div>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref } from "vue";
import { useModal } from "@/composables/useModal";
import { DatePicker } from 'v-calendar';
import moment from 'moment-timezone';

export default defineComponent({
    name: "SectionJobContractNumberModal",
    components: {
        DatePicker,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
	    const modal = useModal(props.id);
	    const supplier = ref([]);

        const state = reactive({
	        contractNumber: '',
	        contractStartsAt: '',
            visibleErrors: false,
	        vat: false,
            loader: false,
        });

        const isNumberError = computed(() => state.contractNumber.length === 0);
	    const isDateError = computed(() => state.contractStartsAt.length === 0);

        onMounted(() => {
            if (modal.data.hasOwnProperty('sectionJob')) {
				state.contractStartsAt = modal.data.sectionJob.contractStartsAt || '';
                state.contractNumber = modal.data.sectionJob.contractNumber || '';
            }
        });

        function send() {
            if (isNumberError.value || isDateError.value) {
                state.visibleErrors = true;
            } else {
                state.visibleErrors = false;
                state.loader = true;
                modal.ok({
                    contractNumber: state.contractNumber,
                    contractStartsAt: state.contractStartsAt,
                });
            }
        }

        function close() {
	        modal.cancel({
		        zxc: 123,
	        });
        }

	    function cancel() {
		    modal.cancel({
			    zxc: 123,
		    });
	    }

        return {
	        modal,
            cancel,
            close,
            state,
	        send,
	        isNumberError,
	        isDateError,
	        supplier,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
