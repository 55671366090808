<template>
    <modal-ui :id="id" size="modal-xl" title="Техническое состояние">
      <form class="loader" @submit.prevent="send">
        <loader-ui :is-show="state.loader" />
        <div class="row my-2">
          <div class="col-3 border fw-bold">Наименование</div>
          <div class="col-2 border fw-bold">Состояние</div>
          <div class="col-2 border fw-bold">Запчасти (сумма, тг)</div>
          <div class="col-2 border fw-bold">Услуги (сумма, тг)</div>
          <div class="col-3 border fw-bold">Стоимость</div>
        </div>
        <div
          v-for="(datasheet, idx) in state.datasheets"
          class="row my-2"
          :key="idx"
        >
          <div class="col-3 border d-flex align-items-center">
            <form-group-ui invalid-feedback="Укажите наименование" required>
              <input-ui
                class="mt-2"
                v-model="datasheet.name"
                :is-invalid="state.visibleErrors && isNameError(datasheet)"
              />
            </form-group-ui>
          </div>
          <div class="col-2 border">
            <form-group-ui
                invalid-feedback="Укажите состояние" required>
                <select-ui
                    :is-invalid="state.visibleErrors && isStateError"
                    :options="DATASHEET_STATE_COLLECTION.map(c => ({ name: c, id: c }))"
                    placeholder="Состояние"
                    :values="[datasheet.state]"
                    @on-updated="(s: any) => handleStateSelect(s, idx)" />
            </form-group-ui>
          </div>
          <div class="col-2 border">
            <form-group-ui invalid-feedback="Укажите ремонт или замену" required>
              <input-ui
                class="mt-2"
                v-model="datasheet.servicesTotal"
                :is-invalid="state.visibleErrors && isNameError(datasheet)"
              />
            </form-group-ui>
          </div>
          <div class="col-2 border">
            <form-group-ui invalid-feedback="Введите сумму запчастей">
              <input-ui
                class="mt-2"
                :mask="{ mask: 'Z*', tokens: { 'Z': { pattern: /[0-9]/ } } }" maxlength="12"
                :is-invalid="state.visibleErrors && isTotalError" placeholder=""
                v-model="datasheet.sparePartsTotal"
              />
            </form-group-ui>
          </div>
          <div class="col-2 border text-end">
            {{ formatMoney(calculateTotal(datasheet)) }}
          </div>
          <div class="col-1" v-if="state.datasheets.length > 1">
            <button class="btn btn-sm d-flex justify-content-center align-items-center" @click="handleDeleteItemFromDatasheet(idx)">
              <vue-feather size="14px" type="x" />
          </button>
          </div>
        </div>
        <div class="row my-2">
          <div class="col-5 border fw-bold">Итого</div>
          <div class="col-2 border fw-bold">{{ formatMoney(totalOfServices) }}</div>
          <div class="col-2 border fw-bold">{{ formatMoney(totalOfSpareParts) }}</div>
          <div class="col-3 border fw-bold">{{ formatMoney(totalOfServices + totalOfSpareParts) }}</div>
        </div>
        <div class="row">
          <div class="col-1">
            <button type="button" class="btn btn-outline-primary d-flex justify-content-center align-items-center" @click="handleAddItemToDatasheet">
                Добавить&nbsp;поле
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <button-ui button-class="btn-primary"> Сохранить </button-ui>
          </div>
        </div>
      </form>
    </modal-ui>
  </template>
  
  <script async setup lang="ts">
  import { onMounted, reactive, computed } from 'vue';
  import { DATASHEET_STATE_COLLECTION } from '@depo/constants/project-register';
  import { useModal } from '@/composables/useModal';
  import formatMoney from '@/utils/formatMoney';
  import api from '@/api';
  
  type constructivePriceType = {
    constructiveId: number;
    constructiveName: string;
    constructiveNumber: string;
    projectRegisterId: number;
    totalWithoutVAT: number;
    workAndServicesPrice: number;
    materialsPrice: number;
  };

  type datasheetType = {
    name: string;
    state: string;
    sparePartsTotal?: string;
    servicesTotal?: string;
    comment?: string;
  };
  
  type stateType = {
    constructivePrices: Array<constructivePriceType>;
    datasheets: Array<datasheetType>;
    visibleErrors: boolean;
    loader: boolean;
  };
  
  const props = defineProps({
    id: {
      type: String,
      default: '',
    },
  });
  
  const modal = useModal(props.id);
  
  const state: stateType = reactive({
    constructivePrices: [],
    datasheets: [{
      name: '',
      state: '',
      sparePartsTotal: '',
      servicesTotal: '',
      comment: '',
    }],
    visibleErrors: false,
    loader: false,
  });

  function handleAddItemToDatasheet() {
    state.datasheets.push({
      name: '',
      state: '',
      sparePartsTotal: '',
      servicesTotal: '',
      comment: '',
    });
  }

  function handleStateSelect(_c: { id: string }, idx: number) {
    state.datasheets[idx].state = _c.id;
}

  function handleDeleteItemFromDatasheet(idx: number) {
    state.datasheets.splice(idx, 1);
  }

  const calculateTotal = computed(() => (datasheet: datasheetType) => {
    return Number(datasheet.sparePartsTotal) + Number(datasheet.servicesTotal)
  });

  const totalOfServices = computed(() => {
    return state.datasheets.reduce((acc, datasheet) => acc + Number(datasheet.servicesTotal), 0);
  });

  const totalOfSpareParts = computed(() => {
    return state.datasheets.reduce((acc, datasheet) => acc + Number(datasheet.sparePartsTotal), 0);
  });

  const isStateError = computed(() => (datasheet: datasheetType) => !datasheet.state);
  const isTotalError = computed(() => (datasheet: datasheetType) => !datasheet.sparePartsTotal || !datasheet.servicesTotal);

  const isNameError = computed(() => (datasheet: datasheetType) => {
    return !datasheet.name;
  })
  
  const errors = computed(() => state.datasheets.some((cp) => isNameError.value(cp)));
  
  onMounted(() => {
    api.projectRegister.getDatasheet(+modal.data.projectRegister.id).then(response => {
      if (response.data?.length) {
        state.datasheets = response.data.map((datasheet: datasheetType) => ({
          name: datasheet.name,
          state: datasheet.state,
          sparePartsTotal: datasheet.sparePartsTotal,
          servicesTotal: datasheet.servicesTotal,
          comment: datasheet.comment,
        }));
      }
    }).catch(console.error);

  
  });
  
  function send() {
    if (errors.value) {
      state.visibleErrors = true;
      return;
    }
    state.visibleErrors = false;
    state.loader = true;
  
    api.projectRegister.saveDatasheet(modal.data.projectRegister.id, { datasheets: state.datasheets })
      .then(() => modal.ok({ isSuccess: true }))
      .finally(() => (state.loader = false));
  }
  </script>
  
  <style scoped lang="scss"></style>
  