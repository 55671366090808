<template>
    <modal-ui
        :id="id"
        size="modal-md"
        :title="state.title">
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th class="px-3 py-2" style="width: 50%">
                        Категория
                    </th>
                    <th class="px-3 py-2" colspan="2">
                        Цена
                    </th>
                </tr>
            </thead>
            <tbody>
                <template v-for="(e, eInd) in exceptions">
                    <tr v-if="e.type === 'edit'">
                        <td class="px-3 py-2">
                            {{ e.contractorCategoryName }}
                        </td>
                        <td class="px-3 py-2">
                            <input-ui
                                v-model="e.price"
                                placeholder="Цена"
                                @input="editHandler(e)" />
                        </td>
                        <td class="px-3 py-2">
                            <button-ui button-class="btn-danger" @click="deleteHandler(e, eInd)">
                                х
                            </button-ui>
                        </td>
                    </tr>
                    <tr v-if="e.type === 'add'">
                        <td class="px-3 py-2">
                            <select-ui
                                item-key="categoryName"
                                :options="categories"
                                placeholder="Название"
                                :searchable="true"
                                :values="[e.contractorCategoryId]"
                                @on-selected="(v) => onCategorySelected(e, v)" />
                        </td>
                        <td class="px-3 py-2">
                            <input-ui
                                v-model="e.price"
                                placeholder="Цена" />
                        </td>
                        <td class="px-3 py-2 d-flex">
                            <button-ui button-class="btn-success" @click="addHandler(e, eInd)">
                                ✓
                            </button-ui>
                            <button-ui button-class="btn-danger ms-3" @click="deleteHandler(e, eInd)">
                                х
                            </button-ui>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
        <div class="row">
            <div class="col-12">
                <button-ui button-class="btn-primary" @click="addNewHandler">
                    Добавить исключение
                </button-ui>
            </div>
        </div>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref, watch } from "vue";
import { initModal, useModal } from "@/composables/useModal";
import api from "@/api";
import { DatePicker } from 'v-calendar';
import moment from 'moment-timezone';
import { insert } from "@/composables/useTransmitter";

export default defineComponent({
    name: "MaintenanceExceptionsModal",
    components: {
        DatePicker,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
	    const modal = useModal(props.id);
        const exceptions = ref([]);
        const categories = ref([]);

        const state = reactive({
            title: '',
            btn: '',
            visibleErrors: false,
            loader: false,
        });

        onMounted(() => {
	        state.title = 'Исключения';
	        state.btn = 'Сохранить';

            api.exceptionsPlan.get(modal.data.data.id).then((r: any) => {
                exceptions.value = r.data.map((i: any) => ({
	                ...i,
	                ...{
                        type: 'edit',
	                },
                }));

	            api.info.get().then((r: any) => {
		            categories.value = [];

		            r.data.contractors.forEach((i: any) => {
			            if (categories.value.findIndex((i2: any) => i.categoryId === i2.categoryId) === -1 && exceptions.value.findIndex((i2: any) => i.categoryId === i2.contractorCategoryId) === -1) {
				            // @ts-ignore
				            categories.value.push({
					            // @ts-ignore
					            categoryId: i.categoryId,
					            // @ts-ignore
					            id: i.categoryId,
					            // @ts-ignore
					            categoryName: i.categoryName,
				            });
			            }
		            });
	            });
            });
        });

        function deleteHandler(e: any, eInd: any) {
            if (e.id === 0) {
                exceptions.value.splice(eInd, 1);
            } else {
                api.exceptionsPlan.delete(e.id).then((r: any) => {
                    exceptions.value.splice(eInd, 1);
                });
            }
        }

        function addNewHandler() {
            // @ts-ignore
            exceptions.value.push({
	            // @ts-ignore
                id: 0,
	            // @ts-ignore
                contractorCategoryName: '',
	            // @ts-ignore
	            contractorCategoryId: 0,
	            // @ts-ignore
                price: '',
	            // @ts-ignore
                type: 'add',
            });
        }

        function addHandler(e: any, dInd: any) {
            api.exceptionsPlan.post({
                normId: modal.data.data.id,
                contractorCategoryId: e.contractorCategoryId,
                price: parseInt(e.price),
            }).then((r: any) => {
                let ind = categories.value.findIndex((i: any) => i.id === e.contractorCategoryId);
	            if (ind !== -1) {
                    categories.value.splice(ind, 1);
	            }
	            exceptions.value = r.data.map((i: any) => ({
		            ...i,
		            ...{
			            type: 'edit',
		            },
	            }));
            });
        }

        function onCategorySelected(e: any, v: any) {
            // @ts-ignore
            e.contractorCategoryName = v.categoryName;
	        // @ts-ignore
            e.contractorCategoryId = v.categoryId;
        }

        function editHandler(e: any) {
            if (parseInt(e.price) >= 0) {
                api.exceptionsPlan.patch({
	                // normId: modal.data.data.id,
	                id: modal.data.data.id,
	                // contractorCategoryId: e.contractorCategoryId,
	                price: parseInt(e.price),
                }).then((r: any) => {
                    console.log(r.data);
                });
            }
        }

        function send() {
            // if (isNormDaysError.value || isPriceError.value) {
            //     state.visibleErrors = true;
            // } else {
            //     state.visibleErrors = false;
            //     state.loader = true;
            //     api.locoJobPriceDefault.patch({
	        //         normDays: state.normDays,
	        //         price: state.price,
	        //         id: modal.data.data.id,
            //     }).then((r: any) => {
	        //         modal.ok({
		    //             normDays: state.normDays,
		    //             price: state.price,
	        //         });
            //     });
	        //     // ok();
            // }
        }

	    function ok() {
		    modal.ok({
			    // normDays: state.normDays,
			    // price: state.price,
		    });
	    }

        function close() {
	        modal.cancel({
		        zxc: 123,
	        });
        }

	    function cancel() {
		    modal.cancel({
			    zxc: 123,
		    });
	    }

        return {
            ok,
            cancel,
            close,
            state,
	        send,
	        exceptions,
	        moment,
	        deleteHandler,
	        addNewHandler,
	        addHandler,
	        onCategorySelected,
	        editHandler,
	        categories,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
