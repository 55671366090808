<template>
    <div :class="place">
        <div class="workshop__locomotive2">
            <Tooltip placement="top">
                <template #title>
                    <div v-html="getTooltip"></div>
                </template>
                <locomotive-vertical-component :locomotive="locomotive" :type="type" />
            </Tooltip>
        </div>
        <div class="outside__locomotive my-3">
            <Tooltip placement="top">
                <template #title>
                    <div v-html="getTooltip"></div>
                </template>
                <locomotive-horizontal-component :locomotive="locomotive" />
            </Tooltip>
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import LocomotiveVerticalComponent from "@/components/LocomotiveVerticalComponent.vue";
import LocomotiveHorizontalComponent from "@/components/LocomotiveHorizontalComponent.vue";
import Popper from "vue3-popper";
import moment from 'moment-timezone';
import { Tooltip } from '@programic/vue3-tooltip';
import formatDate from "@/utils/formatDate";

export default defineComponent({
    name: "LocomotiveComponent",
    components: {
        LocomotiveVerticalComponent,
	    LocomotiveHorizontalComponent,
	    Popper,
	    Tooltip,
    },
    props: {
        place: {
            type: String as () => 'workshop' | 'outside',
            default: 'workshop',
        },
	    type: {
            type: String,
	    },
        locomotive: {
            type: Object,
            default: null,
            // validator: (value: string) => [ 'submit', 'button' ].includes(value),
        },
    },
    setup(props) {
        const getTooltip = computed(() => {
            if (!props.locomotive) return '';

            let q1 = `Техника: ${props.locomotive.locomotiveModel} №${props.locomotive.locomotiveNumber}<br>`;
            let q2 = `Владелец: ${props.locomotive.contractorName}<br>Вид ремонта: ${props.locomotive.jobCategory}<br>`;
            let q3 = props.locomotive.receiptDate ? `Поступление в депо: ${formatDate(props.locomotive.receiptDate)}<br>` : '';
            let q4 = props.locomotive.receiptStartDate ? `Дата начала ремонта: ${formatDate(props.locomotive.receiptStartDate)}<br>` : '';
            let q5 = props.locomotive.receiptEndDate ? `Дата окончания ремонта: ${formatDate(props.locomotive.receiptEndDate)}<br>` : '';
            let q6 = props.locomotive.days ? `Дней в ремонте: ${props.locomotive.days}<br>` : '';
            let q7 = props.locomotive.plansToLeaveAt ? `Планируемая дата выхода: ${formatDate(props.locomotive.plansToLeaveAt)}` : '';

            return [ q1, q2, q3, q4, q5, q6, q7].join('');
        });

        return {
	        getTooltip,
        };
    },
});
</script>

<style scoped lang="scss">
.workshop:not(.ghost) .workshop__locomotive2 {
	display: block;
}
.workshop:not(.ghost) .outside__locomotive {
	display: none;
}

.outside:not(.ghost) .workshop__locomotive2 {
	display: none;
}
.outside:not(.ghost) .outside__locomotive {
	display: block;
}
</style>