<template>
    <div class="col-auto flex-grow-0 d-flex justify-content-between align-items-center">
        <select v-model="state.sectionJobId" class="form-select">
            <option disabled value="0">
                Выберите технику
            </option>
            <option v-for="sectionJob in state.endedSectionJobs" :key="sectionJob.id"
                :value="sectionJob.id">
                {{ sectionJob.name }}
            </option>
        </select>
    </div>
    <div class="mt-3">
        <div class="container-fluid">
            <div class="row mb-2">
                <div class="col-auto d-none d-sm-block flex-grow-1"></div>
                
            </div>
        </div>
        <div class="overflow-hidden">
            <div class="bg-white position-relative overflow-auto mw-100 pb-5" style="max-height: 650px;">
                <table class="table table-bordered" v-if="!!state.constructives.length || !!state.materials.length">
                    <thead class="sticky-top bg-white">
                        <tr class="position-relative border-0">
                            <th class="py-2 px-3">№ п/п</th>
                            <th class="py-2 px-3">Наименование</th>
                            <th class="py-2 px-3">Ед изм</th>
                            <th class="py-2 px-3">Количество</th>
                            <th class="py-2 px-3">Сумма без НДС</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="state.constructives.length" class="bg-light">
                            <td class="py-2 px-3">
                                <b>I</b>
                            </td>
                            <td class="py-2 px-3">Трудозатраты</td>
                            <td class="py-2 px-3"></td>
                            <td class="py-2 px-3 text-end">{{ constructiveTotals.hoursWorked }}</td>
                            <td class="py-2 px-3 text-end">{{ formatMoney(constructiveTotals.total, 2, '.', '\xa0') }}</td>
                        </tr>
                        <template v-for="constructive in state.constructives" :key="constructive.name">
                            <tr>
                                <td class="py-2 px-3 fw-bold">
                                    {{ constructive.number }}.
                                </td>
                                <td class="py-2 px-3">{{ constructive.name }}</td>
                                <td class="py-2 px-3">Чел/час</td>
                                <td class="py-2 px-3 text-end">{{ constructive.hoursWorked }}</td>
                                <td class="py-2 px-3 text-end">
                                    {{ constructive.total
                                        ? formatMoney(+constructive.total, 2, '.', '\xa0')
                                        : '0'
                                    }}
                                </td>
                            </tr>
                        </template>
                        <tr v-if="state.materials.length" class="bg-light">
                            <td class="py-2 px-3">
                                <b>II</b>
                            </td>
                            <td class="py-2 px-3">Материалы</td>
                            <td class="py-2 px-3"></td>
                            <td class="py-2 px-3 text-end"></td>
                            <td class="py-2 px-3 text-end">{{ formatMoney(materialTotals.total, 2, '.', '\xa0') }}</td>
                        </tr>
                        <template v-for="material in state.materials" :key="material.id">
                            <tr>
                                <td class="py-2 px-3"></td>
                                <td class="py-2 px-3">{{ material.name }}</td>
                                <td class="py-2 px-3">{{ material.unit }}</td>
                                <td class="py-2 px-3 text-end">{{ material.count }}</td>
                                <td class="py-2 px-3 text-end">
                                    {{ material.total
                                        ? formatMoney(+material.total, 2, '.', '\xa0')
                                        : '0'
                                    }}
                                </td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script lang="ts" async setup>

import api from "@/api";
import { reactive, computed, watch } from "vue";
import formatMoney from "@/utils/formatMoney";
import { ISectionJob } from "@/@types/ISectionJob";

type constructiveReportType = {
    name: string;
    number: string;
    hoursWorked: number;
    total: number;
};

type materialType = {
    price: number,
    unit: string,
    count: number,
    total: number,
    name: string,
    id: number,
};

type reportStateType = {
    endedSectionJobs: ISectionJob[];
    sectionJobId: number;
    constructives: constructiveReportType[];
    materials: materialType[];
};
const state: reportStateType = reactive({
    endedSectionJobs: [],
    sectionJobId: 0,
    constructives: [],
    materials: [],
});

api.sectionJob.getEndedSectionJobsList().then(r => {
    state.endedSectionJobs = r.data;
});

watch(() => [state.sectionJobId], (v: any) => {
    if (!state.sectionJobId) {
        return;
    }
    api.sectionJob.getCalculationReport(state.sectionJobId).then(r => {
        state.constructives = r.data.constructives;
        state.materials = r.data.materials;
    })
})

const constructiveTotals = computed(() => {
    return state.constructives.reduce((acc, constructive) => {
        acc.hoursWorked += constructive.hoursWorked;
        acc.total += constructive.total;
        return acc;
    }, {
        hoursWorked: 0,
        total: 0,
    });
});

const materialTotals = computed(() => {
    return state.materials.reduce((acc, material) => {
        acc.count += Number(material.count);
        acc.total += Number(material.total);
        return acc;
    }, {
        count: 0,
        total: 0,
    });
});

</script>

<style scoped lang="scss"></style>
