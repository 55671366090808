<template>
    <modal-ui
        :id="id"
        size="modal-sm"
        title="Добавление техники">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-12">
                    <form-group-ui
                        invalid-feedback="Выберите локомотив"
                        label="Локомотив"
                        required>
                        <select-ui
                            :is-invalid="isLocoIdError && state.visibleErrors"
                            item-key="model"
                            :options="locoModels"
                            :searchable="true"
                            :values="[state.locoModelId]"
                            @on-selected="onLocoModelSelected" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui
                        invalid-feedback="Выберите вид ремонта"
                        label="Вид ремонта"
                        required>
                        <select-ui
                            :is-invalid="isJobCategoryIdError && state.visibleErrors"
                            item-key="name"
                            :options="getJobCategories"
                            :searchable="true"
                            :values="[state.jobCategoryId]"
                            @on-selected="onJobCategorySelected" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui
                        invalid-feedback="Укажите количество дней"
                        label="Количество дней"
                        required>
                        <input-ui
                            v-model="state.days"
                            :is-invalid="isDaysError && state.visibleErrors"
                            step="0.1"
                            type="number" />
                    </form-group-ui>
                </div>
                <div class="row">
                    <div class="col-12 text-center">
                        <button-ui
                            button-class="btn-primary">
                            Добавить
                        </button-ui>
                    </div>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref, watch } from "vue";
import { initModal, useModal } from "@/composables/useModal";
import api from "@/api";
import { DatePicker } from 'v-calendar';
import moment from 'moment-timezone';
import { insert } from "@/composables/useTransmitter";

export default defineComponent({
    name: "AddSectionModal",
    components: {
        DatePicker,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
	    const modal = useModal(props.id);
        const locoModels = ref([]);
        const jobCategories = ref([]);

        const getJobCategories = computed(() => {
            return jobCategories.value.filter((i: any) => {
                let ind = modal.data.list.findIndex((i2: any) => i2.id !== state.id && i2.modelId === state.locoModelId && i2.jobId === i.id);
                return ind === -1;
            });
        });

        const state = reactive({
	        id: 0,
	        locoModelId: 0,
	        jobCategoryId: 0,
	        days: 0,
            visibleErrors: false,
            loader: false,
        });

        const isLocoIdError = computed(() => state.locoModelId === 0);
	    const isJobCategoryIdError = computed(() => state.jobCategoryId === 0);
	    const isDaysError = computed(() => state.days === 0);

        onMounted(() => {
	        api.info.get().then((r: any) => {
                locoModels.value = r.data.locoModels;
		        jobCategories.value = r.data.jobCategories;
		        // contractors.value = r.data.contractors;
	        });

            if (modal.data.hasOwnProperty('item')) {
                state.id = modal.data.item.id;
                state.locoModelId = modal.data.item.modelId;
                state.jobCategoryId = modal.data.item.jobId;
                state.days = modal.data.item.days;
            }
        });

        watch(() => state.locoModelId, () => {
            state.jobCategoryId = 0;
        });

        function send() {
            if (isLocoIdError.value || isJobCategoryIdError.value || isDaysError.value) {
                state.visibleErrors = true;
            } else {
                state.visibleErrors = false;
                state.loader = true;

	            if (!modal.data.hasOwnProperty('item')) {
		            api.rate.create({
			            locoModelId: state.locoModelId,
			            jobCategoryId: state.jobCategoryId,
			            days: state.days,
		            }).then(r => {
			            modal.ok({
				            type: 'add',
			                item: r.data,
			            });
		            });
	            } else {
		            api.rate.update({
			            id: state.id,
			            locoModelId: state.locoModelId,
			            jobCategoryId: state.jobCategoryId,
			            days: state.days,
		            }).then(r => {
			            modal.ok({
				            type: 'edit',
				            item: r.data,
			            });
		            });
	            }
            }
        }

	    function ok(loco: any) {
		    modal.ok({
			    loco: loco,
		    });
	    }

        function close() {
	        modal.cancel({
		        zxc: 123,
	        });
        }

	    function cancel() {
		    modal.cancel({
			    zxc: 123,
		    });
	    }

	    function onLocoModelSelected(i: any) {
		    state.locoModelId = i.id;
	    }

	    function onJobCategorySelected(i: any) {
		    state.jobCategoryId = i.id;
	    }

        return {
	        onLocoModelSelected,
	        onJobCategorySelected,
            ok,
	        modal,
            cancel,
            close,
            state,
	        send,
	        locoModels,
	        jobCategories,
	        isLocoIdError,
	        isJobCategoryIdError,
	        isDaysError,
	        getJobCategories,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
