import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row mb-4" }
const _hoisted_2 = { class: "col-12 col-md-3" }
const _hoisted_3 = { class: "col-12 col-md-3" }
const _hoisted_4 = { class: "col-12 col-md-3" }
const _hoisted_5 = { class: "col-12 col-md-3" }
const _hoisted_6 = { class: "col-12 col-md-3" }
const _hoisted_7 = { class: "col-12 col-md-3" }
const _hoisted_8 = { class: "col-12 col-md-3 d-flex align-items-center" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col-12 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader_ui = _resolveComponent("loader-ui")!
  const _component_input_ui = _resolveComponent("input-ui")!
  const _component_form_group_ui = _resolveComponent("form-group-ui")!
  const _component_select_ui = _resolveComponent("select-ui")!
  const _component_button_ui = _resolveComponent("button-ui")!
  const _component_modal_ui = _resolveComponent("modal-ui")!

  return (_openBlock(), _createBlock(_component_modal_ui, {
    id: _ctx.id,
    size: "modal-xl",
    title: "Добавление материала"
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        class: "loader",
        onSubmit: _cache[5] || (_cache[5] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.send && _ctx.send(...args)), ["prevent"]))
      }, [
        _createVNode(_component_loader_ui, {
          "is-show": _ctx.state.loader
        }, null, 8, ["is-show"]),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": "Укажите наименование ТМЦ",
              label: "Наименование ТМЦ",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_input_ui, {
                  placeholder: "Наименование ТМЦ",
                  modelValue: _ctx.state.originalName,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.originalName) = $event)),
                  "is-invalid": _ctx.isNameError && _ctx.state.visibleErrors
                }, null, 8, ["modelValue", "is-invalid"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": "Укажите единицу измерения",
              label: "Единица измерения",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_input_ui, {
                  modelValue: _ctx.state.originalUnitDimension,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.originalUnitDimension) = $event)),
                  placeholder: "Единица измерения",
                  "is-invalid": _ctx.isUnitError && _ctx.state.visibleErrors
                }, null, 8, ["modelValue", "is-invalid"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": "Укажите количество ТМЦ",
              label: "Количество ТМЦ",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_input_ui, {
                  type: "number",
                  step: "any",
                  modelValue: _ctx.state.originalCount,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.originalCount) = $event)),
                  placeholder: "Количество ТМЦ",
                  "is-invalid": _ctx.isCountInvError && _ctx.state.visibleErrors
                }, null, 8, ["modelValue", "is-invalid"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": "Укажите цену",
              label: "Цена за единицу",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_input_ui, {
                  modelValue: _ctx.state.originalUnitPrice,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.originalUnitPrice) = $event)),
                  step: "any",
                  type: "number",
                  placeholder: "Цена за единицу",
                  "is-invalid": _ctx.isSumInvError && _ctx.state.visibleErrors
                }, null, 8, ["modelValue", "is-invalid"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": "Выберите материал",
              label: "Материал",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_select_ui, {
                  "is-invalid": _ctx.state.visibleErrors && _ctx.isApmIdError,
                  "item-key": "name",
                  options: _ctx.materials,
                  searchable: true,
                  values: [_ctx.state.materialId],
                  onOnUpdated: _ctx.onMaterialSelected
                }, null, 8, ["is-invalid", "options", "values", "onOnUpdated"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": "Укажите количество",
              label: "Количество",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_input_ui, {
                  modelValue: _ctx.state.actualCount,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.actualCount) = $event)),
                  type: "number",
                  step: "any",
                  placeholder: "Количество",
                  "is-invalid": _ctx.isCountError && _ctx.state.visibleErrors
                }, null, 8, ["modelValue", "is-invalid"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("span", null, "Ед измерения материала: " + _toDisplayString(_ctx.selectedMaterial?.unit || ''), 1)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_button_ui, { "button-class": "btn-primary" }, {
                default: _withCtx(() => [
                  _createTextVNode(" Сохранить ")
                ]),
                _: 1
              })
            ])
          ])
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["id"]))
}