<template>
    <div class="alert alert-warning" :class="getClasses">
        <div class="alert-message text-center">
            {{ getText }}
        </div>
    </div>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import t from "@/utils/t";

export default defineComponent({
    name: "NoDataUi",
    props: {
        text: {
            type: String,
            default: 'noData',
        },
	    classes: {
            type: String,
		    default: 'm-0',
	    },
    },
    setup(props) {
        const getText = computed(() => props.text ? t(props.text) : '');
        const getClasses = computed(() => props.classes ? props.classes : '');

        return {
            getText,
	        getClasses,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
