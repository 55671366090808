<template>
    <div class="mb-3">
        <label v-if="label" class="form-label">
            {{ getLabel }}
            <span v-if="required" class="text-danger">*</span>
        </label>
        <slot></slot>
        <div v-if="invalidFeedback" class="invalid-feedback">
            {{ getInvalidFeedback }}
        </div>
        <div v-if="validFeedback" class="valid-feedback">
            {{ getValidFeedback }}
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import t from '@/utils/t';

export default defineComponent({
    name: "FormGroupUi",
    props: {
        label: {
            type: String,
            default: null,
        },
        required: {
            type: Boolean,
            default: false,
        },
        invalidFeedback: {
            type: String,
            default: null,
        },
        validFeedback: {
            type: String,
            default: null,
        },
    },
    setup(props) {
        const getLabel = computed(() => props.label ? t(props.label) : '');
        const getInvalidFeedback = computed(() => props.invalidFeedback ? t(props.invalidFeedback) : '');
        const getValidFeedback = computed(() => props.validFeedback ? t(props.validFeedback) : '');

        return {
            getLabel, getInvalidFeedback, getValidFeedback,
        };
    },
    components: {

    },
});
</script>

<style scoped>

</style>
