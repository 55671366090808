<template>
    <teleport to="body">
        <div v-if="modal.loader" class="position-fixed top-0 bottom-0 w-100 loader">
            <loader-ui :is-show="true" />
        </div>
        <transition name="fade">
            <component
                :is="initialComponent"
                v-if="modal.isShow"
                :id="modalName"
                ref="handle" />
        </transition>
    </teleport>
</template>

<script lang="ts">
import { defineComponent, defineAsyncComponent, computed } from "vue";
import { initModal, modals } from '@/composables/useModal';
import toCamelCase from "@/utils/toCamelCase";

export default defineComponent({
    name: "ModalWrapper",
    props: {
        modalName: {
            type: String,
            default: null,
        },
    },
    setup(props) {
	    const initialComponent: any = defineAsyncComponent(() => {
		    let name = toCamelCase(props.modalName);
		    return import('./../modals/' + name.charAt(0).toUpperCase() + name.slice(1) + '.vue');
	    });

        const { handle, modal } = initModal(props.modalName);

        return {
            initialComponent,
            modals,
            modal,
            handle,
        };
    },
});
</script>

<style scoped lang="scss">
.loader {
    z-index: 1100;
}
</style>
