<template>
    <modal-ui :id="id" size="modal-lg" :title="state.title">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row">
                <div class="col-12 row">
                    <div class="col-12 col-md-4">
                        <form-group-ui invalid-feedback="Выберите дату тендера" label="Дата тендера" required>
                            <date-picker v-model="state.tenderStartsAt" class="inline-block h-full" :is24hr="true"
                                mode="date">
                                <template #default="{ inputValue, togglePopover }">
                                    <div class="flex items-center">
                                        <input class="form-control"
                                            :class="{ 'is-invalid': isTenderStartsAtError && state.visibleErrors, 'bg-white': true }"
                                            as readonly :timezone="'Asia/Almaty'" :value="inputValue"
                                            @blur="togglePopover()" @focus="togglePopover()">
                                        <div class="invalid-feedback">
                                            Укажите дату тендера
                                        </div>
                                    </div>
                                </template>
                            </date-picker>
                        </form-group-ui>
                    </div>
                    <div class="col-12 col-md-4">
                        <form-group-ui
                            invalid-feedback="Укажите портал" label="Портал">
                            <select-ui
                                :is-invalid="state.visibleErrors && isPortalError"
                                :options="PORTAL_COLLECTION.map(c => ({ name: c.URL, id: c.URL }))"
                                placeholder="Портал"
                                :values="[state.portal]"
                                @on-updated="handleCategoryTypesSelect" />
                        </form-group-ui>
                    </div>
                    <div class="col-12 col-md-4">
                        <form-group-ui invalid-feedback="Выберите дату заезда" label="Дата заезда" required>
                            <date-picker v-model="state.entersAt" class="inline-block h-full" :is24hr="true"
                                mode="date">
                                <template #default="{ inputValue, togglePopover }">
                                    <div class="flex items-center">
                                        <input class="form-control"
                                            :class="{ 'is-invalid': isEntersAtError && state.visibleErrors, 'bg-white': true }"
                                            as readonly :timezone="'Asia/Almaty'" :value="inputValue"
                                            @blur="togglePopover()" @focus="togglePopover()">
                                        <div class="invalid-feedback">
                                            Укажите дату заезда
                                        </div>
                                    </div>
                                </template>
                            </date-picker>
                        </form-group-ui>
                    </div>
                </div>
                <div class="col-12 row">
                    <div class="col-12 h6">
                        <hr />
                        Проект (лот)
                    </div>
                    <div class="col-12 col-md-3">
                        <form-group-ui invalid-feedback="Выберите клиента" label="Клиент" required>
                            <select-ui :is-invalid="state.visibleErrors && isContractorIdError" item-key="name"
                                :options="contractors" placeholder="Клиент" :searchable="true"
                                :values="[state.contractorId]" @on-selected="onContractorSelected" />
                        </form-group-ui>
                    </div>
                    <div class="col-12 col-md-3">
                        <form-group-ui invalid-feedback="Выберите ПС" label="ПС" required>
                            <select-ui :is-invalid="state.visibleErrors && isLocoModelIdError" item-key="model"
                                :options="locoModels" placeholder="ПС" :searchable="true"
                                :values="[state.locomotiveModelId]" @on-selected="onLocoModelSelected" />
                        </form-group-ui>
                    </div>
                    <div class="col-12 col-md-3">
                        <form-group-ui label="Гос.номер">
                            <select-ui item-key="name" :options="filteredLocomotives" placeholder="Гос.номер"
                                :searchable="true" :values="[state.locomotiveId]" @on-selected="onLocomotiveSelected">
                            </select-ui>
                        </form-group-ui>
                    </div>
                    <div class="col-12 col-md-3">
                        <form-group-ui invalid-feedback="Выберите вид работ" label="Вид работ" required>
                            <select-ui :is-invalid="state.visibleErrors && isJobCategoryIdError" item-key="name"
                                :options="jobCategories" placeholder="Вид работ" :searchable="true"
                                :values="[state.jobCategoryId]" @on-selected="onJobCategorySelected" />
                        </form-group-ui>
                    </div>
                </div>
                <div class="col-12 col-md-8 row">
                    <div class="col-12 h6">
                        <hr />
                        Стоимость
                    </div>
                    <div class="col-12 col-md-8">
                        <form-group-ui invalid-feedback="Введите стоимость" label="Стоимость без НДС (тенге)">
                            <input-ui v-model="state.totalWithoutVAT"
                                :mask="{ mask: 'Z*', tokens: { 'Z': { pattern: /^[0-9.,]+$/ } } }" maxlength="12"
                                :is-invalid="state.visibleErrors && isTotalError" placeholder="" />
                        </form-group-ui>
                    </div>
                    <div class="col-12 col-md-4 d-flex align-items-center">
                        <label class="form-check form-check-inline">
                            <input
                                v-model="state.isVATIncluded"
                                class="form-check-input"
                                type="checkbox">
                            <span class="form-check-label">НДС</span>
                        </label>
                    </div>
                </div>
                <div class="col-12 col-md-4 row">
                    <div class="col-12 h6">
                        <hr />
                        Условия участия
                    </div>
                    <div class="col-12">
                        <form-group-ui invalid-feedback="Введите ОИД" label="ОИД (%)">
                            <input-ui v-model="state.oidPercent" :mask="{ mask: 'Z*', tokens: { 'Z': { pattern: /^[0-9.,]+$/ } } }"
                                maxlength="3" :is-invalid="state.visibleErrors && isOidError" placeholder="" />
                        </form-group-ui>
                    </div>
                    <div class="col-12">
                        <form-group-ui invalid-feedback="Введите Тендерное обеспечение" label="Тендерное обеспечение (%)">
                            <input-ui v-model="state.tenderSecurityPercent" :mask="{ mask: 'Z*', tokens: { 'Z': { pattern: /^[0-9.,]+$/ } } }"
                                maxlength="3" :is-invalid="state.visibleErrors && isTenderSecurityError" placeholder="" />
                        </form-group-ui>
                    </div>
                </div>
                <div class="col-12">
                    <form-group-ui label="Квалификационные требования">
                        <textarea v-model="qualificationRequirements" ref="qualificationRequirementRef"></textarea>
                    </form-group-ui>
                </div>
                <div class="col-12 row">
                    <div class="col-12 h6">
                        <hr />
                        Условия контракта
                    </div>
                    <div class="col-12 col-md-4">
                        <form-group-ui invalid-feedback="Введите аванс" label="Аванс (%)">
                            <input-ui v-model="state.advancePercent" :mask="{ mask: 'Z*', tokens: { 'Z': { pattern: /^[0-9.,]+$/ } } }"
                                maxlength="3" :is-invalid="state.visibleErrors && isAdvanceError" placeholder="" />
                        </form-group-ui>
                    </div>
                    <div class="col-12 col-md-4">
                        <form-group-ui invalid-feedback="Введите дни" label="Оплата после сдачи АВР (дни)">
                            <input-ui v-model="state.daysToGetPaidAfterActOfWorkPerformedSubmit"
                                :mask="{ mask: 'Z*', tokens: { 'Z': { pattern: /^[0-9.,]+$/ } } }" maxlength="12"
                                :is-invalid="state.visibleErrors && isDaysToGetPaidAfterActOfWorkPerformedSubmitError"
                                placeholder="" />
                        </form-group-ui>
                    </div>
                    <div class="col-12 col-md-4">
                        <form-group-ui invalid-feedback="Введите срок ремонта" label="Срок ремонта (дни)">
                            <input-ui v-model="state.repairPeriodDays"
                                :mask="{ mask: 'Z*', tokens: { 'Z': { pattern: /^[0-9.,]+$/ } } }" maxlength="12"
                                :is-invalid="state.visibleErrors && isRepairPeriodError" placeholder="" />
                        </form-group-ui>
                    </div>
                    <div class="col-12 col-md-6">
                        <form-group-ui label="Полная оплата">
                            <input-ui v-model="state.fullPayment" placeholder="" />
                        </form-group-ui>
                    </div>
                    <div class="col-12 col-md-6">
                        <form-group-ui invalid-feedback="Введите проценты" label="Пеня за простой (%)">
                            <input-ui v-model.lazy="state.demuragePenalty"
                                :mask="{ mask: 'Z*', tokens: { 'Z': { pattern: /^[0-9.,]+$/ } } }" maxlength="12" @input="onDemuragePenaltyEnter"
                                :is-invalid="state.visibleErrors && isDemuragePenaltyError" placeholder="" />
                        </form-group-ui>
                    </div>
                    <div class="col-12 col-md-4 d-flex align-items-center">
                        <label class="form-check form-check-inline">
                            <input
                                v-model="state.isOvercyclePaid"
                                class="form-check-input"
                                type="checkbox">
                            <span class="form-check-label">Оплата сверхцикла</span>
                        </label>
                    </div>
                    <div class="col-12 col-md-4 d-flex align-items-center">
                        <label class="form-check form-check-inline">
                            <input
                                v-model="state.isAdditionalEquipmentInstalled"
                                class="form-check-input"
                                type="checkbox">
                            <span class="form-check-label">Установка доп. оборудование</span>
                        </label>
                    </div>
                    <div class="col-12 col-md-4 d-flex align-items-center">
                        <label class="form-check form-check-inline">
                            <input
                                v-model="state.isSpecialConditions"
                                class="form-check-input"
                                type="checkbox">
                            <span class="form-check-label">Спец.условия</span>
                        </label>
                    </div>
                </div>
                <div class="col-12">
                    <form-group-ui invalid-feedback="Укажите примечание" label="Примечание">
                        <input-ui v-model="state.comment" placeholder="Примечание" />
                    </form-group-ui>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button-ui button-class="btn-primary">
                        Сохранить
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script async setup lang="ts">
import { onMounted, reactive, computed, ref } from "vue";
import { DatePicker } from 'v-calendar';
import { useTextareaAutosize } from '@vueuse/core';
import { PORTAL_COLLECTION } from '@depo/constants/project-register';
import { useModal } from "@/composables/useModal";
import api from "@/api";
import { ILocomotive } from "@/@types/ILocomotive";

const props = defineProps({
    id: {
        type: String,
        default: '',
    },
});

const modal = useModal(props.id);

const state = reactive({
    title: '',
    tenderStartsAt: new Date(),
    contractorId: '',
    locomotiveModelId: '',
    locomotiveId: '',
    jobCategoryId: '',
    totalWithoutVAT: '',
    isVATIncluded: false,
    isOvercyclePaid: false,
    isAdditionalEquipmentInstalled: false,
    isSpecialConditions: false,
    oidPercent: '',
    advancePercent: '',
    repairPeriodDays: '',
    daysToGetPaidAfterActOfWorkPerformedSubmit: '',
    fullPayment: '',
    demuragePenalty: '',
    tenderSecurityPercent: '',
    portal: '',
    entersAt: '',
    comment: '',
    visibleErrors: false,
    loader: false,
});

const contractors = ref([]);
const jobCategories = ref([]);
const locoModels = ref([]);
const locomotives = ref<Array<ILocomotive>>([]);
const { input: qualificationRequirements, textarea: qualificationRequirementRef } = useTextareaAutosize();

const isTenderStartsAtError = computed(() => !state.tenderStartsAt);
const isEntersAtError = computed(() => !state.entersAt);
const isContractorIdError = computed(() => !state.contractorId);
const isLocoModelIdError = computed(() => !state.locomotiveModelId);
const isJobCategoryIdError = computed(() => !state.jobCategoryId);
const isTotalError = computed(() => !state.totalWithoutVAT.length ? false : /^[0-9]{12}$/.test(state.totalWithoutVAT));
const isOidError = computed(() => !state.oidPercent.length ? false : (+state.oidPercent > 100 || +state.oidPercent < 0));
const isTenderSecurityError = computed(() => !state.tenderSecurityPercent.length ? false : (+state.tenderSecurityPercent > 100 || +state.tenderSecurityPercent < 0));
const isAdvanceError = computed(() => !state.advancePercent.length ? false : (+state.advancePercent > 100 || +state.advancePercent < 0));
const isDemuragePenaltyError = computed(() => !state.demuragePenalty.length ? false : (+state.demuragePenalty > 100 || +state.demuragePenalty < 0));
const isRepairPeriodError = computed(() => !state.repairPeriodDays.length ? false : /^[0-9]{12}$/.test(state.repairPeriodDays));
const isDaysToGetPaidAfterActOfWorkPerformedSubmitError = computed(() => !state.daysToGetPaidAfterActOfWorkPerformedSubmit.length ? false : /^[0-9]{12}$/.test(state.daysToGetPaidAfterActOfWorkPerformedSubmit));
const isPortalError = computed(() => !state.portal.length);

const filteredLocomotives = computed(() => {
    if (isLocoModelIdError.value || isContractorIdError.value) {
        return [];
    }

    return locomotives.value.filter(locomotive => locomotive.contractorId === Number(state.contractorId) && locomotive.modelId === Number(state.locomotiveModelId));
});

function onDemuragePenaltyEnter() {
    state.demuragePenalty = state.demuragePenalty.replaceAll(',', '.');
}

function onContractorSelected(contractor: any) {
    state.contractorId = contractor.id;
    state.locomotiveId = '';
}

function onLocoModelSelected(locomotive: any) {
    state.locomotiveModelId = locomotive.id;
    state.locomotiveId = '';
}

function onLocomotiveSelected(locomotive: any) {
    state.locomotiveId = locomotive.id;
}

function onJobCategorySelected(category: any) {
    state.jobCategoryId = category.id;
}

function handleCategoryTypesSelect(_c: any) {
    state.portal = _c.id;
}

const errors = computed(() => {
    return isTenderStartsAtError.value ||
        isContractorIdError.value ||
        isLocoModelIdError.value ||
        isJobCategoryIdError.value ||
        isTotalError.value ||
        isOidError.value ||
        isTenderSecurityError.value ||
        isAdvanceError.value ||
        isRepairPeriodError.value ||
        isDaysToGetPaidAfterActOfWorkPerformedSubmitError.value ||
        isPortalError.value ||
        isEntersAtError.value;
});

onMounted(() => {
    if (modal.data.type === 'add') {
        state.title = 'Создание реестра проектов';
    } else {
        state.title = 'Редактирование реестра проектов';
        state.tenderStartsAt = modal.data.projectRegister.tenderStartsAt;
        state.contractorId = modal.data.projectRegister.contractorId;
        state.locomotiveModelId = modal.data.projectRegister.locomotiveModelId;
        state.locomotiveId = modal.data.projectRegister.locomotiveId;
        state.jobCategoryId = modal.data.projectRegister.jobCategoryId;
        state.totalWithoutVAT = modal.data.projectRegister.totalWithoutVAT;
        state.oidPercent = modal.data.projectRegister.oidPercent;
        state.advancePercent = modal.data.projectRegister.advancePercent;
        state.repairPeriodDays = modal.data.projectRegister.repairPeriodDays;
        state.daysToGetPaidAfterActOfWorkPerformedSubmit = modal.data.projectRegister.daysToGetPaidAfterActOfWorkPerformedSubmit;
        state.comment = modal.data.projectRegister.comment;
        state.isVATIncluded = modal.data.projectRegister.isVATIncluded;
        state.fullPayment = modal.data.projectRegister.fullPayment;
        state.demuragePenalty = modal.data.projectRegister.demuragePenalty;
        state.portal  = modal.data.projectRegister.portal;
        state.entersAt = modal.data.projectRegister.entersAt;
        state.isAdditionalEquipmentInstalled = modal.data.projectRegister.isAdditionalEquipmentInstalled;
        state.isOvercyclePaid = modal.data.projectRegister.isOvercyclePaid;
        state.isSpecialConditions = modal.data.projectRegister.isSpecialConditions;
        state.tenderSecurityPercent = modal.data.projectRegister.tenderSecurityPercent;
        qualificationRequirements.value = modal.data.projectRegister.qualificationRequirements;
    }

    api.info.get().then((r: any) => {
        contractors.value = r.data.contractors;
        jobCategories.value = r.data.jobCategories;
        locoModels.value = r.data.locoModels;
    });
    api.locomotives.getAll().then(data => {
        locomotives.value = data;
    }).catch(console.error);
});

function send() {
    if (errors.value) {
        state.visibleErrors = true;
        return;
    }
    state.visibleErrors = false;
    state.loader = true;

    const dataToUpdateOrCreate = {
        tenderStartsAt: state.tenderStartsAt,
        contractorId: state.contractorId,
        locomotiveModelId: state.locomotiveModelId,
        locomotiveId: state.locomotiveId || null,
        jobCategoryId: state.jobCategoryId,
        totalWithoutVAT: state.totalWithoutVAT,
        oidPercent: state.oidPercent,
        advancePercent: state.advancePercent,
        repairPeriodDays: state.repairPeriodDays,
        daysToGetPaidAfterActOfWorkPerformedSubmit: state.daysToGetPaidAfterActOfWorkPerformedSubmit,
        comment: state.comment,
        isVATIncluded: state.isVATIncluded,
        fullPayment: state.fullPayment,
        demuragePenalty: state.demuragePenalty,
        entersAt: state.entersAt,
        qualificationRequirements: qualificationRequirements.value,
        portal: state.portal,
        isAdditionalEquipmentInstalled: state.isAdditionalEquipmentInstalled,
        isOvercyclePaid: state.isOvercyclePaid,
        isSpecialConditions: state.isSpecialConditions,
        tenderSecurityPercent: state.tenderSecurityPercent,
    };

    if (modal.data.type === 'add') {
        api.projectRegister.create(dataToUpdateOrCreate).then(() => {
            modal.ok({ isSuccess: true });
        }).finally(() => state.loader = false);
        return;
    }

    api.projectRegister.update(modal.data.projectRegister.id, dataToUpdateOrCreate).then(() => {
        modal.ok({ isSuccess: true });
    }).finally(() => state.loader = false);
}
</script>

<style scoped lang="scss">
    textarea {
    width: 100%;
    min-height: 90px;
    resize: none;
    overflow: hidden;
    }

    textarea:focus {
    outline: none;
    }
</style>
