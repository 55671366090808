<template>
    <div class="mt-3">
        <h3>Отчет по численности персонала по состоянию на {{ today.format('DD.MM.YYYY') }} г.</h3>
        <div class="overflow-hidden">
            <div class="bg-white position-relative overflow-auto mw-100" style="max-height: 650px;">
                <table class="table table-bordered">
                    <thead class="sticky-top bg-white">
                        <tr class="position-relative border-0">
                            <th class="p-1">
                                Подразделение/Участок
                            </th>
                            <template v-for="organization in state.organizations">
                                <th class="p-1">
                                    {{ organization.name }}
                                </th>
                            </template>
                            <th class="p-1">
                                Всего
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="state.subdivisions.length">
                            <template v-for="subdivision in state.subdivisions">
                                <tr class="bg-primary bg-opacity-25">
                                    <td class="p-1">
                                        {{ subdivision.name }}
                                    </td>
                                    <template v-for="organization in state.organizations">
                                        <td class="p-1 text-end">
                                            {{ subdivision.count[organization.name] }}
                                        </td>
                                    </template>
                                    <td class="p-1 text-end">
                                        {{ subdivision.totalCount }}
                                    </td>
                                </tr>
                                <tr v-for="(plot, plotIdx) in subdivision.plots" :key="plotIdx">
                                    <td class="p-1">
                                        {{ plot.name }}
                                    </td>
                                    <template v-for="organization in state.organizations">
                                        <td class="p-1 text-end">
                                            {{ plot.count[organization.name] }}
                                        </td>
                                    </template>
                                    <td class="p-1 text-end">
                                        {{ plot.totalCount }}
                                    </td>
                                </tr>
                            </template>
                            <tr class="bg-success bg-opacity-25 sticky-bottom">
                                <td class="p-1">
                                    Итого
                                </td>
                                <template v-for="organization in state.organizations">
                                    <td class="p-1 text-end">
                                        {{ organization.count }}
                                    </td>
                                </template>
                                <td class="p-1 text-end">
                                    {{ state.totalEmployeeCount }}
                                </td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <td colspan="10" class="p-1 text-center">Записи отсутствуют</td>
                            </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script lang="ts" async setup>

import api from "@/api";
import { reactive } from "vue";
import moment from 'moment-timezone';

type organizationType = {
    id: number;
    name: string;
    count: number;
};

type subdivisionType = {
    name: string;
    count: {
        [name: string]: number;
    };
    totalCount: number;
    plots: subdivisionType[],
};

type reportStateType = {
    totalEmployeeCount: number;
    organizations: organizationType[];
    subdivisions: subdivisionType[];
};
const state: reportStateType = reactive({
    organizations: [],
    subdivisions: [],
    totalEmployeeCount: 0,
});

const today = moment().tz('Asia/Almaty');

api.employees.getHeadCountReport().then((r) => {
    state.organizations = r.data.organizations;
    state.subdivisions = r.data.countBySubdivision;
    state.totalEmployeeCount = r.data.totalEmployeeCount;
});

</script>

<style scoped lang="scss">
.sticky-bottom {
    position: sticky;
    bottom: 0;
    z-index: 2;
}
</style>
