<template>
    <svg
        class="locomotive"
        fill="none"
        height="74"
        viewBox="0 0 180 74"
        width="180"
        xmlns="http://www.w3.org/2000/svg">
        <g v-if="locomotiveType === 'middle'" id="middle">
            <rect
                fill="#E5E5E5"
                height="74"
                width="180" />
            <rect
                fill="white"
                height="1504"
                transform="translate(-1530 -566)"
                width="2086" />
            <rect
                class="locomotive__border"
                :class="{ 'locomotive__border--error': locomotive?.plansToLeaveAt && locomotive.sectionStatus !== 4 ? moment().isAfter(locomotive.plansToLeaveAt) : false }"
                fill="white"
                height="176"
                stroke="#333333"
                stroke-width="4"
                transform="rotate(-90 2 72)"
                width="70"
                x="2"
                y="72" />
            <text
                fill="#333333"
                font-family="Inter"
                font-size="10"
                font-weight="bold"
                letter-spacing="0em"
                style="white-space: pre"
                xml:space="preserve">
                <tspan x="19.4972" y="20.7589">{{ locomotive.sectionNumber }}</tspan>
            </text>
            <text
                fill="#333333"
                font-family="Inter"
                font-size="10"
                font-weight="bold"
                letter-spacing="0em"
                style="white-space: pre"
                xml:space="preserve">
                <tspan x="113.828" y="61.6364">{{ locomotive.locomotiveModel }}</tspan>
            </text>
            <circle
                cx="90"
                cy="37"
                :fill="fill"
                r="13.5"
                stroke="#333333"
                stroke-width="3" />
            <text
                fill="#333333"
                font-family="Inter"
                font-size="14"
                font-weight="bold"
                letter-spacing="0em"
                style="white-space: pre"
                xml:space="preserve">
                <tspan
                    dominant-baseline="middle"
                    text-anchor="middle"
                    x="50%"
                    y="38.6909">
                    {{ locomotive.jobCategoryShort }}
                </tspan>
            </text>
        </g>
        <g v-if="locomotiveType === 'once'" id="once">
            <rect
                class="locomotive__background"
                fill="#333333"
                height="16"
                rx="3"
                transform="rotate(-90 10 70)"
                width="66"
                x="10"
                y="70" />
            <rect
                class="locomotive__border"
                :class="{ 'locomotive__border--error': locomotive?.plansToLeaveAt && locomotive.sectionStatus !== 4 ? moment().isAfter(locomotive.plansToLeaveAt) : false }"
                fill="white"
                height="156"
                rx="13"
                stroke="#333333"
                stroke-width="4"
                transform="rotate(-90 12 72)"
                width="70"
                x="12"
                y="72" />
            <text
                fill="#333333"
                font-family="Inter"
                font-size="10"
                font-weight="bold"
                letter-spacing="0em"
                style="white-space: pre"
                xml:space="preserve">
                <tspan x="28.0138" y="19.7589">{{ locomotive.sectionNumber }}</tspan>
            </text>
            <text
                fill="#333333"
                font-family="Inter"
                font-size="10"
                font-weight="bold"
                letter-spacing="0em"
                style="white-space: pre"
                xml:space="preserve">
                <tspan x="104.828" y="60.6364">{{ locomotive.locomotiveModel }}</tspan>
            </text>
            <circle
                cx="90"
                cy="37"
                :fill="fill"
                r="13.5"
                stroke="#333333"
                stroke-width="3" />
            <text
                fill="#333333"
                font-family="Inter"
                font-size="14"
                font-weight="bold"
                letter-spacing="0em"
                style="white-space: pre"
                xml:space="preserve">
                <tspan
                    dominant-baseline="middle"
                    text-anchor="middle"
                    x="50%"
                    y="39.5909">
                    {{ locomotive.jobCategoryShort }}
                </tspan>
            </text>
        </g>
        <g v-if="locomotiveType === 'start'" id="start">
            <rect
                class="locomotive__background"
                fill="#333333"
                height="16"
                rx="3"
                transform="rotate(-90 10 70)"
                width="66"
                x="10"
                y="70" />
            <path
                class="locomotive__border"
                :class="{ 'locomotive__border--error': locomotive?.plansToLeaveAt && locomotive.sectionStatus !== 4 ? moment().isAfter(locomotive.plansToLeaveAt) : false }"
                d="M12 59L12 15C12 7.8203 17.8203 2 25 2L178 1.99999L178 72L25 72C17.8203 72 12 66.1797 12 59Z"
                fill="white"
                stroke="#333333"
                stroke-width="4" />
            <text
                fill="#333333"
                font-family="Inter"
                font-size="10"
                font-weight="bold"
                letter-spacing="0em"
                style="white-space: pre"
                xml:space="preserve">
                <tspan x="29.0138" y="19.7589">{{ locomotive.sectionNumber }}</tspan>
            </text>
            <text
                fill="#333333"
                font-family="Inter"
                font-size="10"
                font-weight="bold"
                letter-spacing="0em"
                style="white-space: pre"
                xml:space="preserve">
                <tspan x="113.828" y="61.6364">{{ locomotive.locomotiveModel }}</tspan>
            </text>
            <circle
                cx="90"
                cy="37"
                :fill="fill"
                r="13.5"
                stroke="#333333"
                stroke-width="3" />
            <text
                fill="#333333"
                font-family="Inter"
                font-size="14"
                font-weight="bold"
                letter-spacing="0em"
                style="white-space: pre"
                xml:space="preserve">
                <tspan
                    dominant-baseline="middle"
                    text-anchor="middle"
                    x="50%"
                    y="39.5909">
                    {{ locomotive.jobCategoryShort }}
                </tspan>
            </text>
        </g>
        <g v-if="locomotiveType === 'end'" id="end">
            <path
                class="locomotive__border"
                :class="{ 'locomotive__border--error': locomotive?.plansToLeaveAt && locomotive.sectionStatus !== 4 ? moment().isAfter(locomotive.plansToLeaveAt) : false }"
                d="M2 72L2 2L155 1.99999C162.18 1.99999 168 7.82029 168 15L168 59C168 66.1797 162.18 72 155 72L2 72Z"
                fill="white"
                stroke="#333333"
                stroke-width="4" />
            <text
                fill="#333333"
                font-family="Inter"
                font-size="10"
                font-weight="bold"
                letter-spacing="0em"
                style="white-space: pre"
                xml:space="preserve">
                <tspan x="19.0138" y="20.7589">{{ locomotive.sectionNumber }}</tspan>
            </text>
            <text
                fill="#333333"
                font-family="Inter"
                font-size="10"
                font-weight="bold"
                letter-spacing="0em"
                style="white-space: pre"
                xml:space="preserve">
                <tspan x="103.828" y="60.6364">{{ locomotive.locomotiveModel }}</tspan>
            </text>
            <circle
                cx="90"
                cy="37"
                :fill="fill"
                r="13.5"
                stroke="#333333"
                stroke-width="3" />
            <text
                fill="#333333"
                font-family="Inter"
                font-size="14"
                font-weight="bold"
                letter-spacing="0em"
                style="white-space: pre"
                xml:space="preserve">
                <tspan
                    dominant-baseline="middle"
                    text-anchor="middle"
                    x="50%"
                    y="39.5909">
                    {{ locomotive.jobCategoryShort }}
                </tspan>
            </text>
        </g>
        <g
            v-if="locomotiveType === 'add'"
            id="add"
            style="cursor:pointer;">
            <rect
                class="locomotive__border"
                :class="{ 'locomotive__border--error': locomotive?.plansToLeaveAt && locomotive.sectionStatus !== 4 ? moment().isAfter(locomotive.plansToLeaveAt) : false }"
                fill="white"
                height="156"
                rx="13"
                stroke="#333333"
                stroke-dasharray="8 8"
                stroke-width="4"
                transform="rotate(-90 12 72)"
                width="70"
                x="12"
                y="72" />
            <circle
                cx="90"
                cy="37"
                fill="white"
                r="13.5"
                stroke="#333333"
                stroke-width="3"
                transform="rotate(-90 90 37)" />
            <rect
                fill="#333333"
                height="16"
                transform="rotate(-90 82 39)"
                width="4"
                x="82"
                y="39" />
            <rect
                fill="#333333"
                height="16"
                width="4"
                x="88"
                y="29" />
        </g>
    </svg>
</template>

<script lang="ts">
import { computed, defineComponent } from "vue";
import moment from 'moment-timezone';
import { IUnknownObject } from "@/@types/common";

export default defineComponent({
    name: "LocomotiveHorizontalComponent",
    props: {
	    locomotive: {
            type: Object,
            default: null,
            // validator: (value: string) => [ 'submit', 'button' ].includes(value),
        },
    },
    setup(props) {
        const locomotiveType = computed(() => {
            if (props.locomotive === null) return 'add';
	        // if (props.locomotive.id === 0) return 'add';
            else if (props.locomotive.current === 1 && props.locomotive.count === 1) return 'once';
            else if (props.locomotive.current === 1 && [ 2, 3 ].includes(props.locomotive.count)) return 'start';
            else if (props.locomotive.current === 2 && props.locomotive.count === 2) return 'end';
            else if (props.locomotive.current === 2 && props.locomotive.count === 3) return 'middle';
            else if (props.locomotive.current === 3 && [ 2, 3 ].includes(props.locomotive.count)) return 'end';
        });

	    const fill = computed(() => {
		    if (props.locomotive.sectionStatus === 1) return '#adb5bd';
		    if (props.locomotive.sectionStatus === 3) return '#1cbb8c';

		    return '#fff';
	    });

        return {
            locomotiveType,
	        fill,
            moment,
        };
    },
});
</script>

<style scoped>
.locomotive {
	cursor: pointer;
	display: block;
}
.locomotive:hover .locomotive__border {
	stroke: #3b7ddd;
}
.locomotive__border--error {
    stroke: #f7380d;
}
.locomotive:hover .locomotive__background {
	fill: #3b7ddd;
}
</style>