<template>
    <modal-ui :id="id" size="modal-sm" :title="state.title">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-12">
                    <form-group-ui invalid-feedback="Укажите название" label="Название" required>
                        <input-ui v-model="state.name" :is-invalid="state.visibleErrors && isNameError"
                            placeholder="Название" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui invalid-feedback="Подразделение не указано" label="Подразделение">
                        <select class="form-select mt-2" v-model="state.subdivisionId"
                            :class="{ 'is-invalid': state.visibleErrors && isSubdivisionIdError }">
                            <option value="0" disabled>Не выбрано</option>
                            <option v-for="subdivision in subdivisions" :key="subdivision.id" :value="subdivision.id">
                                {{ subdivision.name }}
                            </option>
                        </select>
                    </form-group-ui>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button-ui button-class="btn-primary">
                        Сохранить
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts" async setup>
import { onMounted, reactive, computed, ref } from "vue";
import { useModal } from "@/composables/useModal";
import api from "@/api";
import { ISubdivision } from "@/@types/ISubdivision";

const props = defineProps({
    id: {
        type: String,
        default: '',
    },
});

const modal = useModal(props.id);
const subdivisions = ref<ISubdivision[]>([]);
const state = reactive({
    title: '',
    btn: '',
    name: '',
    subdivisionId: '',
    visibleErrors: false,
    loader: false,
});

const isNameError = computed(() => !state.name.length);
const isSubdivisionIdError = computed(() => !Number(state.subdivisionId));

onMounted((() => {
    api.department.getSubdivisions().then(r => {
        subdivisions.value = r.data;
    });
    if (modal.data.type !== 'add' && modal.data.department) {
        state.title = 'Редактирование подразделения';
        state.subdivisionId = modal.data?.department?.subdivisionId;
        state.name = modal.data?.department?.name;
    } else {
        state.title = 'Создание подразделения';
    }
}));

function send() {
    state.loader = true;
    if (isNameError.value || isSubdivisionIdError.value) {
        state.visibleErrors = true
        return;
    }
    state.visibleErrors = false;
    if (modal.data.type === 'add') {
        api.department.create({
            name: state.name,
            subdivisionId: state.subdivisionId,
        }).then(modal.ok);
        state.loader = false;
        return;
    }
    api.department.update(modal.data.department.id, {
        name: state.name,
        subdivisionId: state.subdivisionId,
    }).then(modal.ok);
    state.loader = false;
}

</script>

<style scoped lang="scss"></style>
