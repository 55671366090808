<template>
    <modal-ui :id="id" size="modal-sm" :title="state.title">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-12">
                    <label class="form-label d-flex">
                        <label class="form-check m-0">
                            <input v-model="state.type" class="form-check-input" name="1" type="radio" :value="1">
                            <span class="form-check-label me-3">Секция</span>
                        </label>
                        <label class="form-check m-0">
                            <input v-model="state.type" class="form-check-input" name="2" type="radio" :value="2">
                            <span class="form-check-label me-3">Участок</span>
                        </label>
                        <span class="text-danger">*</span>
                    </label>
                </div>
                <div class="col-12">
                    <form-group-ui v-if="state.type === 1" invalid-feedback="Укажите технику" label="Техника" required>
                        <select-ui :is-invalid="state.visibleErrors && isSectionIdError" :options="locos"
                            placeholder="Техника" :searchable="true" :values="[state.sectionId]"
                            @on-selected="onSectionSelected">
                        </select-ui>
                    </form-group-ui>
                    <form-group-ui v-if="state.type === 2" invalid-feedback="Укажите участок" label="Участок" required>
                        <select-ui :is-invalid="state.visibleErrors && isDepartmentIdError" :options="departments"
                            placeholder="Участок" :searchable="true" :values="[state.departmentId]"
                            @on-selected="onDepartmentSelected">
                        </select-ui>
                    </form-group-ui>
                </div>
                <div v-if="state.type === 1" class="12">
                    <form-group-ui invalid-feedback="Укажите вид ремонта" label="Вид ремонта">
                        <select-ui :is-invalid="state.visibleErrors && isJobCategoryIdError" :options="jobs"
                            placeholder="Вид ремонта" :searchable="true" :values="[state.jobCategoryId]"
                            @on-selected="onJobCategorySelected" />
                    </form-group-ui>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button-ui button-class="btn-primary">
                        {{ state.btn }}
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref } from "vue";
import { useModal } from "@/composables/useModal";
import api from "@/api";
import { DatePicker } from 'v-calendar';
import moment from "moment-timezone";

export default defineComponent({
    name: "MaterialRequirementModal",
    components: {
        DatePicker,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
        const modal = useModal(props.id);

        const jobs = ref([]);
        const locos = ref([]);
        const departments = ref([]);

        const state = reactive({
            title: '',
            btn: '',
            id: 0,
            type: 1,
            enter: '',
            jobCategoryId: 0,
            sectionId: 0,
            departmentId: 0,
            visibleErrors: false,
            loader: false,
        });

        const isSectionIdError = computed(() => !state.sectionId);
        const isDepartmentIdError = computed(() => !state.departmentId);
        const isJobCategoryIdError = computed(() => !state.jobCategoryId);

        const errors = computed(() => {
            return state.type === 1 ? (isSectionIdError.value || isJobCategoryIdError.value) : isDepartmentIdError.value;
        });

        onMounted(() => {
            if (modal.data.type === 'add') {
                state.title = 'Добавление';
                state.btn = 'Добавить';
            } else {
                state.title = 'Редактирование';
                state.btn = 'Сохранить';
            }

            api.info.get().then(r => {
                jobs.value = r.data.jobCategories;
            });

            api.department.get().then((r: any) => {
                departments.value = r.data;
            });

            api.alllocos.get().then(r => {
                locos.value = r.data.map((loco: any) => ({
                    ...loco,
                    number: loco.name,
                    name: `№${loco.sectionNumber} ${loco.locoModel} - ${loco.contractorName}`,
                    sectionId: loco.id,
                    id: loco.sectionId,
                }));
            });
        });

        function send() {
            if (errors.value) {
                state.visibleErrors = true;
            } else {
                state.visibleErrors = false;
                state.loader = true;
                api.advancePayment.create({
                    sectionId: state.type === 1 ? state.sectionId : null,
                    jobCategoryId: state.type === 1 ? state.jobCategoryId : null,
                    departmentId: state.type === 2 ? state.departmentId : null,
                    enter: moment().toDate(),
                }).then((r: any) => {
                    if (r.data) {
                        modal.ok(r.data);
                    }
                }).finally(() => state.loader = false);
            }
        }

        function onSectionSelected(_m: any) {
            state.sectionId = _m.id;
        }

        function onJobCategorySelected(_c: any) {
            state.jobCategoryId = _c.id;
        }

        function onDepartmentSelected(sj: any) {
            state.departmentId = sj.id;
        }

        function ok() {
            modal.ok({
                asd: 123,
            });
        }

        function close() {
            modal.close();
        }

        function cancel() {
            modal.cancel({
                zxc: 123,
            });
        }

        return {
            ok,
            cancel,
            close,
            state,
            send,
            isSectionIdError,
            isDepartmentIdError,
            isJobCategoryIdError,
            jobs,
            locos,
            departments,
            onSectionSelected,
            onDepartmentSelected,
            onJobCategorySelected,
        };
    },
});
</script>

<style scoped lang="scss"></style>
