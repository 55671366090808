<template>
    <modal-ui :id="id" size="modal-md" title="Создание нового пользователя">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row">
                <div class="col-12">
                    <form-group-ui invalid-feedback="Логин состоит из не менее 5 букв или цифр" label="Логин" required>
                        <input-ui class="mt-2" v-model="state.login" :is-invalid="state.visibleErrors && isLoginError"
                            placeholder="Логин" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui invalid-feedback="Пароль состоит из не менее 5 букв или цифр" label="Пароль"
                        required>
                        <input-ui class="mt-2" v-model="state.password" type="password"
                            :is-invalid="state.visibleErrors && isPasswordError" placeholder="Пароль" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui label="ФИО">
                        <input-ui class="mt-2" v-model="state.fullName" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui label="Фамилия и инициалы">
                        <input-ui class="mt-2" v-model="state.name" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui invalid-feedback="Укажите должность" label="Должность">
                        <input-ui class="mt-2" v-model="state.position" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui invalid-feedback="Роль не указан" label="Роль" required>
                        <select class="form-select mt-2" v-model="state.roleId">
                            <option value="0" disabled>Не выбрано</option>
                            <option v-for="role in ROLE_COLLECTION" :key="role.VALUE" :value="role.VALUE">
                                {{ role.LABEL }}
                            </option>
                        </select>
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui invalid-feedback="Депо не указаны" label="Депо">
                        <select-ui :multiple="true" :is-invalid="state.visibleErrors && isDepotsError"
                            :options="depotsList" placeholder="Депо" :searchable="true" :values="state.depots"
                            @on-updated="handleDepoSelect" />
                    </form-group-ui>
                </div>
            </div>
            <div class="col-12 text-center mt-3">
                <button-ui button-class="btn-primary">
                    Сохранить
                </button-ui>
            </div>
        </form>
    </modal-ui>
</template>

<script setup lang="ts">
import { onMounted, reactive, computed, ref } from "vue";
import { useModal } from "@/composables/useModal";
import api from "@/api";
const { ROLE_COLLECTION } = require('@depo/constants/user');

type stateType = {
    id: number,
    visibleErrors: boolean,
    loader: boolean,
    password: string;
    login: string;
    roleId: number;
    position: string;
    fullName: string;
    name: string;
    email: string;
    depots: number[];
};

const props = defineProps({
    id: {
        type: String,
        default: '',
    },
});

const modal = useModal(props.id);
const depotsList = ref<{ id: number, name: string }[]>([]);
const state: stateType = reactive({
    id: 0,
    visibleErrors: false,
    loader: false,
    password: '',
    login: '',
    roleId: 0,
    position: '',
    fullName: '',
    name: '',
    email: '',
    depots: [],
});
onMounted(() => {
    api.depot.get().then(r => {
        depotsList.value = r.data;
    })
});

const isLoginError = computed(() => state.login.length < 5 || !/^[a-zA-Z0-9_]+$/.test(state.login));
const isPasswordError = computed(() => state.password.length < 5 || !/^[a-zA-Z0-9]+$/.test(state.password));
const isRoleError = computed(() => !Number(state.roleId));
const isDepotsError = computed(() => !state.depots.length);

const isErrorsExist = computed(() => {
    return isLoginError.value || isPasswordError.value || isRoleError.value || isDepotsError.value;
});

function handleDepoSelect(_c: any) {
    state.depots = _c.map((i: any) => i.id);
}

function send() {
    if (isErrorsExist.value) {
        state.visibleErrors = true;
        return;
    }
    state.visibleErrors = false;
    state.loader = true;

    api.user.create({
        login: state.login,
        roleId: state.roleId,
        password: state.password,
        position: state.position,
        fullName: state.fullName,
        name: state.name,
        email: state.email,
        depots: state.depots,
    }).then(r => {
        modal.ok(r.data);
    })
}

function ok(loco: any) {
    modal.ok({
        loco: loco,
    });
}

function close() {
    modal.cancel({
        zxc: 123,
    });
}

function cancel() {
    modal.cancel({
        zxc: 123,
    });
}
</script>

<style scoped lang="scss"></style>
