import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "alert-message text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["alert alert-warning", _ctx.getClasses])
  }, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.getText), 1)
  ], 2))
}