<template>
    <modal-ui :id="id" size="modal-lg" title="Выбрать победителя">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row">
                <div class="col-4 border fw-bold">Поставщик</div>
                <div class="col-4 border fw-bold">Общая сумма</div>
                <div class="col-4 border fw-bold">Место</div>
            </div>
            <div class="row" v-for="ps in state.protocolSuppliers">
                <div class="col-4 border d-flex align-items-center">{{ ps.supplier.name }}</div>
                <div class="col-4 border d-flex align-items-center justify-content-end">{{ formatMoney(ps.total) }}</div>
                <div class="col-4 border">
                    <form-group-ui invalid-feedback="Места не указаны или дублируется">
                        <select
                            v-model="ps.place"
                            class="form-select mt-2 text-end"
                            :class="{'is-invalid': state.visibleErrors && isPlacesError}"
                        >
                            <option v-for="place in [1, 2, 3]" :key="place" :value="place">
                                {{ place }}
                            </option>
                            <option value="">Не выбрано</option>
                        </select>
                    </form-group-ui>
                </div>
            </div>
            <div class="col-12 text-center mt-3">
                <button-ui button-class="btn-primary">
                    Сохранить
                </button-ui>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref } from "vue";
import { useModal } from "@/composables/useModal";
import api from "@/api";
import { DatePicker } from 'v-calendar';
import { ISupplier } from "@/@types/ISupplier";
import formatMoney from "@/utils/formatMoney";

type protocolSupplierType = {
    supplierId: number | string,
    id: number,
    isVatIncludedInPrice: boolean,
    description?: string,
    place: number | string,
    supplier: {
        name: string;
    },
    total: number,
    paymentTranches: {
        number: number,
        percent: number,
    }[],
};

type stateType = {
    id: number,
    protocolSuppliers: protocolSupplierType[],
    visibleErrors: boolean,
    loader: boolean,
};

export default defineComponent({
    name: "SupplierSelectionWinnerModal",
    components: {
        DatePicker,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    methods: {
        formatMoney,
    },
    setup(props) {
        const modal = useModal(props.id);

        const suppliers = ref<ISupplier[]>([]);

        const state: stateType = reactive({
            id: 0,
            protocolSuppliers: [],
            visibleErrors: false,
            loader: false,
        });
        onMounted(() => {
            if (Object.values(modal.data?.protocolSuppliers || {}).length) {
                state.protocolSuppliers = [...modal.data.protocolSuppliers];
            }
        });

        const isPlacesError = computed(() => {
            const places = state.protocolSuppliers.map(p => p.place);
            return new Set(places).size !== places.length;
        });

        const isErrorsExist = computed(() => {
            return state.protocolSuppliers.every(rm => !rm.place) || isPlacesError.value;
        });

        function send() {
            if (isErrorsExist.value) {
                state.visibleErrors = true;
                return;
            }
            state.visibleErrors = false;
            state.loader = true;

            api.supplierSelection.setWinner({
                protocolSupplierIds: state.protocolSuppliers.map(ps => ({
                    id: ps.id,
                    place: ps.place ? ps.place : null,
                })),
            }).then(r => {
                modal.ok(r.data);
            })
        }

        function ok(loco: any) {
            modal.ok({
                loco: loco,
            });
        }

        function close() {
            modal.cancel({
                zxc: 123,
            });
        }

        function cancel() {
            modal.cancel({
                zxc: 123,
            });
        }

        return {
            ok,
            modal,
            cancel,
            close,
            state,
            send,
            suppliers,
            isPlacesError,
        };
    },
});
</script>

<style scoped lang="scss"></style>
