<template>
    <ul class="nav nav-tabs">
        <li v-for="organization in props.tabs" :key="organization.VALUE"
            @click="onTabChange(organization.VALUE)" class="nav-item">
            <a class="nav-link text-black"
                :class="{ 'active': props.selectedTab === organization.VALUE }" href="#">
                {{ organization.LABEL }}
            </a>

        </li>
    </ul>
</template>

<script lang="ts" async setup>
import { PropType } from "vue";

const props = defineProps({
    selectedTab: {
        type: Number,
        required: true,
    },
    onTabChange: {
        type: Function as PropType<(tabIdx: number) => void>,
        required: true,
    },
    tabs: {
        type: Array<{ VALUE: number, LABEL: string }>,
        required: true,
    },
});
</script>

<style scoped lang="scss"></style>