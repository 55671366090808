<template>
    <popover-ui class="border-0 m-0" :content="note">
        <button class="btn btn-sm py-0 px-1 border-0 note-btn">
            <div class="note">
                ?
            </div>
        </button>
    </popover-ui>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import PopoverUi from "@/components/ui/PopoverUi.vue";

export default defineComponent({
    name: "NoteMarkUi",
    props: {
        note: {
            type: String,
            default: '',
        },
    },
    components: { PopoverUi },
    setup(props, { slots }) {
        return {

        };
    },
});
</script>

<style scoped lang="scss">
    .note-btn {
        background: transparent;
    }

	.note {
		background: #4b4b4b;
		color: #fff !important;
		font-weight: 700;
		font-size: .50rem;
		display: inline-block;
		width: 12px;
		height: 12px;
		border-radius: 50%;
		line-height: 12px;
		float: left;

		:hover {
			background: #333;
			color: #fff !important;
		}
	}
</style>
