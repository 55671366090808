<template>
    <modal-ui :id="id" size="modal-md" title="Выбрать технику">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-12">
                    <form-group-ui label="Техника" required invalid-feedback="Укажите техника">
                        <select-ui :is-invalid="state.visibleErrors && isSectionError" :options="state.sections"
                            placeholder="Техника" :searchable="true" :values="[state.sectionId]"
                            @on-selected="onSectionSelected">
                        </select-ui>
                    </form-group-ui>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button-ui button-class="btn-primary">
                        Сохранить
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed } from "vue";
import { useModal } from "@/composables/useModal";
import api from "@/api";

export default defineComponent({
    name: "SelectLocomotiveInMaterialRequirementModal",
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
        const modal = useModal(props.id);

        const state = reactive({
            sectionId: 0,
            materialRequirementId: 0,
            contractorId: null,
            locoModelId: null,
            sections: [],
            visibleErrors: false,
            loader: false,
        });

        const isSectionError = computed(() => !state.sectionId);

        onMounted((() => {
            state.contractorId = modal.data.contractorId;
            state.locoModelId = modal.data.locoModelId;
            state.materialRequirementId = modal.data.id;
            api.alllocos.get().then(r => {
                state.sections = r.data.reduce((acc: any[], section: any) => {
                    if (state.locoModelId !== section.locoModelId || (state.contractorId && state.contractorId !== section.contractorId)) {
                        return acc;
                    }
                    return [...acc, {
                        ...section,
                        number: section.name,
                        name: `№${section.sectionNumber} ${section.locoModel} - ${section.contractorName}`,
                        sectionId: section.id,
                        id: section.sectionId,
                    }];
                }, []);
            });
        }));

        function send() {
            if (isSectionError.value) {
                state.visibleErrors = true;
                return;
            }
            state.visibleErrors = false;
            state.loader = true;

            api.advancePayment.setLocomotive(state.materialRequirementId, { sectionId: state.sectionId }).then(r => {
                modal.ok();
            });
        }

        function ok() {
            modal.ok();
        }

        function close() {
            modal.cancel({
                zxc: 123,
            });
        }

        function cancel() {
            modal.cancel({
                zxc: 123,
            });
        }

        function onSectionSelected(_m: any) {
            state.sectionId = _m.id;
        }

        return {
            ok,
            cancel,
            close,
            state,
            send,
            onSectionSelected,
            isSectionError,
        };
    },
});
</script>

<style scoped lang="scss"></style>
