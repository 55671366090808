<template>
    <modal-ui :id="id" size="modal-xl" :title="state.title">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-12">
                    <form-group-ui invalid-feedback="Выберите сотрудника" label="Сотрудник" required>
                        <select-ui :is-invalid="state.visibleErrors && isEmployeeIdError"
                            :options="employees" placeholder="Сотрудник"
                            :searchable="true" :values="[state.employeeId]"
                            @on-selected="onEmployeeSelect" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui invalid-feedback="Выберите тип приказа" label="Тип приказа" required>
                        <select-ui :is-invalid="state.visibleErrors && isTypeError"
                            :options="orderTypes" placeholder="Приказ"
                            :searchable="true" :values="[state.type]"
                            @on-selected="onTypeSelect" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui invalid-feedback="Выберите начало" label="Начало" required>
                        <date-picker v-model="state.startsAt" as readonly :timezone="'Asia/Almaty'" class="inline-block h-full" :is24hr="true" mode="date">
                            <template #default="{ inputValue, togglePopover }">
                                <div class="flex items-center">
                                    <input class="form-control"
                                        :class="{ 'is-invalid': isStartsAtError && state.visibleErrors, 'bg-white': true }"
                                        :value="inputValue"
                                        @blur="togglePopover()" @focus="togglePopover()">
                                    <div class="invalid-feedback">
                                        Укажите начало
                                    </div>
                                </div>
                            </template>
                        </date-picker>
                    </form-group-ui>
                    <form-group-ui invalid-feedback="Выберите конец" label="Конец" required>
                        <date-picker v-model="state.endsAt" as readonly :timezone="'Asia/Almaty'" class="inline-block h-full" :is24hr="true" mode="date">
                            <template #default="{ inputValue, togglePopover }">
                                <div class="flex items-center">
                                    <input class="form-control"
                                        :disabled="state.type === ORDER_TYPE.DISMISSAL"
                                        :class="{ 'is-invalid': isEndsAtError && state.visibleErrors, 'bg-white': true }"
                                        :value="inputValue"
                                        @blur="togglePopover()" @focus="togglePopover()">
                                    <div class="invalid-feedback">
                                        Укажите конец
                                    </div>
                                </div>
                            </template>
                        </date-picker>
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui invalid-feedback="Примечание" label="Примечание">
                        <input-ui v-model="state.comment" placeholder="Примечание" />
                    </form-group-ui>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button-ui button-class="btn-primary">
                        {{ state.btn }}
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script setup lang="ts">
import { onMounted, reactive, computed, ref } from "vue";
import { ORDER_TYPE, ORDER_TYPE_COLLECTION } from '@depo/constants/employee';
import { useModal } from "@/composables/useModal";
import api from "@/api";
import moment from 'moment-timezone';
import { DatePicker } from 'v-calendar';

const props = defineProps({
    id: {
        type: String,
        default: '',
    },
});

const modal = useModal(props.id);
const employees = ref([]);
const orderTypes = ORDER_TYPE_COLLECTION.map(o => ({ id: o, name: o }));

const state = reactive({
    startsAt: '',
    endsAt: '',
    type: '',
    comment: '',
    employeeId: '',
    title: '',
    btn: '',
    visibleErrors: false,
    loader: false,
});
const isEmployeeIdError = computed(() => !state.employeeId);
const isTypeError = computed(() => !state.type);
const isStartsAtError = computed(() => !state.startsAt);
const isEndsAtError = computed(() => state.type !== ORDER_TYPE.DISMISSAL ? !state.endsAt : false);

const errors = computed(() => {
    return isStartsAtError.value || isEndsAtError.value || isEmployeeIdError.value || isTypeError.value;
});

onMounted(() => {
    console.log(modal.data.employeeOrder);
    if (modal.data.type === 'add') {
        state.title = 'Создать приказ';
        state.btn = 'Сохранить';
    } else {
        state.title = 'Редактировать приказ';
        state.btn = 'Сохранить';
        state.startsAt = modal.data.employeeOrder.startsAt;
        state.type = modal.data.employeeOrder.type;
        state.employeeId = modal.data.employeeOrder.employeeId;
        state.endsAt = modal.data.employeeOrder.endsAt;
        state.comment = modal.data.employeeOrder.comment;
    }
    api.employees.get().then((r: any) => {
        employees.value = r.data?.map((em: any) => ({
            ...em,
            name: `${em.employeeName} (${em.employeePost})`,
        }));
    });
});

function onEmployeeSelect(_c: any) {
    state.employeeId = _c.id;
}

function onTypeSelect(_c: any) {
    state.type = _c.id;
}

function send() {
    if (errors.value) {
        state.visibleErrors = true;
    } else {
        state.visibleErrors = false;
        state.loader = true;
        if (modal.data.type === 'add') {
            api.employeeOrder.create({
                startsAt: state.startsAt,
                ...(!!state.endsAt && {
                    endsAt: state.endsAt,
                }),
                employeeId: state.employeeId,
                comment: state.comment,
                type: state.type,
            }).then((r: any) => {
                if (r.data) {
                    modal.ok(r.data);
                }
            }).finally(() => state.loader = false);
        } else {
            api.employeeOrder.update(modal.data.employeeOrder.id, {
                startsAt: state.startsAt,
                ...(!!state.endsAt && {
                    endsAt: state.endsAt,
                }),
                employeeId: state.employeeId,
                comment: state.comment,
                type: state.type,
            }).then((r: any) => {
                if (r.data) {
                    modal.ok(r.data);
                }
            }).finally(() => state.loader = false);
        }
    }
}

function ok() {
    modal.ok({
        asd: 123,
    });
}

function close() {
    modal.close();
}

function cancel() {
    modal.cancel({
        zxc: 123,
    });
}
</script>

<style scoped lang="scss"></style>
