<template>
    <modal-ui
        :id="id"
        size="modal-md"
        :title="state.title">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Выберите сотрудника"
                        label="Сотрудник"
                        required>
                        <select-ui
                            :is-invalid="state.visibleErrors && isEmployeeIdError"
                            item-key="employeeName"
                            :options="employees"
                            placeholder="Сотрудник"
                            :searchable="true"
                            :values="[state.employeeId]"
                            @on-selected="onEmployeeSelected" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Выберите инструмент"
                        label="Инструмент"
                        required>
                        <select-ui
                            :is-invalid="state.visibleErrors && isToolIdError"
                            item-key="name"
                            :options="tools"
                            placeholder="Инструмент"
                            :searchable="true"
                            :values="[state.toolId]"
                            @on-selected="onToolSelected" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Укажите количество"
                        label="Количество"
                        required>
                        <input-ui
                            v-model="state.count"
                            :is-invalid="state.visibleErrors && isCountError"
                            :mask="{ mask: 'Z*', tokens: { 'Z': { pattern: /[0-9]/ }}}"
                            placeholder="Количество" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Укажите единицу измерения"
                        label="Единица измерения"
                        required>
                        <select
                            v-model="state.unit"
                            class="form-select"
                            :class="{'is-invalid': state.visibleErrors && isUnitError}">
                            <option disabled value="">
                                Единица измерения
                            </option>
                            <option
                                v-for="measurement in measurements"
                                :key="measurement"
                                :value="measurement">
                                {{ measurement }}
                            </option>
                        </select>
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        label="Примечание"
                        required>
                        <input-ui
                            v-model="state.info"
                            placeholder="Примечание" />
                    </form-group-ui>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button-ui
                        button-class="btn-primary">
                        {{ state.btn }}
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref, watch } from "vue";
import { initModal, useModal } from "@/composables/useModal";
import api from "@/api";
import { DatePicker } from 'v-calendar';
import moment from 'moment-timezone';

export default defineComponent({
    name: "ToolEmployeeReceiptModal",
    components: {
        DatePicker,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
	    const modal = useModal(props.id);
        const tools = ref([]);
        const employees = ref([]);

        const state = reactive({
            title: '',
            btn: '',
	        id: 0,
	        toolId: 0,
	        employeeId: 0,
	        unit: '',
	        info: '',
	        count: '0',
            visibleErrors: false,
            loader: false,
        });

	    const measurements = ref([
		    'гр.',
		    'кв. м',
		    'кг.',
		    'комплект',
		    'куб. м.',
		    'литр',
		    'метр',
		    'мл.',
		    'мм.',
		    'п.м.',
		    'пара',
		    'рулон',
		    'см.',
		    'тонна',
		    'упаковка',
		    'штук',
	    ]);

	    const isToolIdError = computed(() => !state.toolId);
	    const isEmployeeIdError = computed(() => !state.employeeId);
	    const isUnitError = computed(() => !state.unit.length);
	    const isCountError = computed(() => !parseInt(state.count));

        const errors = computed(() => isEmployeeIdError.value || isCountError.value || isUnitError.value);

        onMounted(() => {
            if (modal.data.type === 'add') {
                state.title = 'приемка инструмента';
                state.btn = 'Принять';
            } else {
	            state.title = 'Редактирование инструмента';
	            state.btn = 'Сохранить';

                state.id = modal.data.tool.id;
                state.employeeId = modal.data.tool.employeeId;
                state.toolId = modal.data.tool.toolId;
                state.count = modal.data.tool.count;
                state.unit = modal.data.tool.unit;
                state.info = modal.data.tool.info;
            }

	        api.tools.get().then((r: any) => {
		        tools.value = r.data;
	        });

	        api.employees.get().then((r: any) => {
		        employees.value = r.data;
	        });
        });

	    function onToolSelected(tool: any) {
		    state.toolId = tool.id;
	    }

	    function onEmployeeSelected(employee: any) {
		    state.employeeId = employee.employeeId;
	    }

        function send() {
            if (errors.value) {
                state.visibleErrors = true;
            } else {
                state.visibleErrors = false;
                state.loader = true;
	            if (modal.data.type === 'add') {
		            api.toolsemployees.receipt.post({
			            employeeId: state.employeeId,
			            toolId: state.toolId,
			            count: parseInt(state.count),
			            unit: state.unit,
			            info: state.info,
		            }).then((r: any) => {
			            if (r.data) {
                            modal.ok({
                	            list: r.data,
                            });
			            }
		            }).finally(() => state.loader = false);
	            } else {
		            api.toolsemployees.receipt.patch({
			            employeeId: state.employeeId,
			            toolId: state.toolId,
			            count: parseInt(state.count),
			            unit: state.unit,
			            info: state.info,
			            id: state.id,
		            }).then((r: any) => {
			            if (r.data) {
				            modal.ok({
					            list: r.data,
				            });
			            }
		            }).finally(() => state.loader = false);
	            }
            }
        }

	    function ok() {
		    modal.ok({
			    asd: 123,
		    });
	    }

        function close() {
            modal.close();
        }

	    function cancel() {
		    modal.cancel({
			    zxc: 123,
		    });
	    }

        return {
            ok,
            cancel,
            close,
            state,
	        tools,
	        employees,
	        isToolIdError,
	        isEmployeeIdError,
	        isCountError,
	        onToolSelected,
	        onEmployeeSelected,
	        send,
	        measurements,
	        isUnitError,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
