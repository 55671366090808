<template>
    <modal-ui
        :id="id"
        size="modal-md"
        :title="state.title">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Выберите дату"
                        label="Дата от"
                        required>
                        <date-picker
                            v-model="state.dateTo"
                            class="inline-block h-full"
                            :is24hr="true"
                            mode="dateTime">
                            <template #default="{ inputValue, togglePopover }">
                                <div class="flex items-center">
                                    <input
                                        class="form-control"
                                        :class="{'is-invalid': isDateToError && state.visibleErrors, 'bg-white': true }"
                                        placeholder="Дата"
                                        readonly
                                        :timezone="'Asia/Dhaka'"
                                        :value="inputValue"
                                        @blur="togglePopover()"
                                        @focus="togglePopover()">
                                    <div class="invalid-feedback">
                                        Укажите дату
                                    </div>
                                </div>
                            </template>
                        </date-picker>
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Выберите дату"
                        label="Дата до"
                        required>
                        <date-picker
                            v-model="state.dateFrom"
                            class="inline-block h-full"
                            :is24hr="true"
                            mode="dateTime">
                            <template #default="{ inputValue, togglePopover }">
                                <div class="flex items-center">
                                    <input
                                        class="form-control"
                                        :class="{'is-invalid': isDateFromError && state.visibleErrors, 'bg-white': true }"
                                        placeholder="Дата"
                                        readonly
                                        :timezone="'Asia/Dhaka'"
                                        :value="inputValue"
                                        @blur="togglePopover()"
                                        @focus="togglePopover()">
                                    <div class="invalid-feedback">
                                        Укажите дату
                                    </div>
                                </div>
                            </template>
                        </date-picker>
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Выберите поставщика"
                        label="Поставщик"
                        required>
                        <select-ui
                            :is-invalid="state.visibleErrors && isSupplierIdError"
                            item-key="name"
                            :options="supplier"
                            placeholder="Группа"
                            :searchable="true"
                            :values="[state.supplierId]"
                            @on-selected="onSupplierSelected" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Укажите ед. измерения"
                        label="Ед. измерения">
                        <input-ui
                            v-model="state.unit"
                            :is-invalid="state.visibleErrors && isUnitError"
                            placeholder="Ед. измерения" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Укажите цену"
                        label="Цена">
                        <input-ui
                            v-model="state.price"
                            :is-invalid="state.visibleErrors && isPriceError"
                            placeholder="Цена" />
                    </form-group-ui>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button-ui
                        button-class="btn-primary">
                        {{ state.btn }}
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref, watch } from "vue";
import { initModal, useModal } from "@/composables/useModal";
import api from "@/api";
import { DatePicker } from 'v-calendar';
import moment from 'moment-timezone';
import { insert } from "@/composables/useTransmitter";
import { useRoute } from "vue-router";

export default defineComponent({
    name: "PriceModal",
    components: {
        DatePicker,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
	    const modal = useModal(props.id);
        const supplier = ref([]);
        const route = useRoute();

        const state = reactive({
            title: '',
            btn: '',
	        id: 0,
	        dateFrom: new Date(),
	        dateTo: new Date(),
	        unit: '',
	        price: '',
	        supplierId: null,
            visibleErrors: false,
            loader: false,
        });

	    const isDateFromError = computed(() => !state.dateFrom);
	    const isDateToError = computed(() => !state.dateTo);
	    const isUnitError = computed(() => !state.unit.length);
	    const isPriceError = computed(() => !state.price.length);
	    const isSupplierIdError = computed(() => !state.supplierId);

        const errors = computed(() => {
            return isDateFromError.value ||
	            isDateToError.value ||
	            isUnitError.value ||
	            isPriceError.value ||
	            isSupplierIdError.value;
        });

        onMounted(() => {
            if (modal.data.type === 'add') {
                state.title = 'Добавление цены';
                state.btn = 'Добавить';
            } else {
	            state.title = 'Редактирование цены';
	            state.btn = 'Сохранить';

                state.id = modal.data.price.id;
                state.dateTo = modal.data.price.dateTo;
                state.dateFrom = modal.data.price.dateFrom;
                state.unit = modal.data.price.unit;
                state.price = modal.data.price.price;
                state.supplierId = modal.data.price.supplierId;
            }

	        api.supplier.get().then((i: any) => {
		        supplier.value = i.data;
	        });
        });

	    function onSupplierSelected(supplier: any) {
		    state.supplierId = supplier.id;
	    }

        function send() {
            if (errors.value) {
                state.visibleErrors = true;
            } else {
                state.visibleErrors = false;
                state.loader = true;
	            if (modal.data.type === 'add') {
		            api.materialPrice.post({
			            materialId: route.params.id,
			            id: state.id,
			            unit: state.unit,
			            price: state.price,
			            supplierId: state.supplierId,
			            // @ts-ignore
			            dateTo: moment(state.dateTo).format('YYYY-MM-DD'),
			            // @ts-ignore
			            dateFrom: moment(state.dateFrom).format('YYYY-MM-DD'),
		            }).then((r: any) => {
			            modal.ok({
				            price: r.data,
			            });
		            }).finally(() => state.loader = false);
	            } else {
		            api.materialPrice.patch({
			            id: state.id,
			            unit: state.unit,
			            price: state.price,
			            supplierId: state.supplierId,
			            // @ts-ignore
			            dateTo: moment(state.dateTo).format('YYYY-MM-DD'),
			            // @ts-ignore
			            dateFrom: moment(state.dateFrom).format('YYYY-MM-DD'),
		            }).then((r: any) => {
			            modal.ok({
				            price: r.data,
			            });
		            }).finally(() => state.loader = false);
	            }
            }
        }

	    function ok() {
		    modal.ok({
			    asd: 123,
		    });
	    }

        function close() {
            modal.close();
        }

	    function cancel() {
		    modal.cancel({
			    zxc: 123,
		    });
	    }

        return {
            ok,
            cancel,
            close,
            state,
	        onSupplierSelected,
	        send,
	        isDateFromError,
	        isDateToError,
	        isUnitError,
	        isPriceError,
	        isSupplierIdError,
	        supplier,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
