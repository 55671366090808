import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_popper = _resolveComponent("popper")!

  return (_openBlock(), _createBlock(_component_popper, {
    arrow: "",
    hover: true,
    placement: "top"
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", { innerHTML: _ctx.content }, null, 8, _hoisted_1)
    ]),
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }))
}