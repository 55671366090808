<template>
    <modal-ui
        :id="id"
        size="modal-sm"
        title="Загрузка платежного поручения">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
	            <div class="col-12">
		            <form-group-ui
			            invalid-feedback="Выберите файл"
			            label="Выберите файл"
			            required>
			            <input-ui
				            @change="selectFile($event)"
				            type="file"
				            ref="file"
				            :is-invalid="isFileError && state.visibleErrors"
			            />
		            </form-group-ui>
	            </div>
                <div class="col-12">
                    <form-group-ui :invalid-feedback="amountErrorText" label="Сумма">
                        <input-ui v-model="state.amount"
                            :mask="{ mask: 'Z*', tokens: { 'Z': { pattern: /^[0-9.,]+$/ } } }" maxlength="12"
                            :is-invalid="state.visibleErrors && isAmountError" placeholder="" />
                    </form-group-ui>
                </div>
                <div class="col-12" v-if="!isFullyPaid">
		            <form-group-ui
			            invalid-feedback="Выберите дату"
			            label="Дата следующего платежа"
			            required>
			            <date-picker
				            v-model="state.nextDate"
				            class="inline-block h-full"
				            mode="date">
				            <template #default="{ inputValue, togglePopover }">
					            <div class="flex items-center">
						            <input
							            class="form-control"
							            :class="{'is-invalid': isDateError && state.visibleErrors, 'bg-white': true }"as
							            readonly
							            :timezone="'Asia/Dhaka'"
							            :value="inputValue"
							            @blur="togglePopover()"
							            @focus="togglePopover()">
						            <div class="invalid-feedback">
							            Укажите дату следующей оплаты
						            </div>
					            </div>
				            </template>
			            </date-picker>
		            </form-group-ui>
	            </div>
                <div class="row">
                    <div class="col-12 text-center">
                        <button-ui
                            button-class="btn-primary">
                            Добавить
                        </button-ui>
                    </div>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed } from "vue";
import { useModal } from "@/composables/useModal";
import api from "@/api";
import { DatePicker } from 'v-calendar';

export default defineComponent({
    name: "InvoiceModal",
    components: {
        DatePicker,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
	    const modal = useModal(props.id);

        const state = reactive({
	        id: 0,
	        file: null,
            amount: '',
            nextDate: '',
            remainingAmount: 0,
            visibleErrors: false,
            loader: false,
        });

        onMounted(() => {
            state.remainingAmount = modal.data.payment.remainingAmount;
        });

        const isFullyPaid = computed(() => Number(state.amount) >= state.remainingAmount);
        const isFileError = computed(() => !state.file)
        const isAmountError = computed(() => /^[0-9]{12}$/.test(state.amount) || Number(state.amount) > state.remainingAmount);
        const isDateError = computed(() => !isFullyPaid ? state.nextDate.length === 0 : false);
        const amountErrorText = computed(() => Number(state.amount) > state.remainingAmount ? 'Сумма превышает остаток' : 'Введите сумму');

        const isError = computed(() => isFileError.value || isAmountError.value || isDateError.value);

		function selectFile(e: any) {
			let file = e.target.files[0];
			state.file = file
		}

        function send() {
            if (isError.value ) {
                state.visibleErrors = true;
                return;
            }

            state.visibleErrors = false;
            state.loader = true;

            let fd = new FormData()
            //@ts-ignore
            fd.append('file', state.file);
            fd.append('id', modal.data.payment.paymentRegisterId);
            fd.append('amount', state.amount);
            if (state.nextDate) {
                fd.append('nextDate', state.nextDate);
            }


            api.requestsPayments.pay(fd).then(r => {
                modal.ok({
                    item: r.data,
                });
            });
        }

	    function ok(loco: any) {
		    modal.ok({
			    loco: loco,
		    });
	    }

        function close() {
	        modal.cancel({
		        zxc: 123,
	        });
        }

	    function cancel() {
		    modal.cancel({
			    zxc: 123,
		    });
	    }

        return {
            ok,
	        modal,
            cancel,
            close,
            state,
	        send,
	        isFileError,
            isDateError,
            isAmountError,
            isFullyPaid,
            amountErrorText,
	        selectFile
        };
    },
});
</script>

<style scoped lang="scss">

</style>
