<template>
    <modal-ui
        :id="id"
        size="modal-md"
        :title="state.title">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        :invalid-feedback="isLocoNumberExistError ? 'Данный номер уже имеется в депо' : 'Укажите номер локомотива'"
                        label="Номер локомотива"
                        required>
                        <input-ui
                            v-model="state.locoNumber"
                            :is-invalid="state.visibleErrors && (isLocoNumberError || isLocoNumberExistError)"
                            :mask="{ mask: 'Z*', tokens: { 'Z': { pattern: /[0-9]/ }}}"
                            :maxlength="5"
                            placeholder="Номер локомотива" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Выберите модель локомотива"
                        label="Модель локомотива"
                        required>
                        <select-ui
                            :is-invalid="state.visibleErrors && isLocoModelIdError"
                            item-key="model"
                            :options="locoModels"
                            placeholder="Модель локомотива"
                            :searchable="true"
                            :values="[state.locoModelId]"
                            @on-selected="onLocoModelSelected" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Выберите владельца"
                        label="Владелец"
                        required>
                        <select-ui
                            :is-invalid="state.visibleErrors && isContractorIdError"
                            item-key="name"
                            :options="contractors"
                            placeholder="Владелец"
                            :searchable="true"
                            :values="[state.contractorId]"
                            @on-selected="onContractorSelected" />
                    </form-group-ui>
                </div>
                <!--                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">-->
                <!--                    <form-group-ui-->
                <!--                        invalid-feedback="Выберите вид работ"-->
                <!--                        label="Вид работ"-->
                <!--                        required>-->
                <!--                        <select-ui-->
                <!--                            :is-invalid="state.visibleErrors && isJobCategoryIdError"-->
                <!--                            item-key="name"-->
                <!--                            :options="jobCategories"-->
                <!--                            placeholder="Вид работ"-->
                <!--                            :searchable="true"-->
                <!--                            :values="[state.jobCategoryId]"-->
                <!--                            @on-selected="onJobCategorySelected" />-->
                <!--                    </form-group-ui>-->
                <!--                </div>-->
                <!--                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">-->
                <!--                    <form-group-ui-->
                <!--                        invalid-feedback="Выберите дату поступления"-->
                <!--                        label="Дата поступления"-->
                <!--                        required>-->
                <!--                        <date-picker-->
                <!--                            v-model="state.date"-->
                <!--                            class="inline-block h-full"-->
                <!--                            :is24hr="true"-->
                <!--                            mode="date">-->
                <!--                            <template #default="{ inputValue, togglePopover }">-->
                <!--                                <div class="flex items-center">-->
                <!--                                    <input-->
                <!--                                        class="form-control"-->
                <!--                                        :class="{'is-invalid': isDateError && state.visibleErrors, 'bg-white': true }"-->
                <!--                                        placeholder="Дата поступления"-->
                <!--                                        readonly-->
                <!--                                        :timezone="'Asia/Dhaka'"-->
                <!--                                        :value="inputValue"-->
                <!--                                        @blur="togglePopover()"-->
                <!--                                        @focus="togglePopover()">-->
                <!--                                    <div class="invalid-feedback">-->
                <!--                                        Укажите дату-->
                <!--                                    </div>-->
                <!--                                </div>-->
                <!--                            </template>-->
                <!--                        </date-picker>-->
                <!--                    </form-group-ui>-->
                <!--                </div>-->
            </div>
            <div v-if="state.locoSections.length" class="row mb-4">
                <h5>Данные о секциях</h5>
                <template v-for="(section, sectionInd) in state.locoSections">
                    <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                        <form-group-ui
                            invalid-feedback="Укажите номер секции"
                            :label="'Номер секции ' + (sectionInd + 1)"
                            required>
                            <input-ui
                                v-model="state.locoSections[sectionInd]"
                                :is-invalid="state.visibleErrors && isSectionNumberError(sectionInd)"
                                :placeholder="'Номер секции ' + (sectionInd + 1)" />
                        </form-group-ui>
                    </div>
                </template>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button-ui
                        button-class="btn-primary">
                        {{ state.btn }}
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref, watch } from "vue";
import { initModal, useModal } from "@/composables/useModal";
import api from "@/api";
import { DatePicker } from 'v-calendar';
import moment from 'moment-timezone';

export default defineComponent({
    name: "LocomotiveModal",
    components: {
        DatePicker,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
	    const modal = useModal(props.id);

	    const contractors = ref([]);
	    const jobCategories = ref([]);
	    const locoModels = ref([]);
        const locomotives = ref([]);

        const state = reactive({
            title: '',
            btn: '',
	        locoModelId: 0,
	        // jobCategoryId: 0,
	        contractorId: 0,
	        locoNumber: '',
	        locoSections: <string[]>[],
	        // date: moment().format('YYYY-MM-DD'),
            visibleErrors: false,
            loader: false,
        });

	    const isLocoModelIdError = computed(() => !state.locoModelId);
	    // const isJobCategoryIdError = computed(() => !state.jobCategoryId);
	    const isContractorIdError = computed(() => !state.contractorId);
	    const isLocoNumberError = computed(() => !state.locoNumber.length);
        const isLocoNumberExistError = computed(() => locomotives.value.filter((i: any) => i.name === state.locoNumber).length);
	    // const isDateError = computed(() => !state.date);
	    const isSectionsError = computed(() => !state.locoSections.length);
	    const isSectionNumberError = computed(() => (ind: number) => !state.locoSections[ind].length);

        const errors = computed(() => {
            let sections = state.locoSections.map((item: any, i: any) => !state.locoSections[i].length).filter((i: any) => i).length > 0;
            return isLocoModelIdError.value ||
				// isJobCategoryIdError.value ||
				isContractorIdError.value ||
				isLocoNumberError.value ||
				// isDateError.value ||
				isSectionsError.value ||
	            isLocoNumberExistError.value ||
	            sections;
        });

        onMounted((() => {
            if (modal.data.type === 'add') {
                state.title = 'Добавление локомотива';
                state.btn = 'Добавить';
            }

            api.info.get().then((r: any) => {
	            contractors.value = r.data.contractors;
	            jobCategories.value = r.data.jobCategories;
	            locoModels.value = r.data.locoModels;
            });

	        locomotives.value = modal.data.locomotives;
        }));

	    function onLocoModelSelected(locomotive: any) {
		    state.locoModelId = locomotive.id;
	    }

	    // function onJobCategorySelected(category: any) {
        //     state.jobCategoryId = category.id;
	    // }

	    function onContractorSelected(contractor: any) {
		    state.contractorId = contractor.id;
	    }

        // function onSelectedCity(city: ICity) {
        //     state.cityId = city.id;
        // }
        //
        function send() {
            if (errors.value) {
                state.visibleErrors = true;
            } else {
                state.visibleErrors = false;
                state.loader = true;
                api.locomotives.post({
                    modelId: state.locoModelId,
                    // categoryId: state.jobCategoryId,
                    contractorId: state.contractorId,
                    number: state.locoNumber,
                    // date: moment(state.date).format('YYYY-MM-DD'),
	                sections: state.locoSections,
                }).then((r: any) => {
                    if (r.data.length) {
                        modal.ok({
                            locomotives: r.data,
                        });
                    }
                }).finally(() => state.loader = false);
            }
        }

	    function ok() {
		    modal.ok({
			    asd: 123,
		    });
	    }

        function close() {
            modal.close();
        }

	    function cancel() {
		    modal.cancel({
			    zxc: 123,
		    });
	    }

        watch(() => [state.locoModelId, state.locoNumber], (v: any) => {
            if (v[0] && v[1].length) {
                state.locoSections = [];
                let symbols = ['А', 'Б', 'В', 'Г', 'Д', 'Е'];
                let locomotive: any = locoModels.value.find((i: any) => i.id === v[0]);
                if (locomotive.count === 1) {
                    state.locoSections.push(v[1]);
                } else {
                    for (let x = 0; x < locomotive.count; x++) {
                        let section: string = v[1] + '' + symbols[x];
                        state.locoSections.push(section);
                    }
                }
            } else {
                state.locoSections = [];
            }
        });

        // watch(() => state.locoNumber, (v) => {
        //     if (v.length === 4) {
        //         api.searchLoco.post(v).then((r: any) => {
        //             if (r.data.length) {
        //                 state.contractorId = r.data[0].contractorId;
	    //                 // state.jobCategoryId = r.data[0].jobCategoryId;
	    //                 state.locoModelId = r.data[0].locomotiveModelId;
        //             }
        //         });
        //     }
        // });

        return {
            ok,
            cancel,
            close,
            state,
	        contractors,
	        jobCategories,
	        locoModels,
	        isLocoModelIdError,
	        // isJobCategoryIdError,
	        isContractorIdError,
	        isLocoNumberError,
	        // isDateError,
	        isSectionNumberError,
	        isLocoNumberExistError,
	        onLocoModelSelected,
	        // onJobCategorySelected,
	        onContractorSelected,
	        send,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
