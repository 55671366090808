import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["disabled", "type"]
const _hoisted_2 = {
  key: 0,
  class: "spinner-border spinner-border-sm"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["btn", _ctx.getButtonClass]),
    disabled: _ctx.getDisabled,
    type: _ctx.type
  }, [
    (_ctx.state.loader)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2))
      : _createCommentVNode("", true),
    (!_ctx.state.loader)
      ? _renderSlot(_ctx.$slots, "default", { key: 1 })
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}