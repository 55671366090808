<template>
    <div class="container-fluid p-0">
        <div class="row mb-2 mb-xl-3">
            <div class="col-auto d-none d-sm-block">
                <h3>Документы</h3>
            </div>
            <div class="col-auto ms-auto text-end mt-n1 d-flex">
                <button-ui button-class="btn-primary ms-3 btn-sm" @click="handleCreateDocumentType">
                    Создать
                </button-ui>
            </div>
        </div>
    </div>
    <div>
        <slot></slot>
        <div class="overflow-hidden">
            <div class="bg-white position-relative overflow-auto mw-100 pb-5" style="max-height: 670px;">
                <table class="table table-bordered">
                    <thead class="sticky-top bg-white">
                        <tr class="position-relative border-0">
                            <th class="py-2 px-3"># п/п</th>
                            <th class="py-2 px-3">Тип</th>
                            <th class="py-2 px-3">Количество подтипов</th>
                            <th class="py-2 px-3">Создано</th>
                            <th class="py-2 px-3"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="state.documentTypes.length">
                            <tr v-for="(documentType, idx) in state.documentTypes" :key="documentType.id">
                                <td class="py-2 px-3">{{ idx + 1 }}</td>
                                <td class="py-2 px-3">{{ documentType.name }}</td>
                                <td class="py-2 px-3">{{ documentType._count.subTypes }}</td>
                                <td class="py-2 px-3">{{ formatDate(documentType.createdAt) }}</td>
                                <td class="py-2 px-3">
                                    <dropdown-ui>
                                        <template #button>
                                            <button
                                                class="btn btn-sm d-flex justify-content-center align-items-center">
                                                <vue-feather size="14px" type="more-vertical" />
                                            </button>
                                        </template>
                                        <template #list>
                                            <button-ui class="dropdown-item cursor-pointer" @click="handleCreateDocumentSubType(documentType)">
                                                Создать подтип
                                            </button-ui>
                                            <button-ui class="dropdown-item cursor-pointer" @click="handleEditDocumentType(documentType)">
                                                Редактировать
                                            </button-ui>
                                            <button-ui class="dropdown-item cursor-pointer" @click="handleDeleteDocumentType(documentType.id)">
                                                Удалить
                                            </button-ui>
                                        </template>
                                    </dropdown-ui>
                                </td>
                            </tr>
                        </template>
                        <tr v-else>
                            <td class="py-2 px-3 text-center" colspan="3">Документы отсутствуют</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script lang="ts" async setup>

import { reactive, onMounted } from "vue";
import api from "@/api";
import { useModal } from "@/composables/useModal";
import { IDocumentType } from "@/@types/IDocument";
import formatDate from "@/utils/formatDate";

type stateType = {
    documentTypes: IDocumentType[],
    search: string,
};

const state: stateType = reactive({
    documentTypes: [],
    search: '',
});

async function getDocumentTypes() {
    return api.document.getTypes().then(({ data }) => {
        state.documentTypes = data;
    });
}

onMounted(getDocumentTypes);

function handleCreateDocumentType() {
    useModal("text_modal").open({
        title: 'Создать тип документа',
        btn: 'Сохранить',
        value: '',
        label: 'Название',
        type: 'text',
    }).then((r: any) => api.document.createType({ name: r.value }))
        .then(getDocumentTypes)
        .catch(console.error);
}

function handleCreateDocumentSubType(documentType: IDocumentType) {
    useModal("text_modal").open({
        title: 'Создать подтип документа',
        btn: 'Сохранить',
        value: '',
        label: 'Название',
        type: 'text',
    }).then((r: any) => api.document.createSubType({ documentTypeId: documentType.id, name: r.value }))
        .then(getDocumentTypes)
        .catch(console.error);
}

function handleEditDocumentType(documentType: IDocumentType) {
    useModal("text_modal").open({
        title: 'Редактировать тип документа',
        btn: 'Сохранить',
        value: documentType.name,
        label: 'Название',
        type: 'text',
    }).then((r: any) => api.document.updateType(documentType.id, { name: r.value }))
        .then(getDocumentTypes)
        .catch(console.error);
}

function handleDeleteDocumentType(id: number) {
    useModal('confirm_modal').open({
        question: 'Подтвердите удаление типа документа',
        ok: 'Да',
        cancel: 'Нет',
    }).then((r: any) => api.document.deleteType(id)).then(getDocumentTypes);
}

</script>

<style scoped lang="scss"></style>