import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row mb-4" }
const _hoisted_2 = { class: "col-md-6 col-lg-6 col-xl-6 col-xxl-6" }
const _hoisted_3 = { class: "col-md-6 col-lg-6 col-xl-6 col-xxl-6" }
const _hoisted_4 = { class: "col-md-6 col-lg-6 col-xl-6 col-xxl-6" }
const _hoisted_5 = { class: "col-md-6 col-lg-6 col-xl-6 col-xxl-6" }
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col-12 text-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loader_ui = _resolveComponent("loader-ui")!
  const _component_input_ui = _resolveComponent("input-ui")!
  const _component_form_group_ui = _resolveComponent("form-group-ui")!
  const _component_select_ui = _resolveComponent("select-ui")!
  const _component_button_ui = _resolveComponent("button-ui")!
  const _component_modal_ui = _resolveComponent("modal-ui")!

  return (_openBlock(), _createBlock(_component_modal_ui, {
    id: _ctx.id,
    size: "modal-md",
    title: _ctx.state.title
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        class: "loader",
        onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.send && _ctx.send(...args)), ["prevent"]))
      }, [
        _createVNode(_component_loader_ui, {
          "is-show": _ctx.state.loader
        }, null, 8, ["is-show"]),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": "Укажите наименование",
              label: "Наименование",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_input_ui, {
                  modelValue: _ctx.state.name,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.name) = $event)),
                  "is-invalid": _ctx.state.visibleErrors && _ctx.isNameError,
                  placeholder: "Наименование"
                }, null, 8, ["modelValue", "is-invalid"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": "Выберите группу",
              label: "Группа",
              required: ""
            }, {
              default: _withCtx(() => [
                _createVNode(_component_select_ui, {
                  "is-invalid": _ctx.state.visibleErrors && _ctx.isGroupIdError,
                  "item-key": "name",
                  options: _ctx.groups,
                  placeholder: "Группа",
                  searchable: true,
                  values: [_ctx.state.groupId],
                  onOnSelected: _ctx.onGroupSelected
                }, null, 8, ["is-invalid", "options", "values", "onOnSelected"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": "Укажите категорию",
              label: "Категория"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_input_ui, {
                  modelValue: _ctx.state.category,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.category) = $event)),
                  placeholder: "Категория"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", _hoisted_5, [
            _createVNode(_component_form_group_ui, {
              "invalid-feedback": "Укажите характеристику",
              label: "Характеристика"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_input_ui, {
                  modelValue: _ctx.state.characteristic,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.state.characteristic) = $event)),
                  placeholder: "Характеристика"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_button_ui, { "button-class": "btn-primary" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.state.btn), 1)
              ]),
              _: 1
            })
          ])
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["id", "title"]))
}