<template>
    <modal-ui
        :id="id"
        size="modal-sm"
        :title="state.title">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-12">
                    <form-group-ui
                        invalid-feedback="Выберите дату"
                        label="Дата"
                        required>
                        <date-picker
                            v-model="state.date"
                            class="inline-block h-full"
                            :is24hr="true"
                            :mode="state.type">
                            <template #default="{ inputValue, togglePopover }">
                                <div class="flex items-center">
                                    <input
                                        class="form-control"
                                        :class="{'is-invalid': isDateError && state.visibleErrors, 'bg-white': true }"
                                        placeholder="Дата"
                                        readonly
                                        :timezone="'Asia/Dhaka'"
                                        :value="inputValue"
                                        @blur="togglePopover()"
                                        @focus="togglePopover()">
                                    <div class="invalid-feedback">
                                        Укажите дату
                                    </div>
                                </div>
                            </template>
                        </date-picker>
                    </form-group-ui>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button-ui
                        button-class="btn-primary">
                        {{ state.btn }}
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref, watch } from "vue";
import { initModal, useModal } from "@/composables/useModal";
import api from "@/api";
import { DatePicker } from 'v-calendar';
import moment from 'moment-timezone';

export default defineComponent({
    name: "ChooseDateModal",
    components: {
        DatePicker,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
	    const modal = useModal(props.id);

        const state = reactive({
	        type: 'dateTime',
            title: '',
            btn: '',
	        date: moment().format('YYYY-MM-DD HH:mm:ss'),
            visibleErrors: false,
            loader: false,
        });

		if(modal.data.hasOwnProperty('type')) {
			state.type = modal.data.type
		}

	    const isDateError = computed(() => !state.date);

        onMounted((() => {
	        state.title = 'Выбор даты';
	        state.btn = 'Выбрать';
        }));

        function send() {
            if (isDateError.value) {
                state.visibleErrors = true;
            } else {
                state.visibleErrors = false;
                state.loader = true;
	            ok();
            }
        }

	    function ok() {
		    if(state.type === 'dateTime') {
			    modal.ok({
				    date: moment(state.date).format('YYYY-MM-DD HH:mm:ss'),
			    });
		    }else{
			    modal.ok({
				    date: moment(state.date).format('YYYY-MM-DD'),
			    });
		    }
	    }

        function close() {
	        modal.cancel({
		        zxc: 123,
	        });
        }

	    function cancel() {
		    modal.cancel({
			    zxc: 123,
		    });
	    }

        return {
            ok,
            cancel,
            close,
            state,
	        isDateError,
	        send,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
