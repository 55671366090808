<template>
    <modal-ui
        :id="id"
        size="modal-sm"
        title="Добавление локомотива">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-12">
                    <form-group-ui
                        invalid-feedback="Выберите локомотив"
                        label="Локомотив"
                        required>
                        <select-ui
                            :is-invalid="isLocoIdError && state.visibleErrors"
                            item-key="model"
                            :options="locoModels"
                            :searchable="true"
                            :values="[state.locoModelId]"
                            @on-selected="onLocoModelSelected" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui
                        invalid-feedback="Выберите вид ремонта"
                        label="Вид ремонта"
                        required>
                        <select-ui
                            :is-invalid="isJobCategoryIdError && state.visibleErrors"
                            item-key="name"
                            :options="jobCategories"
                            :searchable="true"
                            :values="[state.jobCategoryId]"
                            @on-selected="onJobCategorySelected" />
                        <div v-if="state.jobCategoryId !== 0 && state.locoModelId !== 0" class="mt-2 text-danger">
                            Норма ремонта {{ plannedDays }} дней
                        </div>
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui invalid-feedback="Выберите контрагента" label="Контрагент">
                        <select-ui
                            item-key="name"
                            :options="contractors"
                            :searchable="true"
                            :values="[state.contractorId]"
                            @on-selected="onContractorSelected" />
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui
                        invalid-feedback="Выберите дату"
                        label="Дата"
                        required>
                        <date-picker
                            v-model="state.date"
                            class="inline-block h-full"
                            :disabled-dates="dayList"
                            :is24hr="true"
                            :max-date="new Date(maxDate.format('YYYY-MM-DD'))"
                            :min-date="new Date(minDate.format('YYYY-MM-DD'))"
                            mode="date">
                            <template #default="{ inputValue, togglePopover }">
                                <div class="flex items-center">
                                    <input
                                        class="form-control"
                                        :class="{'is-invalid': isDateError && state.visibleErrors, 'bg-white': true }"
                                        placeholder="Дата"
                                        readonly
                                        :timezone="'Asia/Dhaka'"
                                        :value="inputValue"
                                        @blur="togglePopover()"
                                        @focus="togglePopover()">
                                    <div class="invalid-feedback">
                                        Укажите дату
                                    </div>
                                </div>
                            </template>
                        </date-picker>
                    </form-group-ui>
                </div>
                <div class="col-12">
                    <form-group-ui invalid-feedback="Выберите примечание" label="Примечание">
                        <input-ui v-model="state.comment" placeholder="Примечание" />
                    </form-group-ui>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button-ui
                        button-class="btn-primary"
                        :disabled="plannedDays === 0">
                        Добавить
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref, watch } from "vue";
import { initModal, useModal } from "@/composables/useModal";
import api from "@/api";
import { DatePicker } from 'v-calendar';
import moment from 'moment-timezone';
import { insert } from "@/composables/useTransmitter";

export default defineComponent({
    name: "AddSectionModal",
    components: {
        DatePicker,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
	    const modal = useModal(props.id);
        const locoModels = ref([]);
        const jobCategories = ref([]);
        const contractors = ref([]);

        const state = reactive({
	        locoModelId: 0,
	        jobCategoryId: 0,
	        contractorId: 0,
	        date: '',
	        dateEnd: '',
	        comment: '',
            visibleErrors: false,
            loader: false,
        });

	    const isDateError = computed(() => !state.date);
        const isLocoIdError = computed(() => state.locoModelId === 0);
	    const isJobCategoryIdError = computed(() => state.jobCategoryId === 0);
        const plannedDays = ref(0);

	    const minDate = moment();
	    minDate.set('month', modal.data.monthInd);
	    minDate.set('date', 1);

	    const maxDate = moment();
	    maxDate.set('month', modal.data.monthInd);
	    maxDate.endOf('month');

        const dayList = computed(() => {
            if (state.jobCategoryId !== 0 && state.locoModelId !== 0) {
                let job: any = jobCategories.value.find((i: any) => i.id === state.jobCategoryId);
                let startList = minDate.clone().subtract(plannedDays.value, 'd');
                let endList = maxDate.clone().add(plannedDays.value, 'd');
                // let items = modal.data.items.filter((i: any) => i.row === modal.data.row && i.col === modal.data.column && (moment(i.dateStart).month() === modal.data.monthInd || moment(i.dateEnd).month() === modal.data.monthInd));
                let items = modal.data.items.filter((i: any) => i.row === modal.data.row && i.col === modal.data.column && (startList.isBetween(moment(i.dateStart), moment(i.dateEnd)) || endList.isBetween(moment(i.dateStart), moment(i.dateEnd))));
                let isDisable = false;
                let dates = [];
	            let _items = [];

                while (startList.isSameOrBefore(endList)) {
	                let _items = modal.data.items.filter((i: any) => i.row === modal.data.row && i.col === modal.data.column && startList.isBetween(moment(i.dateStart), moment(i.dateEnd).add(1, 'day')));
	                // console.log(2222, startList.format('M/D/YYYY'), '---', _items);
	                isDisable = false;
                    for (let i = 0; i < _items.length; i++) {
	                    isDisable = false;
                        // console.log(startList.isBetween(moment(items[i].dateStart), moment(items[i].dateEnd)));
	                    // console.log(startList.format('M/D/YYYY'), '---');

	                    // console.log(maxDate.format('MM DD'), moment(_items[i].dateStart).format('MM DD'), moment(_items[i].dateEnd).format('MM DD'), minDate.isBetween(moment(_items[i].dateStart), moment(_items[i].dateEnd)));

	                    // console.log(startList.format('MM DD'), '-', moment(_items[i].dateStart).format('MM DD'), '-', moment(_items[i].dateEnd).add(1, 'day').format('MM DD'), '-', startList.isBetween(moment(_items[i].dateStart), moment(_items[i].dateEnd).add(1, 'day')));
                        console.log(1, startList.format('M/D/YYYY'), startList.isBetween(moment(_items[i].dateStart), moment(_items[i].dateEnd).add(1, 'day')));

	                    if (startList.isBetween(moment(_items[i].dateStart), moment(_items[i].dateEnd).add(1, 'day'))) {
                            isDisable = true;
                            break;
                        } else {
                            // isDisable = false;
                        }
                    }

                    console.log(2, startList.format('M/D/YYYY'), isDisable);
                    dates.push({
                        date: startList.format('M/D/YYYY'),
                        isDisable: isDisable,
                    });

                    startList.add(1, 'day');
                }

	            // _items = modal.data.items.filter((i: any) => i.row === modal.data.row && i.col === modal.data.column && startList.isBetween(moment(i.dateStart), moment(i.dateEnd)));
	            _items = modal.data.items.filter((i: any) => (
			            moment(i.dateStart).month() === modal.data.monthInd ||
			            moment(i.dateEnd).month() === modal.data.monthInd) ||
		            minDate.isBetween(moment(i.dateStart), moment(i.dateEnd)) ||
		            maxDate.isBetween(moment(i.dateStart), moment(i.dateEnd)),
	            ).filter((i: any) => i.row === modal.data.row && i.col === modal.data.column);

	            isDisable = false;

                // console.log(3453453, _items, startList.format('MM DD'));

                for (let i = 0; i < _items.length; i++) {
	                // console.log(45, startList.format('MM DD'), '-', moment(_items[i].dateStart).format('MM DD'), '-', moment(_items[i].dateEnd).add(1, 'day').format('MM DD'), '-', startList.isBetween(moment(_items[i].dateStart), moment(_items[i].dateEnd).add(1, 'day')));

	                if (startList.isBetween(moment(_items[i].dateStart), moment(_items[i].dateEnd).add(1, 'day'))) {
                        isDisable = true;
                        break;
                    } else {
                        isDisable = false;
                    }
                }

                dates.push({
                    date: startList.format('M/D/YYYY'),
                    isDisable: isDisable,
                });

                // console.log(11, dates);

                let enabledDays = dates.filter(i => !i.isDisable);
                // console.log(enabledDays);

	            // for (let i = 0; i < enabledDays.length; i++) {
                //     let count = 1;
	            //
                //     for (let i2 = i; i2 < enabledDays.length; i2++) {
	            //         if (i2 !== 0) {
                //             if (moment(enabledDays[i2].date).subtract(1, 'd').format('M/D/YYYY') === moment(enabledDays[i2 - 1].date).format('M/D/YYYY')) {
	            //                 count = count + 1;
		        //             } else {
	            //                 break;
		        //             }
	            //         }
	            //
                //         // if (i2 !== 0 && moment.duration(moment(enabledDays[i2].date).diff(moment(enabledDays[i2 - 1].date))).asDays() === 1) {
	            //         //     count = count + 1;
                //         // } else {
                //         //     break;
                //         // }
                //     }
	            //
                //     // console.log(enabledDays[i].date, count, job.plannedDays);
                //     if (enabledDays[i].isDisable && count < plannedDays.value) {
                //         enabledDays[i].isDisable = true;
                //     }
	            // }

	            // console.log(dates);

                return dates.filter(i => i.isDisable).map(i => i.date);
            }

            return [];
        });

        watch(() => state.jobCategoryId, (v) => {
            state.date = '';
        });

        watch(() => state.date, (v) => {
            // let job: any = jobCategories.value.find((i: any) => i.id === state.jobCategoryId);
	        let dateEnd = moment(state.date).add(plannedDays.value - 1, 'd');
            state.dateEnd = dateEnd.format('YYYY-MM-DD') ;
            console.log(state.date, plannedDays.value, state.dateEnd);
        });

        watch(() => [ state.locoModelId, state.jobCategoryId ], (v) => {
            api.getrate.get(state.locoModelId, state.jobCategoryId).then((r: any) => {
                if (r.data.hasOwnProperty('days')) {
                    plannedDays.value = r.data.days;
                } else {
                    plannedDays.value = 0;
                }
            });
        });

        onMounted(() => {
	        api.info.get().then((r: any) => {
                locoModels.value = r.data.locoModels;
		        jobCategories.value = r.data.jobCategories;
		        contractors.value = r.data.contractors;
	        });
        });

        function send() {
            if (isLocoIdError.value || isJobCategoryIdError.value || isDateError.value) {
                state.visibleErrors = true;
            } else {
                state.visibleErrors = false;
                state.loader = true;

	            api.newPlan.create({
		            locoModelId: state.locoModelId,
		            jobCategoryId: state.jobCategoryId,
		            comment: state.comment.length ? state.comment : null,
		            contractorId: state.contractorId !== 0 ? state.contractorId : null,
		            dateStart: moment(state.date).format('YYYY-MM-DD'),
		            dateEnd: moment(state.dateEnd).format('YYYY-MM-DD'),
		            row: modal.data.row,
		            col: modal.data.column,
	            }).then(r => {
		            console.log(r.data);
		            modal.ok({
			            loco: r.data,
		            });
	            });
            }
        }

	    function ok(loco: any) {
		    modal.ok({
			    loco: loco,
		    });
	    }

        function close() {
	        modal.cancel({
		        zxc: 123,
	        });
        }

	    function cancel() {
		    modal.cancel({
			    zxc: 123,
		    });
	    }

	    function onLocoModelSelected(i: any) {
		    state.locoModelId = i.id;
	    }

	    function onJobCategorySelected(i: any) {
		    state.jobCategoryId = i.id;
	    }

	    function onContractorSelected(i: any) {
		    state.contractorId = i.id;
	    }

        return {
            ok,
	        modal,
            cancel,
            close,
            state,
	        isDateError,
	        send,
	        locoModels,
	        contractors,
	        jobCategories,
	        onLocoModelSelected,
	        onJobCategorySelected,
	        onContractorSelected,
	        minDate,
	        maxDate,
	        dayList,
	        isLocoIdError,
	        isJobCategoryIdError,
            plannedDays,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
