<template>
    <modal-ui
        :id="id"
        size="modal-sm"
        title="Загрузка вложения протокола">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-12">
		            <form-group-ui invalid-feedback="Введите название" label="Название" required>
                        <input-ui v-model="state.name"
                            :is-invalid="state.visibleErrors && isNameError"
                            placeholder="Название" />
                    </form-group-ui>
	            </div>
	            <div class="col-12">
		            <form-group-ui
			            invalid-feedback="Выберите файл"
			            label="Выберите файл"
			            required>
			            <input-ui
				            @change="selectFile($event)"
				            type="file"
				            ref="file"
				            :is-invalid="isFileError && state.visibleErrors"
			            />
		            </form-group-ui>
	            </div>
                <div class="row">
                    <div class="col-12 text-center">
                        <button-ui
                            button-class="btn-primary">
                            Добавить
                        </button-ui>
                    </div>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, reactive, computed, onMounted } from "vue";
import { useModal } from "@/composables/useModal";
import api from "@/api";
import { DatePicker } from 'v-calendar';

export default defineComponent({
    name: "SupplierSelectionAttachmentModal",
    components: {
        DatePicker,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
	    const modal = useModal(props.id);

        const state = reactive({
	        id: 0,
	        file: null,
            name: '',
            protocolSupplierId: '',
            visibleErrors: false,
            loader: false,
        });

        const isFileError = computed(() => !state.file)
        const isNameError = computed(() => !state.name);

		function selectFile(e: any) {
			let file = e.target.files[0];
			state.file = file
		}

        onMounted(() => {
            console.log(modal.data?.protocolSupplier);
            if (Object.values(modal.data?.protocolSupplier || {}).length) {
                state.protocolSupplierId = modal.data.protocolSupplier.id;
            }
        });

        function send() {
            if (isFileError.value || isNameError.value) {
                state.visibleErrors = true;
                return;
            }
            state.visibleErrors = false;
            state.loader = true;

            let fd = new FormData()
            //@ts-ignore
            fd.append('file', state.file);
            fd.append('name', state.name);
            fd.append('protocolSupplierId', state.protocolSupplierId);


            api.supplierSelection.createAttachment(fd).then(r => {
                modal.ok({
                    isSuccess: true,
                });
            });
        }

	    function ok(loco: any) {
		    modal.ok({
			    loco: loco,
		    });
	    }

        function close() {
	        modal.cancel({
		        zxc: 123,
	        });
        }

	    function cancel() {
		    modal.cancel({
			    zxc: 123,
		    });
	    }

        return {
            ok,
	        modal,
            cancel,
            close,
            state,
	        send,
	        isFileError,
            isNameError,
	        selectFile
        };
    },
});
</script>

<style scoped lang="scss">

</style>
