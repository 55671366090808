<template>
  <modal-ui :id="id" size="modal-xl" title="Объединение материалов">
    <form class="loader" @submit.prevent="send">
      <loader-ui :is-show="state.loader" />
      <div class="row mb-4">
        <div class="col-12 col-md-6">
          <form-group-ui
            invalid-feedback="Выберите материалы которые нужно удалить"
            label="Выберите материалы которые нужно удалить"
            required
          >
            <select-ui
              :is-invalid="state.visibleErrors && isToBeMergedMaterialIdsError"
              item-key="name"
              :options="materials.filter((m) => +m.id !== +state.targetMaterialId)"
              :searchable="true"
              :multiple="true"
              :values="state.toBeMergedMaterialIds"
              @on-updated="onToBeMergedMaterialsSelected"
            />
          </form-group-ui>
        </div>
        <div class="col-12 col-md-6">
          <form-group-ui
            invalid-feedback="Выберите материал который остается"
            label="Выберите материал который остается"
            required
          >
            <select-ui
              :is-invalid="state.visibleErrors && isTargetMaterialIdError"
              item-key="name"
              :options="materials.filter((m) => !state.toBeMergedMaterialIds.includes(+m.id))"
              :searchable="true"
              :values="[state.targetMaterialId]"
              @on-updated="onMaterialSelected"
            />
          </form-group-ui>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <button-ui button-class="btn-primary"> Сохранить </button-ui>
          </div>
        </div>
      </div>
    </form>
  </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref, watch } from 'vue';
import { initModal, useModal } from '@/composables/useModal';
import { IMaterial } from '@/@types/IMaterial';
import api from '@/api';

type stateType = {
  visibleErrors: boolean;
  loader: boolean;
  targetMaterialId: string;
  toBeMergedMaterialIds: number[];
};

export default defineComponent({
  name: 'MergeMaterialModal',
  props: {
    id: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const modal = useModal(props.id);

    const materials = ref<IMaterial[]>([]);

    const state: stateType = reactive({
      visibleErrors: false,
      loader: false,
      targetMaterialId: '',
      toBeMergedMaterialIds: [],
    });

    const isTargetMaterialIdError = computed(() => !state.targetMaterialId);
    const isToBeMergedMaterialIdsError = computed(() => !state.toBeMergedMaterialIds.length);

    onMounted(() => {
      api.materials.getOld().then(({ data }) => {
        materials.value = data;
      });
    });

    function onMaterialSelected(m: any) {
      state.targetMaterialId = m.id;
    }

    function onToBeMergedMaterialsSelected(m: IMaterial[]) {
      console.log(m);
      state.toBeMergedMaterialIds = m.map((i) => +i.id);
    }

    function send() {
      if (isTargetMaterialIdError.value || isToBeMergedMaterialIdsError.value) {
        state.visibleErrors = true;
      } else {
        state.visibleErrors = false;
        state.loader = true;

        api.materials
          .merge({
            targetMaterialId: state.targetMaterialId,
            toBeMergedMaterialIds: state.toBeMergedMaterialIds,
          })
          .then((r) => {
            modal.ok(r.data);
          });
      }
    }

    function ok(loco: any) {
      modal.ok({
        loco: loco,
      });
    }

    function close() {
      modal.cancel({
        zxc: 123,
      });
    }

    function cancel() {
      modal.cancel({
        zxc: 123,
      });
    }

    return {
      ok,
      modal,
      cancel,
      close,
      state,
      send,
      isTargetMaterialIdError,
      isToBeMergedMaterialIdsError,
      materials,
      onMaterialSelected,
      onToBeMergedMaterialsSelected,
    };
  },
});
</script>

<style scoped lang="scss"></style>
