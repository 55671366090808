<template>
    <modal-ui
        :id="id"
        size="modal-md"
        :title="state.title">
        <form class="loader" @submit.prevent="send">
            <loader-ui :is-show="state.loader" />
            <div class="row mb-4">
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Укажите норму дней"
                        label="Норма дней"
                        required>
                        <input-ui
                            v-model="state.normDays"
                            :is-invalid="state.visibleErrors && isNormDaysError"
                            placeholder="Норма дней" />
                    </form-group-ui>
                </div>
                <div class="col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                    <form-group-ui
                        invalid-feedback="Укажите стоимость"
                        label="Стоимость"
                        required>
                        <input-ui
                            v-model="state.price"
                            :is-invalid="state.visibleErrors && isPriceError"
                            placeholder="Стоимость" />
                    </form-group-ui>
                </div>
            </div>
            <div class="row">
                <div class="col-12 text-center">
                    <button-ui
                        button-class="btn-primary">
                        {{ state.btn }}
                    </button-ui>
                </div>
            </div>
        </form>
    </modal-ui>
</template>

<script lang="ts">
import { defineComponent, onMounted, reactive, computed, ref, watch } from "vue";
import { initModal, useModal } from "@/composables/useModal";
import api from "@/api";
import { DatePicker } from 'v-calendar';
import moment from 'moment-timezone';

export default defineComponent({
    name: "MaintenanceStandardModal",
    components: {
        DatePicker,
    },
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    setup(props) {
	    const modal = useModal(props.id);
        const locos = ref([]);
        const contractors = ref([]);

        const state = reactive({
            title: '',
            btn: '',
	        normDays: 0,
	        price: '',
            visibleErrors: false,
            loader: false,
        });

        // @ts-ignore
	    const isNormDaysError = computed(() => !parseInt(state.normDays));
	    // @ts-ignore
	    const isPriceError = computed(() => !parseInt(state.price.length));

        onMounted(() => {
	        state.title = 'Редактирование нормы';
	        state.btn = 'Сохранить';

            state.normDays = modal.data.data.normDays;
            state.price = modal.data.data.price;
        });

        function send() {
            if (isNormDaysError.value || isPriceError.value) {
                state.visibleErrors = true;
            } else {
                state.visibleErrors = false;
                state.loader = true;
                api.locoJobPriceDefault.patch({
	                normDays: state.normDays,
	                price: state.price,
	                id: modal.data.data.id,
                }).then((r: any) => {
	                modal.ok({
		                normDays: state.normDays,
		                price: state.price,
	                });
                });
	            // ok();
            }
        }

	    function ok() {
		    modal.ok({
			    normDays: state.normDays,
			    price: state.price,
		    });
	    }

        function close() {
	        modal.cancel({
		        zxc: 123,
	        });
        }

	    function cancel() {
		    modal.cancel({
			    zxc: 123,
		    });
	    }

        return {
            ok,
            cancel,
            close,
            state,
	        isNormDaysError,
	        isPriceError,
	        send,
	        locos,
	        contractors,
	        moment,
        };
    },
});
</script>

<style scoped lang="scss">

</style>
